import * as React from 'react';
import {  MultiStepContainer, OperationDetailsContainer, TenantInfoDetailsTableContainer } from '../../Styles/Style';
import { multiStepContext } from '../../../App';
import { Checkbox, FontIcon, Label, TextField } from '@fluentui/react';
import { Table } from 'react-bootstrap';
import { Constant } from '../Constants';

interface IOperationDetailsGrid {
    operationtype: string,
    endpointdata: string,
    SupportsPagination: boolean,
    _client?: boolean,
    IsCached?: boolean,
    SerializerType?: number
    IsLegacyResponse?: false
};

function TenantOperationDetails({admin, updateFormData}: any): React.ReactElement {
    const { formData, setFormData } = React.useContext(multiStepContext);
    let gridArray: IOperationDetailsGrid[] = [];

    const handleTextValueChange = (textField: string, index?: number) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        if (index !== undefined) {
            let gridData = formData.OperationDetails.slice();
            switch (textField) {
                case "SerializerType": {
                    const re = /^[0-9\b]+$/;
                    if (value === '' || re.test(value)) {
                        gridData[index][textField] = value === '' ? 0 : parseInt(value);
                        setFormData({ ...formData, OperationDetails: gridData })
                    }
                    break;
                }
                default: {
                    gridData[index][textField] = value
                    setFormData({ ...formData, OperationDetails: gridData })
                    break;
                }
            }
        } else {
            setFormData({ ...formData, [textField]: value })
        }
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean, index: number) => {
        if (index !== undefined) {
            let gridData = formData.OperationDetails.slice();
            gridData[index][fieldName] = isChecked
            setFormData({ ...formData, OperationDetails: gridData })
        }
    };

    const handleAdd = () => {
        gridArray = formData.OperationDetails;
        let newRowData: IOperationDetailsGrid = {
            operationtype: formData.operationtype,
            endpointdata: formData.endpointdata,
            SupportsPagination: formData.SupportsPagination,
            IsCached: formData.IsCached,
            _client: formData._client,
            SerializerType: formData.SerializerType,
            IsLegacyResponse: formData.IsLegacyResponse
        }
        gridArray.push(newRowData);
        setFormData({ ...formData, OperationDetails: gridArray })
    }

    const handleDelete = (index: any) => {
        let filteredGrid = formData.OperationDetails.filter((x: any, i: number) => i !== index);
        setFormData({ ...formData, OperationDetails: filteredGrid })
    }

    
    React.useEffect(() => {
        updateFormData(formData);
    }, [formData]);


    return (
        <div className={MultiStepContainer.divScroll}>
            <Table>
                <tbody>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label required>What would be your Base API Url ? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <TextField
                                value={formData.TenantBaseUrl}
                                onChange={handleTextValueChange("TenantBaseUrl")}
                                ariaLabel="Tenant base url text field"
                                placeholder="Enter text..."
                                errorMessage={formData.TenantBaseUrl === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label required>What would be your Resource Url ? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <TextField
                                value={formData.ResourceUrl}
                                onChange={handleTextValueChange("ResourceUrl")}
                                ariaLabel="Resource Url text field"
                                placeholder="Enter text..."
                                errorMessage={formData.ResourceUrl === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label required>What would be your Summary Url ? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <TextField
                                value={formData.SummaryUrl}
                                onChange={handleTextValueChange("SummaryUrl")}
                                ariaLabel="Summary Url text field"
                                placeholder="Enter text..."
                                errorMessage={formData.SummaryUrl === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label>What would be your Attachment Url ? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <TextField
                                value={formData.AttachmentUrl}
                                onChange={handleTextValueChange("AttachmentUrl")}
                                ariaLabel="Attachment Url text field"
                                placeholder="Enter text..."
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>

            <Table striped bordered hover responsive>
                <thead>
                    <tr className="sp-tabs-tr">
                        <th>Operation Type</th>
                        <th>Endpoint Data Url</th>
                        <th>Supports Pagination</th>
                        {admin &&
                            <React.Fragment>
                                <th>Is Client</th>
                                <th>Is Cached</th>
                                <th>Serializer Type</th>
                            </React.Fragment>}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {formData.OperationDetails.map((item: any, index: number) => {
                        return <tr>
                            <td>
                                <TextField
                                    value={formData.OperationDetails[index].operationtype}
                                    onChange={handleTextValueChange("operationtype", index)}
                                    ariaLabel="Operation Type text field"
                                    placeholder="Enter text..."
                                />
                            </td>
                            <td>
                                <TextField
                                    value={formData.OperationDetails[index].endpointdata}
                                    onChange={handleTextValueChange("endpointdata", index)}
                                    ariaLabel="EndPoint Data Url text field"
                                    placeholder="Enter text..."
                                />
                            </td>
                            <td>
                                <Checkbox
                                    checked={formData.OperationDetails[index].SupportsPagination}
                                    onChange={(e: any, value: boolean) => handleCheckboxChange("SupportsPagination", value, index)}
                                    ariaLabel="Supports Pagination checkbox"
                                    name="SupportsPagination"
                                />
                            </td>
                            {
                                admin && <React.Fragment>
                                    <td>
                                        <Checkbox
                                            checked={formData.OperationDetails[index]._client}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("_client", value, index)}
                                            ariaLabel="Is Client checkbox"
                                            name="IsClient"
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={formData.OperationDetails[index].IsCached}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsCached", value, index)}
                                            ariaLabel="Is Cached checkbox"
                                            name="IsCached"
                                        />
                                    </td>
                                    <td>
                                <TextField
                                    value={formData.OperationDetails[index].SerializerType}
                                    onChange={handleTextValueChange("SerializerType", index)}
                                    ariaLabel="Serializer Type text field"
                                    placeholder="Enter number..."
                                />
                            </td>
                                </React.Fragment>
                            }
                            <td>
                                <span>
                                    <FontIcon iconName='Add' title="Add new row" className={OperationDetailsContainer.gridIcons} role="button" onClick={handleAdd} />
                                </span>
                                {index !== 0 && <span>
                                    <FontIcon iconName='Delete' title="Delete row" className={OperationDetailsContainer.gridIcons} role="button" onClick={() => { handleDelete(index) }} />
                                </span>}

                            </td>
                        </tr>
                    })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default TenantOperationDetails;