import * as React from 'react';
import { Constant } from '../../Shared/Constants';
import TenantApprovals from '../../Shared/ConfigureTenant/TenantApprovals';


function EditTenant(): React.ReactElement {

    return (
        <TenantApprovals
            tenantType={Constant.APPROVED}
            requestType={Constant.EDIT}
            admin={true} />
    )
}

export default EditTenant;