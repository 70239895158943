import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Spinner } from 'office-ui-fabric-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { appContext, multiStepContext } from '../../../App';
import { SubmitMessage } from '../../Styles/Style';
import { generateGuid } from '../../Utils/Common';
import { Constant, defaultValuesOfMultiStepForm, RouteConstants } from '../Constants';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Helpers/telemetryHelpers';
import { ApprovePendingRequest, OnboardTenant } from '../Services';


function OnSubmitForm(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { finalData, setFormData } = React.useContext(multiStepContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        tenantName: finalData?.tenantData?.AppName,
        isLoading: true,
        isError: false,
        errorId: Constant.DEFAULT_EMPTY_STRING,
        requestType: finalData.tenantData.RequestType
    })
    let formDataRequest = new FormData();

    formDataRequest.append("TenantDetails", JSON.stringify(finalData.tenantData));

    finalData.templates.map((templateItem: any) => {
        var objectKey = Object.keys(templateItem)
        switch (objectKey[0]) {
            case Constant.ADAPTIVE_TEMPLATE: {
                templateItem.AdaptiveTemplate.map((item: any) => {
                    formDataRequest.append(objectKey[0], item);
                })
                break;
            }
            case Constant.EMAIL_TEMPLATE: {
                templateItem.EmailTemplate.map((item: any) => {
                    formDataRequest.append(objectKey[0], item);
                })
                break;
            }
            case Constant.SAMPLE_PAYLOAD: {
                templateItem.SamplePayload.map((item: any) => {
                    formDataRequest.append(objectKey[0], item);
                })
                break;
            }
            case Constant.TENANT_IMAGE: {
                templateItem.TenantImage.map((item: any) => {
                    formDataRequest.append(objectKey[0], item);
                })
                break;
            }
        }
    })

    const resetState = () => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        setFormData({
            ...defaultValuesOfMultiStepForm,
            OperationDetails: [{
                operationtype: Constant.DEFAULT_EMPTY_STRING,
                endpointdata: Constant.DEFAULT_EMPTY_STRING,
                SupportsPagination: false,
                _client: false,
                IsCached: false,
                SerializerType: 0,
                IsLegacyResponse: false
            }],
            ActionDetails: [{
                Name: Constant.DEFAULT_EMPTY_STRING,
                Text: Constant.DEFAULT_EMPTY_STRING,
                Code: Constant.DEFAULT_EMPTY_STRING,
                Condition: Constant.DEFAULT_EMPTY_STRING,
                IsCommentMandatory: false,
                CommentLength: 255,
                IsBulkAction: false,
                IsInterimStateRequired: false,
                IsEnabled: true,
                comments: [
                    {
                        mappingKey: Constant.DEFAULT_EMPTY_STRING,
                        isMandatory: false,
                        mandatoryCondition: Constant.DEFAULT_EMPTY_STRING,
                        length: 255
                    }
                ]
            }],
            Templates: Array<any>()
        });
    }

    React.useEffect(() => {

        const guid = generateGuid();

        if (state.requestType === Constant.APPROVE) {
            ApprovePendingRequest(context.environment, formDataRequest, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        resetState();
                    }

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'SubmitForm',
                        'MSApprovals.SubmitForm.ApprovePendingRequest.Success',
                        TrackingEventId.PostApprovePendingRequestSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );

                })
                .catch((error: any) => {
                    setState((prevState) => ({ ...prevState, isLoading: false, IsError: true, errorId: guid.msgId }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'SubmitForm',
                        'MSApprovals.SubmitForm.ApprovePendingRequest.Failure',
                        TrackingEventId.PostApprovePendingRequestFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            OnboardTenant(context.environment, formDataRequest, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        resetState();
                    }

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'SubmitForm',
                        'MSApprovals.SubmitForm.OnboardTenant.Success',
                        TrackingEventId.PostOnboardTenantSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch((error: any) => {
                    setState((prevState) => ({ ...prevState, isLoading: false, IsError: true, errorId: guid.msgId }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'SubmitForm',
                        'MSApprovals.SubmitForm.OnboardTenant.Failure',
                        TrackingEventId.PostOnboardTenantFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, []);

    const spinnerLabel = (): string => {
        switch (state.requestType) {
            case Constant.APPROVE: {
                return Constant.APPROVE_REQUEST
            }
            case Constant.EDIT: {
                return Constant.UPDATE_REQUEST
            }
            case Constant.SUBMIT: {
                return Constant.SUBMIT_REQUEST
            }
        }
    }

    return (
        <SubmitMessage id="1">
            {state.isLoading ?
                <Spinner label={spinnerLabel()} /> :
                <Redirect to={{
                    pathname: RouteConstants.ONBOARD_MESSAGE,
                    state: { tenantName: state.tenantName, isErrorOccurred: state.isError, errorId: state.errorId, requestType: state.requestType }
                }} />
            }
        </SubmitMessage>
    )
}

export default OnSubmitForm;