import { MSALClient } from '@employee-experience/core';
import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { Shell } from '@employee-experience/shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import sessionStorage from 'redux-persist/lib/storage/session'

const telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __ENV_NAME__,
        ServiceOffering: 'Finance',
        ServiceLine: 'Procure To Pay',
        Service: 'MSApprovals',
        ComponentName: 'MSApprovalsReact',
        ComponentId: 'b375ec23-b2b4-49f6-98ac-3118fb312f25'
    },
    defaultProperties: {
        appName: __APP_NAME__
    }
});
const authClient = new MSALClient(
    {
        auth: { clientId: __CLIENT_ID__, authority: __AUTHORITY__, redirectUri: `${window.location.origin}` }
    },
    telemetryClient
);
const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient, httpClient);

const blacklistDynamicConfig = {
    key: 'root',
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    // https://github.com/rt2zz/redux-persist/issues/786
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    timeout: null,
    blacklist: ['dynamic']
};

const reducerRegistry = new ReducerRegistry();
const appName = 'MSApprovalsWeb';
const storeResult = new StoreBuilder(reducerRegistry, {})
    .configureSaga({ telemetryClient, authClient, httpClient, componentLoader, graphClient, appName })
    .configurePersistor(blacklistDynamicConfig)
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
