import * as React from 'react';
import { Constant, TableHeaderConstants } from '../../Shared/Constants';
import { appContext } from '../../../App';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { getRequestStatusData } from '../../Shared/Services';
import '../../Styles/Styles.css';
import { TextField } from 'office-ui-fabric-react';
import { Label, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { divScrollContainer } from '../../Styles/Style';
import { format } from 'react-string-format';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';


export function RequestStatus(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        textValue: Constant.DEFAULT_EMPTY_STRING,
        shouldVisible: { searchResults: false },
        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
        requestResult: [],
        errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
    })

    const handleTextValueChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        setState((prevState) => ({ ...prevState, textValue: value }))
    }

    const onSubmit = () => {
        setState((prevState) => ({
            ...prevState,
            shouldVisible: { searchResults: false },
            isLoading: { visible: true, value: format(Constant.LOAD, Constant.HISTORY_DATA) },
            errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
        }))

        if (state.textValue != Constant.DEFAULT_EMPTY_STRING && state.textValue != undefined) {

            const guid = generateGuid();

            getRequestStatusData(state.textValue, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((results) => {
                    setState((prevState) => ({ ...prevState, isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING } }))

                    if (results.data.length > 0) {
                        setState((prevState) => ({
                            ...prevState,
                            requestResult: results.data,
                            shouldVisible: { searchResults: true }
                        }))
                    }
                    else {
                        setState((prevState) => ({
                            ...prevState,
                            errorMessage: { visible: true, value: Constant.NO_RECORDS_AVAILABLE }
                        }))
                    }

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'RequestStatus',
                        'MSApprovals.RequestStatus.getRequestStatusData.Success',
                        TrackingEventId.GetRequestStatusSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        errorMessage: { visible: true, value: format(Constant.EXCEPTION_MESSAGE, guid.msgId) },
                        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'RequestStatus',
                        'MSApprovals.RequestStatus.getRequestStatusData.Failure',
                        TrackingEventId.GetRequestStatusFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                errorMessage: { visible: true, value: Constant.EMPTY_INPUT_FIELDS },
                isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
            }))
        }
    }

    return (
        <div className={divScrollContainer.divScroll}>
            <h3>Request Status</h3>
            <br />
            {state.errorMessage.visible &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}> <b>{state.errorMessage.value}</b>
                </MessageBar>
            }
            {context.environment &&
                <React.Fragment>
                    <table>
                        <tbody>
                            <tr>
                                <td className="sp-td sp-label"><Label required>Request Number:</Label></td>
                            </tr>
                            <tr>
                                <td className="sp-td sp-width">
                                    <TextField
                                        className="sp-textbox"
                                        ariaLabel="Request Number text field"
                                        value={state.textValue}
                                        onChange={handleTextValueChange} />
                                </td>
                            </tr>
                            <tr><td className="sp-td"></td></tr>
                            <tr>
                                <td className="sp-td">
                                    <PrimaryButton
                                        text={Constant.SUBMIT}
                                        onClick={onSubmit}
                                        className="sp-btn" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    {
                        <React.Fragment>
                            {state.isLoading.visible && <Spinner label={state.isLoading.value}></Spinner>}
                            {(state.shouldVisible.searchResults && !state.isLoading.visible && !state.errorMessage.visible) &&
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr className="sp-tabs-tr">
                                            <th>{TableHeaderConstants.TENANT_NAME}</th>
                                            <th>{TableHeaderConstants.DOCUMENT_NUMBER}</th>
                                            <th>{TableHeaderConstants.APPROVER}</th>
                                            <th>{TableHeaderConstants.STATUS}</th>
                                            <th>{TableHeaderConstants.TIMESTAMP}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.requestResult.map((items, index: number) => {
                                            return <tr key={index + items.DocumentNumber} className="sp-tabs-tr">
                                                <td className="sp-tabs-td">{items.TenantName}</td>
                                                <td className="sp-tabs-td">{items.DocumentNumber}</td>
                                                <td className="sp-tabs-td">{items.Approver}</td>
                                                <td className="sp-tabs-td">{items.Status}</td>
                                                <td className="sp-tabs-td">{items.Timestamp}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>}
                        </React.Fragment>}
                </React.Fragment>
            }
        </div>
    )
}