import { ReduxContext } from "@employee-experience/common";
import { useContext, useLayoutEffect } from "react";

export const usePersistentReducer = (reducerName: string, reducer: any) => {
    const { reducerRegistry } = useContext(ReduxContext);
    useLayoutEffect(() => {
        if (!reducerRegistry.exists(reducerName)) {
            reducerRegistry.register(reducerName, reducer);
        }
    }, [reducer, reducerName, reducerRegistry]);
}