import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from "@rjsf/fluent-ui";
import { Constant, HeaderConstants, LabelConstants } from '../../Shared/Constants';
import { SearchRequestHistory, fetchFormData, fetchAIScope } from '../../Shared/Services';
import { appContext } from '../../../App';
import { IRequestHistory } from '../../Shared/Interfaces/IRequestHistory';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Label, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { divScrollContainer, DropDownStyle, FormContainer } from '../../Styles/Style';
import Select from 'react-dropdown-select';
import { format } from 'react-string-format';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';


function RequestHistory(props: IRequestHistory): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [aiScope, setAIScope] = React.useState({ scopeList: [], selectedScopes: [] });
    const [tabIndex, setTabIndex] = React.useState(0);
    const [state, setState] = React.useState({
        formJson: {},
        formData: {},
        historyData: [],
        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
        shouldVisible: { historyTabs: false, submitButton: false },
        errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
    })

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING && props.RequestType != undefined) {
            setState((prevState) => ({
                ...prevState,
                shouldVisible: { historyTabs: false, submitButton: false },
                formJson: {},
                formData: {},
                errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
                historyData: []
            }))

            const guid = generateGuid();

            fetchFormData(props.RequestType, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then(response => {
                    setState((prevState) => ({
                        ...prevState,
                        formJson: response.data,
                        shouldVisible: { historyTabs: false, submitButton: true }
                    }));

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.fetchFormData.Success',
                        TrackingEventId.GetRequestHistoryFormDataSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch(error => {
                    setState((prevState) => ({ 
                        ...prevState, 
                        errorMessage: { 
                            value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            visible: true 
                        } 
                    }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.fetchFormData.Failure',
                        TrackingEventId.GetRequestHistoryFormDataFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                });
        }
    }, [context.environment, props.RequestType]);

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING && props.RequestType != undefined) {
            setAIScope({ selectedScopes: [], scopeList: [] })
            handleAIScopeChange([]);

            const guid = generateGuid();

            fetchAIScope(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    const aiScopeList = response.data.map((item: string) => {
                        return { value: item, label: item }
                    })
                    setAIScope((prevState) => ({ ...prevState, scopeList: aiScopeList }));

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.fetchAIScope.Success',
                        TrackingEventId.GetRequestHistoryAiScopeSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch(error => {
                    setState((prevState) => ({ 
                        ...prevState, 
                        errorMessage: { 
                            value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            visible: true 
                        } 
                    }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.fetchAIScope.Failure',
                        TrackingEventId.GetRequestHistoryAiScopeFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );

                });
        }
    }, [context.environment, props.RequestType])

    const handleAIScopeChange = (selectedScope: any[]) => {
        setAIScope((prevState) => ({ ...prevState, selectedScopes: selectedScope }))
    }

    const onSubmit = () => {
        document.getElementById('submit-button-inside').click();
    }

    const onSubmitInside = (event: any) => {
        setState((prevState) => ({
            ...prevState,
            shouldVisible: { historyTabs: false, submitButton: true },
            historyData: [],
            errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
            formData: event.formData
        }))

        let scopes = aiScope.selectedScopes.map((item: any) => {
            return item.label
        })

        if (Object.getOwnPropertyNames(event.formData).length === Object.getOwnPropertyNames(event.schema.properties).length &&
            aiScope.selectedScopes.length > 0 &&
            !Object.values(event.formData).includes(undefined)) {
            setState((prevState) => ({ ...prevState, isLoading: { visible: true, value: format(Constant.LOAD, Constant.HISTORY_DATA) } }))

            const guid = generateGuid();

            SearchRequestHistory(event.formData, props.RequestType, scopes.toString(), context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then(response => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
                        shouldVisible: { historyTabs: true, submitButton: true }
                    }))
                    if (response.data.length > 0) {
                        setState((prevState) => ({
                            ...prevState,
                            historyData: response.data
                        }))
                    }
                    else {
                        setState((prevState) => ({
                            ...prevState,
                            errorMessage: { visible: true, value: Constant.NO_RECORDS_AVAILABLE }
                        }))
                    }

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.searchRequestHistory.Success',
                        TrackingEventId.PostRequestHistorySuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch(error => {
                    setState((prevState) => ({ 
                        ...prevState, 
                        errorMessage: { 
                            value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            visible: true 
                        } 
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'RequestHistory',
                        'MSApprovals.RequestHistory.searchRequestHistory.Failure',
                        TrackingEventId.PostRequestHistoryFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                errorMessage: { visible: true, value: Constant.EMPTY_INPUT_FIELDS }
            }))
        }
    }

    return (
        <div className={divScrollContainer.divScroll}>
            <h3>{HeaderConstants.REQUEST_HISTORY}</h3>
            <br />
            {state.errorMessage.visible &&
                <React.Fragment>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}> <b>{state.errorMessage.value}</b>
                    </MessageBar><br />
                </React.Fragment>}
            <FormContainer>
                <Label className="sp-text-margin" required>{LabelConstants.SCOPE}</Label>
                <Select
                    className={DropDownStyle.requestHistory}
                    disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                    multi={true}
                    options={aiScope.scopeList}
                    loading={aiScope.scopeList.length > 0 ? false : true}
                    onChange={(value: any) => handleAIScopeChange(value)}
                    searchable={false}
                    values={[...aiScope.selectedScopes]}
                />
                <br />
                <Form
                    schema={state.formJson}
                    formData={state.formData}
                    onSubmit={onSubmitInside}>
                    <div className='hidden'>
                        <button id="submit-button-inside" className="btn-lg btn-block" type="submit">{Constant.SUBMIT}</button>
                    </div>
                </Form>
                {state.shouldVisible.submitButton &&
                    <PrimaryButton
                        text={Constant.SUBMIT}
                        onClick={onSubmit}
                        className="sp-btn sp-btn-margin"
                        disabled={state.isLoading.visible} />}
            </FormContainer>
            <br /><br />
            {!state.errorMessage.visible &&
                <React.Fragment>
                    {state.isLoading.visible && <Spinner label={state.isLoading.value} />}
                    {(state.shouldVisible.historyTabs && !state.isLoading.visible && state.historyData.some(x => x.tables)) &&
                        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                {state.historyData.map((table: { tables: [] }) => {
                                    return table.tables.map((tabTitle: { title: string }) => {
                                        return <Tab>{tabTitle.title}</Tab>
                                    })
                                })}
                            </TabList>
                            {state.historyData.map((table: { tables: [] }) => {
                                return (table.tables.map((tableFields: { rows: [], columns: [] }) => {
                                    return (
                                        <TabPanel>
                                            <table className="table table-striped table-bordered">
                                                <tr>
                                                    {tableFields.columns.map((column: { name: string }) => {
                                                        return <th>{column.name}</th>
                                                    })}
                                                </tr>
                                                {tableFields.rows.map((row: []) => {
                                                    return (<tr className="sp-tabs-tr">
                                                        {row.map((rowData: string) => {
                                                            return <td className="sp-tabs-td">{rowData}</td>
                                                        })}
                                                    </tr>)
                                                })}
                                            </table>
                                        </TabPanel>
                                    )
                                }))
                            })}
                        </Tabs>
                    }
                </React.Fragment>}
        </div>
    )
}

export default RequestHistory;