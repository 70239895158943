import { ITelemetryClient, IAuthClient } from '@employee-experience/common/lib/Models';

export enum TrackingEventId {
    //Get
    GetTenantInfoSuccess = 100000,
    GetTenantInfoFailure,
    GetAlertNotificationSuccess,
    GetAlertNotificationFailure,
    GetFlightFeaturesSuccess,
    GetFlightFeaturesFailure,
    GetRequestHistoryFormDataSuccess,
    GetRequestHistoryFormDataFailure,
    GetRequestHistoryAiScopeSuccess,
    GetRequestHistoryAiScopeFailure,
    GetUserDelegationSuccess,
    GetUserDelegationFailure,
    GetPayloadHistorySuccess,
    GetPayloadHistoryFailure,
    GetRequestStatusSuccess,
    GetRequestStatusFailure,
    GetTopicDataSuccess,
    GetTopicDataFailure,
    GetSubscriptionDataSuccess,
    GetSubscriptionDataFailure,
    GetSBMonitoringDataSuccess,
    GetSBMonitoringDataFailure,
    GetTemplatesSuccess,
    GetTemplatesFailure,
    GetTenantsFromApprovalsSuccess,
    GetTenantsFromApprovalsFailure,
    GetUserFromAuthClientSuccess,
    GetAuthTokenSuccess,
    GetAuthTokenFailure,
    GetEnvironmentListSuccess,
    GetEnvironmentListFailure,

    //Post
    PostSaveNotificationSuccess,
    PostSaveNotificationFailure,
    PostFlightFeaturesSuccess,
    PostFlightFeaturesFailure,
    PostRequestHistorySuccess,
    PostRequestHistoryFailure,
    PostOutOfSyncRequestsSuccess,
    PostOutOfSyncRequestsFailure,
    PostActionDetailsSuccess,
    PostActionDetailsFailure,
    PostUserDelegationSuccess,
    PostUserDelegationFailure,
    PostReprocessPayloadSuccess,
    PostReprocessPayloadFailure,
    PostApprovePendingRequestSuccess,
    PostApprovePendingRequestFailure,
    PostOnboardTenantSuccess,
    PostOnboardTenantFailure,

    //Delete
    DeleteUserDelegationSuccess,
    DeleteUserDelegationFailure,
    
    CheckUserRoleSuccess,
    CheckUserRoleFailure
}

export const getContextCommonTelemetryProperties = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    appAction: string,
    eventName: string,
    eventId: number
): any => {
    const occurenceTime = new Date();
    const correlationId = telemetryClient.getCorrelationId();
    const logData = {
        AppAction: appAction,
        EventOccurenceTime: occurenceTime,
        SessionId: correlationId,
        EventId: eventId,
        EventName: eventName,
        ComponentType: 'Web'
    };
    return logData;
};

export const trackBusinessProcessEvent = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    businessProcessName: string,
    appAction: string,
    eventId: number,
    additionalProperties: any = {}
): void => {
    try {
        const businessProcessProperties = {
            BusinessProcessName: businessProcessName,
            EventType: 'BusinessProcessEvent'
        };
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            businessProcessName,
            eventId
        );
        const telemetryProperties = Object.assign(
            businessProcessProperties,
            contextCommonProperties,
            additionalProperties
        );
        telemetryClient.trackEvent({ name: businessProcessName }, telemetryProperties);
    } catch (error) { }
};

export const trackException = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    eventName: string,
    appAction: string,
    eventId: number,
    exception: Error,
    additionalProperties: any = {}
): void => {
    try {
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            eventName,
            eventId
        );
        const telemetryProperties = Object.assign(contextCommonProperties, additionalProperties);
        telemetryClient.trackException({ exception: exception, properties: telemetryProperties });
    } catch (error) { }
};