import { Checkbox, ChoiceGroup, DatePicker, DayOfWeek, FontIcon, IChoiceGroupOption, Label, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, Spinner, TextField } from '@fluentui/react';
import * as React from 'react';
import Select from 'react-dropdown-select';
import { appContext } from '../../../App';
import { Constant, DayPickerStrings, LabelConstants, TableHeaderConstants } from '../../Shared/Constants';
import { IReprocessPayload, ISearchPayload } from '../../Shared/Interfaces/IPayloadHistory';
import { getPayloadHistory, getTenantsInfo, ReprocessPayload } from '../../Shared/Services';
import { controlClass, divScrollContainer, DropDownStyle, iconClass, ReadioButtonContainer } from '../../Styles/Style';
import { format } from 'react-string-format';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';


function PayloadHistory(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        tenantList: [],
        tenantData: { tenantName: Constant.DEFAULT_EMPTY_STRING, tenantId: Constant.DEFAULT_EMPTY_STRING },
        isRadioCheckedValue: Constant.DEFAULT_EMPTY_STRING,
        message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
        btnDisabled: false,
        startDate: null,
        endDate: null,
        StartDate: Constant.DEFAULT_EMPTY_STRING,
        EndDate: Constant.DEFAULT_EMPTY_STRING,
        DocNumbers: Constant.DEFAULT_EMPTY_STRING,
        isLoading: false,
        payloadData: [],
        collection: Constant.PRIMARY,
        isTableVisible: false,
        isReprocessing: false,
        isNoRecords: false
    })

    const firstDayOfWeek = DayOfWeek.Sunday;

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {

            const guid = generateGuid();

            getTenantsInfo(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        const tenantsDetails = response.data.map((item: { rowKey: string; appName: string; tenantActionDetails: string }) => {
                            return { value: item.rowKey, label: item.appName, details: item.tenantActionDetails }
                        })
                        setState((prevState) => ({ ...prevState, tenantList: tenantsDetails }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'PayloadHistory',
                            'MSApprovals.PayloadHistory.getTenantsInfo.Success',
                            TrackingEventId.GetTenantInfoSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId) 
                        }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'PayloadHistory',
                        'MSApprovals.PayloadHistory.getTenantsInfo.Failure',
                        TrackingEventId.GetTenantInfoFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, [context.environment])

    const handleTenantDropdownChange = (selectedOption: any[]) => {
        if (state.tenantList.length > 0) {
            setState((prevState) => ({
                ...prevState,
                tenantData: { tenantName: selectedOption[0].label, tenantId: selectedOption[0].value }
            }))
        }
    }

    const handleTextValueChange = (textField: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        setState((prevState) => ({ ...prevState, [textField]: value }))
    }

    const onFormatDate = (date?: Date): string => {
        var monthField = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
        var dateField = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();

        return !date ? '' : monthField + '/' + dateField + "/" + date.getFullYear();
    };

    const handleSelectedDateChange = (dateField: string) => (date: Date | null | undefined): void => {
        dateField === "StartDate" ? setState((prevState) => ({ ...prevState, startDate: date })) : setState((prevState) => ({ ...prevState, endDate: date }))
        var formattedDate = onFormatDate(date);
        setState((prevState) => ({ ...prevState, [dateField]: formattedDate }))
    }

    const handleSearchCriteriaRadioChange = (ev: any, option: IChoiceGroupOption) => {
        if (option.text === Constant.DATE_FIELD) {
            setState((prevState) => ({
                ...prevState,
                isRadioCheckedValue: option.text,
                DocNumbers: Constant.DEFAULT_EMPTY_STRING
            }))
        }
        else {
            setState((prevState) => ({
                ...prevState,
                isRadioCheckedValue: option.text,
                startDate: null,
                endDate: null
            }))
        }

    }

    const handleNotificationCheckboxChange = (isChecked: boolean, index: number) => {
        let gridData = state.payloadData.slice();
        gridData[index].SendNotification = isChecked;
        setState((prevState) => ({ ...prevState, payloadData: gridData }))
    };

    const onSearch = () => {
        setState((prevState) => ({
            ...prevState,
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: Constant.DEFAULT_EMPTY_STRING },
            btnDisabled: true,
            isLoading: true,
            isTableVisible: false,
            isNoRecords: false
        }))

        if (state.tenantData.tenantName !== Constant.DEFAULT_EMPTY_STRING &&
            state.isRadioCheckedValue !== Constant.DEFAULT_EMPTY_STRING &&
            (state.isRadioCheckedValue === Constant.DATE_FIELD ?
                (state.startDate !== null && state.endDate !== null &&
                    state.startDate <= state.endDate) :
                (state.DocNumbers !== Constant.DEFAULT_EMPTY_STRING)
            )) {

            let dataToSearch: ISearchPayload = {
                tenantID: state.tenantData.tenantId,
                fromDate: state.startDate,
                toDate: state.endDate,
                collection: state.collection,
                documentCollection: state.DocNumbers
            }

            const guid = generateGuid();

            getPayloadHistory(context.environment, dataToSearch, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        let payloadDetails: any[] = [];
                        if (response.data.length > 0) {
                            payloadDetails = response.data.map((item: any) => {
                                item.SendNotification = false;
                                return (item);
                            })
                            setState((prevState) => ({
                                ...prevState,
                                btnDisabled: false,
                                isLoading: false,
                                payloadData: payloadDetails,
                                isTableVisible: true
                            }))
                        }
                        else {
                            setState((prevState) => ({
                                ...prevState,
                                btnDisabled: false,
                                isLoading: false,
                                isTableVisible: false,
                                isNoRecords: true
                            }))
                        }

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'PayloadHistory',
                            'MSApprovals.PayloadHistory.getPayloadHistory.Success',
                            TrackingEventId.GetPayloadHistorySuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        btnDisabled: false,
                        isLoading: false,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId) 
                        }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'PayloadHistory',
                        'MSApprovals.PayloadHistory.getPayloadHistory.Failure',
                        TrackingEventId.GetPayloadHistoryFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })

        }
        else if (state.startDate > state.endDate) {
            setState((prevState) => ({
                ...prevState,
                message: { error: Constant.LARGE_START_DATE, success: Constant.DEFAULT_EMPTY_STRING },
                btnDisabled: false,
                isLoading: false
            }))
        }
        else {
            setState((prevState) => ({
                ...prevState,
                message: { error: Constant.EMPTY_INPUT_FIELDS, success: Constant.DEFAULT_EMPTY_STRING },
                btnDisabled: false,
                isLoading: false
            }))
        }
    }

    const onReProcessClick = (itemToReprocess: any, index: number) => {
        setState((prevState) => ({
            ...prevState,
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: Constant.DEFAULT_EMPTY_STRING },
            btnDisabled: true,
            isReprocessing: true
        }))

        let dataToReprocess: IReprocessPayload = {
            requestNumber: itemToReprocess.DisplayDocumentNumber,
            brokeredMessageID: itemToReprocess.BrokeredMessageID,
            SendNotification: itemToReprocess.SendNotification,
            collection: state.collection,
            tenantID: state.tenantData.tenantId
        }

        const guid = generateGuid();

        ReprocessPayload(context.environment, dataToReprocess, context.authToken, context.userAlias, guid.msgId, guid.xcv)
            .then((response) => {
                if (response.status === Constant.STATUS_CODE_OK) {
                    setState((prevState) => ({
                        ...prevState,
                        btnDisabled: false,
                        isReprocessing: false,
                        message: { error: Constant.DEFAULT_EMPTY_STRING, success: Constant.SUCCESS_PAYLOAD_REPROCESS }
                    }))

                    setTimeout(() => {
                        onSearch()
                    }, 2000)
                }
                else {
                    setState((prevState) => ({
                        ...prevState,
                        btnDisabled: false,
                        isReprocessing: false,
                        message: { error: response.data, success: Constant.DEFAULT_EMPTY_STRING }
                    }))
                }

                trackBusinessProcessEvent(
                    authClient,
                    telemetryClient,
                    'PayloadHistory',
                    'MSApprovals.PayloadHistory.ReprocessPayload.Success',
                    TrackingEventId.PostReprocessPayloadSuccess,
                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            })
            .catch((error) => {
                setState((prevState) => ({
                    ...prevState,
                    btnDisabled: false,
                    isReprocessing: false,
                    message: { 
                        error: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                        success: Constant.DEFAULT_EMPTY_STRING 
                    }
                }))

                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'PayloadHistory',
                    'MSApprovals.PayloadHistory.ReprocessPayload.Failure',
                    TrackingEventId.PostReprocessPayloadFailure,
                    errorMessage,
                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            })
    }

    return (
        <div className={divScrollContainer.divScroll}>
            {state.message.error !== Constant.DEFAULT_EMPTY_STRING ?
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}> <b>{state.message.error}</b>
                </MessageBar> :
                <React.Fragment>
                    {state.isReprocessing && <React.Fragment>
                        <b>{Constant.REQUEST_PROCESSING}</b>
                        <ProgressIndicator barHeight={3} /></React.Fragment>}
                    {state.isNoRecords && <React.Fragment>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={false}> <b>{Constant.NO_RECORDS_AVAILABLE}</b>
                        </MessageBar>
                    </React.Fragment>}
                    {state.message.success !== Constant.DEFAULT_EMPTY_STRING &&
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}> <b>{state.message.success}</b>
                        </MessageBar>}
                </React.Fragment>
            }
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td">
                            <Label required>{LabelConstants.SELECT_TENANT}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <Select
                                className={DropDownStyle.generic}
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.tenantList}
                                loading={state.tenantList.length > 0 ? false : true}
                                onChange={(value: any) => handleTenantDropdownChange(value)}
                                searchable={false}
                                values={[...state.tenantData.tenantName]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <Label required>{LabelConstants.SEARCH_CRITERIA}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <div className={ReadioButtonContainer.radioStyle}>
                                <ChoiceGroup
                                    options={Constant.SEARCH_CRITERIA}
                                    onChange={handleSearchCriteriaRadioChange}
                                    styles={{ flexContainer: { display: "flex" } }}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        {state.isRadioCheckedValue !== Constant.DEFAULT_EMPTY_STRING &&
                            <React.Fragment>
                                {state.isRadioCheckedValue === Constant.DATE_FIELD ?
                                    <React.Fragment>
                                        <td className="sp-td">
                                            <Label required>{LabelConstants.START_DATE}</Label>
                                        </td>
                                        <td className="sp-td sp-width">
                                            <DatePicker
                                                className={controlClass.control}
                                                firstDayOfWeek={firstDayOfWeek}
                                                strings={DayPickerStrings}
                                                placeholder="mm/dd/yyyy"
                                                ariaLabel="Select a start date"
                                                showMonthPickerAsOverlay={true}
                                                formatDate={onFormatDate}
                                                onSelectDate={handleSelectedDateChange("StartDate")}
                                                value={state.startDate}
                                            />
                                        </td>
                                        <td className="sp-td">
                                            <Label required>{LabelConstants.END_DATE}</Label>
                                        </td>
                                        <td className="sp-td sp-width">
                                            <DatePicker
                                                className={controlClass.control}
                                                firstDayOfWeek={firstDayOfWeek}
                                                strings={DayPickerStrings}
                                                placeholder="mm/dd/yyyy"
                                                ariaLabel="Select an end date"
                                                showMonthPickerAsOverlay={true}
                                                formatDate={onFormatDate}
                                                onSelectDate={handleSelectedDateChange("EndDate")}
                                                value={state.endDate}
                                            />
                                        </td>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <td className="sp-td">
                                            <Label required>{LabelConstants.DOC_NUMBER}</Label>
                                        </td>
                                        <td className="sp-td sp-width">
                                            <TextField
                                                value={state.DocNumbers}
                                                onChange={handleTextValueChange("DocNumbers")}
                                                ariaLabel="Request Numbers text field"
                                                className="sp-textbox-admin"
                                                title={Constant.COMMA_SEPERATED_REQ_NUMBERS} />
                                        </td>
                                    </React.Fragment>}
                            </React.Fragment>}
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <PrimaryButton
                                text={Constant.SEARCH}
                                onClick={onSearch}
                                className="sp-btn"
                                disabled={state.btnDisabled}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            {state.isLoading && <Spinner label={format(Constant.LOAD, Constant.DATA)} />}
            {(state.payloadData.length > 0 && state.isTableVisible) && <React.Fragment>
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>{TableHeaderConstants.DOCUMENT_NUMBER}</th>
                            <th>{TableHeaderConstants.DISPLAY_DOCUMENT_NUMBER}</th>
                            <th>{TableHeaderConstants.BROKERAGE_MSG_ID}</th>
                            <th>{TableHeaderConstants.OPERATION_TYPE}</th>
                            <th>{TableHeaderConstants.APPROVER_ALIAS}</th>
                            <th>{TableHeaderConstants.TIMESTAMP}</th>
                            <th>{TableHeaderConstants.NOTIFICATION}</th>
                            <th>{TableHeaderConstants.ACTION}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.payloadData.map((item: any, index: number) => {
                            return (
                                <tr className="sp-tabs-tr" key={index} >
                                    <td className="sp-tabs-td">{item.DocumentNumber}</td>
                                    <td className="sp-tabs-td">{item.DisplayDocumentNumber}</td>
                                    <td className="sp-tabs-td">{item.BrokeredMessageID}</td>
                                    <td className="sp-tabs-td">{item.OperationType}</td>
                                    <td className="sp-tabs-td">{item.ApproverAlias}</td>
                                    <td className="sp-tabs-td">{item.TimeStamp}</td>
                                    <td className="sp-tabs-td">
                                        <Checkbox
                                            checked={state.payloadData[index].SendNotification}
                                            onChange={(e: any, value: boolean) => handleNotificationCheckboxChange(value, index)}
                                            ariaLabel="Enable disable notification checkbox"
                                            name="Notification"
                                            className={iconClass.reprocessIcon}
                                        />
                                    </td>
                                    <td className="sp-tabs-td">
                                        <FontIcon
                                            iconName='RepeatAll'
                                            title="Reprocess Payload"
                                            className={iconClass.reprocessIcon}
                                            role="button"
                                            onClick={() => { onReProcessClick(item, index) }} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </React.Fragment>}
        </div>
    )
}

export default PayloadHistory;