import * as React from 'react';
import Select from 'react-dropdown-select';
import { Constant } from '../../Shared/Constants';
import { appContext } from '../../../App';
import { getSBMonitoringData, getSubscriptionData, getTopicData } from '../../Shared/Services';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import '../../Styles/Styles.css';
import { Label, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { divScrollContainer } from '../../Styles/Style';
import { format } from 'react-string-format';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';

export function ServiceBusTopicMonitoring(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        topicList: [],
        subscriptionList: [],
        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
        shouldVisible: { searchResults: false, submitButton: false },
        dropdownValues: {
            topic: Constant.DEFAULT_EMPTY_STRING,
            subscription: Constant.DEFAULT_EMPTY_STRING,
            messageType: Constant.DEFAULT_EMPTY_STRING
        },
        topicResults: { columns: [], rows: [] },
        errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
    })

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {
            setState((prevState) => ({
                ...prevState,
                shouldVisible: { searchResults: false, submitButton: false },
                errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
                topicList: []
            }))

            const guid = generateGuid();

            getTopicData(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((topics) => {
                    if (topics.data.length > 0) {
                        const topicData = topics.data.map((item: string) => {
                            return { value: item, label: item }
                        })

                        setState((prevState) => ({
                            ...prevState,
                            shouldVisible: { searchResults: false, submitButton: true },
                            topicList: topicData
                        }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'ServiceBusTopicMonitoring',
                            'MSApprovals.ServiceBusTopicMonitoring.getTopicData.Success',
                            TrackingEventId.GetTopicDataSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        errorMessage: { value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), visible: true }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'ServiceBusTopicMonitoring',
                        'MSApprovals.ServiceBusTopicMonitoring.getTopicData.Failure',
                        TrackingEventId.GetTopicDataFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, [context.environment])

    const handleTopicDropdownChange = (selectedOption: any[]) => {
        setState((prevState) => ({
            ...prevState,
            dropdownValues: { ...prevState.dropdownValues, topic: selectedOption[0].value }
        }))

        const guid = generateGuid();

        getSubscriptionData(selectedOption[0].value, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
            .then((subscriptions) => {
                if (subscriptions.data.length > 0) {
                    const subscriptionData = subscriptions.data.map((item: string) => {
                        return { value: item, label: item }
                    })
                    setState((prevState) => ({
                        ...prevState,
                        subscriptionList: subscriptionData
                    }))

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'ServiceBusTopicMonitoring',
                        'MSApprovals.ServiceBusTopicMonitoring.getSubscriptionData.Success',
                        TrackingEventId.GetSubscriptionDataSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                }
            })
            .catch((error) => {
                setState((prevState) => ({
                    ...prevState,
                    errorMessage: { value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), visible: true }
                }))

                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'ServiceBusTopicMonitoring',
                    'MSApprovals.ServiceBusTopicMonitoring.getSubscriptionData.Failure',
                    TrackingEventId.GetSubscriptionDataFailure,
                    errorMessage,
                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            })
    }

    const handleSubscriptionDropdownChange = (selectedOption: any[]) => {
        setState((prevState) => ({
            ...prevState,
            dropdownValues: { ...prevState.dropdownValues, subscription: selectedOption[0].value }
        }))
    }

    const handleMessageTypeDropdownChange = (selectedOption: any[]) => {
        setState((prevState) => ({
            ...prevState,
            dropdownValues: { ...prevState.dropdownValues, messageType: selectedOption[0].value }
        }))
    }

    const onSubmit = () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: { visible: true, value: format(Constant.LOAD, Constant.DATA) },
            shouldVisible: { searchResults: false, submitButton: true },
            errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
        }))

        if (state.dropdownValues.topic != Constant.DEFAULT_EMPTY_STRING &&
            state.dropdownValues.subscription != Constant.DEFAULT_EMPTY_STRING &&
            state.dropdownValues.messageType != Constant.DEFAULT_EMPTY_STRING) {

            const guid = generateGuid();

            getSBMonitoringData(state.dropdownValues.topic, state.dropdownValues.subscription, state.dropdownValues.messageType, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((results) => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: { visible: false, value: format(Constant.LOAD, Constant.DATA) }
                    }))

                    if (Object.getOwnPropertyNames(results.data).length > 0 &&
                        Object.getOwnPropertyNames(results.data.columns).length > 0 &&
                        Object.getOwnPropertyNames(results.data.rows).length > 0) {
                        setState((prevState) => ({
                            ...prevState,
                            topicResults: { columns: results.data.columns, rows: results.data.rows },
                            shouldVisible: { searchResults: true, submitButton: true }
                        }))
                    }
                    else {
                        setState((prevState) => ({
                            ...prevState,
                            errorMessage: { visible: true, value: Constant.NO_RECORDS_AVAILABLE },
                            shouldVisible: { searchResults: false, submitButton: true }
                        }))
                    }

                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'ServiceBusTopicMonitoring',
                        'MSApprovals.ServiceBusTopicMonitoring.getSBMonitoringData.Success',
                        TrackingEventId.GetSBMonitoringDataSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        errorMessage: { visible: true, value: format(Constant.EXCEPTION_MESSAGE, guid.msgId) },
                        shouldVisible: { searchResults: false, submitButton: true },
                        isLoading: { visible: false, value: format(Constant.LOAD, Constant.DATA) }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'ServiceBusTopicMonitoring',
                        'MSApprovals.ServiceBusTopicMonitoring.getSBMonitoringData.Failure',
                        TrackingEventId.GetSBMonitoringDataFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                errorMessage: { visible: true, value: Constant.EMPTY_INPUT_FIELDS },
                isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
            }))
        }
    }

    return (
        <div className={divScrollContainer.divScroll}>
            <h3>ServiceBus Topic Monitoring</h3>
            <br />
            {state.errorMessage.visible && <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}> <b>{state.errorMessage.value}</b>
            </MessageBar>}
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>Select Topic Name: </Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.topicList}
                                loading={state.topicList.length > 0 ? false : true}
                                onChange={(val: any) => handleTopicDropdownChange(val)}
                                searchable={false}
                                values={[...state.dropdownValues.topic]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>Select Subscription Name: </Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.subscriptionList}
                                loading={state.subscriptionList.length > 0 ? false : true}
                                onChange={(value: any) => handleSubscriptionDropdownChange(value)}
                                searchable={false}
                                values={[...state.dropdownValues.subscription]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>Select Message Type: </Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={Constant.MESSAGE_TYPE}
                                onChange={(value: any) => handleMessageTypeDropdownChange(value)}
                                searchable={false}
                                values={[...state.dropdownValues.messageType]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"></td>
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <PrimaryButton
                                text={Constant.SUBMIT}
                                onClick={onSubmit}
                                className="sp-btn" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <br /> <br />
            {state.isLoading.visible && <Spinner label={state.isLoading.value} />}
            {(state.shouldVisible.searchResults && !state.isLoading.visible) && <React.Fragment>
                <h4>Following document(s) found for given combination :</h4>
                <br />
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr className="sp-tabs-tr">
                            {state.topicResults.columns.length > 0 && state.topicResults.columns.map((column: string) => {
                                return <th>{column}</th>
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(state.topicResults.columns.length > 0 && state.topicResults.rows.length > 0) ? state.topicResults.rows.map((row: []) => {
                            return (<tr className="sp-tabs-tr">
                                {row.map((rowData: string) => {
                                    return <td className="sp-tabs-td">{rowData}</td>
                                })}
                            </tr>)
                        }) : <tr>{state.topicResults.rows.map((row: string) => {
                            return <td>{row}</td>
                        })}
                        </tr>}
                    </tbody>
                </table>
            </React.Fragment>
            }
        </div>
    )
}