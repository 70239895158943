import { Checkbox, Dropdown, IDropdownOption, Label } from '@fluentui/react';
import * as React from 'react';
import { multiStepContext } from '../../../App';
import { Constant } from '../Constants';
import { MultiStepContainer, NotificationsContainer, TenantInfoDetailsTableContainer } from '../../Styles/Style';


const TenantNotification = ({ admin, updateFormData }: any): React.ReactElement => {
    const { formData, setFormData } = React.useContext(multiStepContext);

    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        setFormData({ ...formData, [fieldName]: isChecked })
    };

    const handleDropDownChange = (fieldName: string, item: IDropdownOption) => {
        setFormData({ ...formData, [fieldName]: item.text })
    };

    React.useEffect(() => {
        updateFormData(formData);
    }, [formData]);


    return (
        <div className={MultiStepContainer.divScroll}>
            <table>
                <tbody>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label required>Do you need an actionable email notification? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            options={Constant.AGREE_TYPE}
                            defaultSelectedKey = {formData.EnableOutlookActionableEmail}
                            onChange={(e: any, value: any) => handleDropDownChange("EnableOutlookActionableEmail", value)}
                            placeholder="--Select--"
                            className={NotificationsContainer.dropdownContainer}
                            errorMessage={formData.EnableOutlookActionableEmail === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need a normal email notification? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.EnableOutlookActionableEmail === Constant.AGREE_TYPE[0].text ? true : formData.EnableEmailNotification}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("EnableEmailNotification", value)}
                            ariaLabel="Enable Email Notification checkbox"
                            name="EnableEmailNotification"
                            disabled={formData.EnableOutlookActionableEmail === Constant.AGREE_TYPE[0].text ? true: false}
                        />
                    </td>
                </tr>
                {admin && <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Notify Email with mobile Friendly Action Card </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            placeholder="--Select--"
                            defaultSelectedKey={formData.NotifyEmailWithMobileFriendlyActionCard}
                            options={Constant.ACTION_DROPDOWN}
                            onChange={(e: any, value: any) => handleDropDownChange("NotifyEmailWithMobileFriendlyActionCard", value)}
                        />
                    </td>
                </tr>}
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need a feature to send reminder emails? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.AllowActionableReminderEmail}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("AllowActionableReminderEmail", value)}
                            ariaLabel="Allow Actionable Reminder Email checkbox"
                            name="AllowActionableReminderEmail"
                        />
                    </td>
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need MS Teams notification? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.EnableTeamsNotification}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("EnableTeamsNotification", value)}
                            ariaLabel="Enable Teams Notification checkbox"
                            name="EnableTeamsNotification"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TenantNotification;