/* eslint-disable prettier/prettier */
import * as React from 'react';
import * as Styled from '../Styles/Layout';
import { withReduxContext } from '@employee-experience/common/lib/withReduxContext';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Constant } from './Constants';
import { appContext } from '../../App';
import { fetchAuthToken, fetchEnvironmentList, checkUserRole } from './Services';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react';
import { IDropdownOption } from '@fluentui/react';
import { generateGuid } from '../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from './Helpers/telemetryHelpers';

function SecondaryHeader(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const [envValue, setEnvValue] = React.useState([]);
    const { context, setContext } = React.useContext(appContext);

    React.useEffect(() => {
        var alias: string;
        const guid = generateGuid();

        //get user alias
        authClient
            .getUser()
            .then((user: { email: string; }) => {
                alias = user.email.split('@')[0];
                setContext((prevState: object) => ({ ...prevState, userAlias: alias }))

                trackBusinessProcessEvent(
                    authClient,
                    telemetryClient,
                    'SecondaryHeader',
                    'MSApprovals.SecondaryHeader.authClient.Success',
                    TrackingEventId.GetUserFromAuthClientSuccess,
                    { userAlias: alias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            });

        const guid1 = generateGuid();

        //get auth token
        fetchAuthToken(authClient)
            .then((token: string) => {
                setContext((prevState: object) => ({ ...prevState, authToken: token }))

                trackBusinessProcessEvent(
                    authClient,
                    telemetryClient,
                    'SecondaryHeader',
                    'MSApprovals.SecondaryHeader.fetchAuthToken.Success',
                    TrackingEventId.GetAuthTokenSuccess,
                    { userAlias: alias, messageId: guid1.msgId, xcv: guid1.xcv, environment: context.environment }
                );

                const guid = generateGuid();

                //get env list
                fetchEnvironmentList(token, alias, guid.msgId, guid.xcv)
                    .then((response: { data: string[]; }) => {
                        const envList = response.data.map((item: string) => {
                            return { key: item, text: item }
                        })
                        setEnvValue(envList);
                        setContext((prevState: any) => ({ ...prevState, environment: envList[0].text }));

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'SecondaryHeader',
                            'MSApprovals.SecondaryHeader.fetchEnvironmentList.Success',
                            TrackingEventId.GetEnvironmentListSuccess,
                            { userAlias: alias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                        checkUserRole(envList[0].text, token, alias)
                            .then((response)=>{
                                if(response.data)
                                  setContext((prevState: object) => ({ ...prevState, isAdmin: true }));
                                else
                                  setContext((prevState: object) => ({ ...prevState, isAdmin: false }));

                                trackBusinessProcessEvent(
                                    authClient,
                                    telemetryClient,
                                    'SecondaryHeader',
                                    'MSApprovals.SecondaryHeader.CheckUserRole.Success',
                                    TrackingEventId.CheckUserRoleSuccess,
                                    { userAlias: alias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                                );
                            })
                            .catch((error)=>{
                                const errorMessage = error.response ?? error;
                                trackException(
                                    authClient,
                                    telemetryClient,
                                    'SecondaryHeader',
                                    'MSApprovals.SecondaryHeader.CheckUserRole.Failure',
                                    TrackingEventId.CheckUserRoleFailure,
                                    errorMessage,
                                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                                );
                            })
                    })
                    .catch((error) => {
                        const errorMessage = error.response ?? error;
                        trackException(
                            authClient,
                            telemetryClient,
                            'SecondaryHeader',
                            'MSApprovals.SecondaryHeader.fetchEnvironmentList.Failure',
                            TrackingEventId.GetEnvironmentListFailure,
                            errorMessage,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    });
            })
            .catch((error) => {
                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'SecondaryHeader',
                    'MSApprovals.SecondaryHeader.fetchAuthToken.Failure',
                    TrackingEventId.GetAuthTokenFailure,
                    errorMessage,
                    { userAlias: context.userAlias, messageId: guid1.msgId, xcv: guid1.xcv, environment: context.environment }
                );
            });
    }, []);

    const onEnvironmentChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const env = item.text;
        setContext((prevState: object) => ({ ...prevState, environment: env }));
        const guid = generateGuid();
        checkUserRole(env, context.authToken, context.userAlias)
                            .then((response)=>{
                                if(response.data)
                                  setContext((prevState: object) => ({ ...prevState, isAdmin: true }));
                                else
                                  setContext((prevState: object) => ({ ...prevState, isAdmin: false }));

                                trackBusinessProcessEvent(
                                    authClient,
                                    telemetryClient,
                                    'SecondaryHeader',
                                    'MSApprovals.SecondaryHeader.CheckUserRole.Success',
                                    TrackingEventId.CheckUserRoleSuccess,
                                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                                );
                            })
                            .catch((error)=>{
                                const errorMessage = error.response ?? error;
                                trackException(
                                    authClient,
                                    telemetryClient,
                                    'SecondaryHeader',
                                    'MSApprovals.SecondaryHeader.CheckUserRole.Failure',
                                    TrackingEventId.CheckUserRoleFailure,
                                    errorMessage,
                                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                                );
                            })
    }

    return (
        <Styled.SecondaryHeaderContainer>
            <Stack horizontal styles={Styled.stackStyles} padding="10px 1%">
                <Stack.Item align="center" className="sp-stack-item">
                    {Constant.ENVIRONMENT_NAME}
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        onChange={onEnvironmentChange}
                        placeholder="Select..."
                        options={envValue}
                        selectedKey={envValue.length > 0 && context.environment}
                        defaultSelectedKey={envValue.length > 0 && envValue[0].text}
                        className="sp-dropdown-subheader"
                        responsiveMode={ResponsiveMode.large}
                    />
                </Stack.Item>
            </Stack>
        </Styled.SecondaryHeaderContainer>
    )
}

const connected = withReduxContext(SecondaryHeader);
export { connected as SecondaryHeader };