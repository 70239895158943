import * as React from 'react';
import Select from 'react-dropdown-select';
import { Constant } from '../../Shared/Constants';
import { appContext } from '../../../App';
import { getFlightFeatures, postFlightFeatures } from '../../Shared/Services';
import { Label, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, TextField } from '@fluentui/react';
import { IFlightFeatureDetails } from '../../Shared/Interfaces/IFlightUsers';
import { divScrollContainer } from '../../Styles/Style';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';
import { useContext } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { format } from 'react-string-format';

export function BulkFlightFeatures(): React.ReactElement {
    const { authClient, telemetryClient } = useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        featuresList: [],
        formValues: {
            selectedFeatureId: Constant.DEFAULT_EMPTY_STRING,
            selectedFeatureName: Constant.DEFAULT_EMPTY_STRING,
            alias: Constant.DEFAULT_EMPTY_STRING
        },
        message: { success: [], error: Constant.DEFAULT_EMPTY_STRING },
        isLoader: false
    })

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {
            setState((prevState) => ({
                ...prevState,
                isLoader: false,
                message: { error: Constant.DEFAULT_EMPTY_STRING, success: [] },
                formValues: { selectedFeatureId: Constant.DEFAULT_EMPTY_STRING, selectedFeatureName: Constant.DEFAULT_EMPTY_STRING, alias: Constant.DEFAULT_EMPTY_STRING },
                featuresList: []
            }))

            const guid = generateGuid();

            getFlightFeatures(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        const featureList = response.data.map((feature: { featureName: string; id: string; }) => {
                            return { label: feature.featureName, value: feature.id }
                        })
                        setState((prevState) => ({ ...prevState, featuresList: featureList }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'FlightUsers',
                            'MSApprovals.FlightUsers.getFlightFeatures.Success',
                            TrackingEventId.GetFlightFeaturesSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch(error => {
                    setState((prevState) => ({ 
                        ...prevState, 
                        message: { 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            success: [] 
                        } 
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'FlightUsers',
                        'MSApprovals.FlightUsers.getFlightFeatures.Failure',
                        TrackingEventId.GetFlightFeaturesFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                });
        }

    }, [context.environment])


    const handleFeaturesDropdownChange = (selectedOption: any[]) => {
        setState((prevState) => ({
            ...prevState,
            formValues: {
                ...prevState.formValues,
                selectedFeatureName: selectedOption[0].label,
                selectedFeatureId: selectedOption[0].value
            },
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: [] }
        }))
    }

    const handleTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => {
        setState((prevState) => ({
            ...prevState,
            formValues: {
                ...prevState.formValues,
                alias: newText
            },
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: [] }
        }))
    }

    const onSubmit = (buttonType: string) => (): void => {
        setState((prevState) => ({
            ...prevState,
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: [] },
            isLoader: true
        }))

        if (state.formValues.alias != Constant.DEFAULT_EMPTY_STRING &&
            state.formValues.selectedFeatureId != Constant.DEFAULT_EMPTY_STRING &&
            state.formValues.selectedFeatureName != Constant.DEFAULT_EMPTY_STRING) {

            var flightFeatureDetails: IFlightFeatureDetails = {
                aliases: state.formValues.alias,
                featureId: state.formValues.selectedFeatureId,
                featureName: state.formValues.selectedFeatureName,
                isBulkSubscribeEnabled: buttonType == Constant.SUBSCRIBE ? true : false
            }

            const guid = generateGuid();

            postFlightFeatures(flightFeatureDetails, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        setState((prevState) => ({
                            ...prevState,
                            message: response.data.length > 0 ?
                                { error: Constant.DEFAULT_EMPTY_STRING, success: response.data } :
                                { error: Constant.DEFAULT_EMPTY_STRING, success: [Constant.NO_RECORDS_AVAILABLE] },
                            isLoader: false
                        }));

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'FlightUsers',
                            'MSApprovals.FlightUsers.postFlightFeatures.Success',
                            TrackingEventId.PostFlightFeaturesSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: { 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            success: [] 
                        },
                        isLoader: false
                    }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'FlightUsers',
                        'MSApprovals.FlightUsers.postFlightFeatures.Failure',
                        TrackingEventId.PostFlightFeaturesFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                message: { error: Constant.EMPTY_INPUT_FIELDS, success: [] },
                isLoader: false
            }))
        }
    };

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            <h3>Bulk Flight Users for MSApproval Features</h3>
            <br />
            {state.message.error !== Constant.DEFAULT_EMPTY_STRING ?
                <MessageBar
                    data-testid="errorMessage"
                    messageBarType={MessageBarType.error}
                    isMultiline={false}> <b>{state.message.error}</b>
                </MessageBar> :
                <React.Fragment>
                    {state.isLoader && <React.Fragment>
                        <b>{Constant.REQUEST_PROCESSING}</b>
                        <ProgressIndicator barHeight={3} />
                    </React.Fragment>
                    }
                    {(state.message.success.length > 0 &&
                        state.message.success.map((msg: string, index: number) => {
                            return (<MessageBar
                                key={index + msg}
                                data-testid="successMessage"
                                messageBarType={msg.includes("successfully") ? MessageBarType.success : MessageBarType.error}
                                isMultiline={false}> <b>{msg}</b>
                            </MessageBar>)
                        })
                    )}<br />
                </React.Fragment>
            }
            <table>
                <tbody>
                    <tr data-testid="feature-list">
                        <td className="sp-td"><Label required>Features:</Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                data-testid="select-dropdown"
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.featuresList}
                                loading={state.featuresList.length > 0 ? false : true}
                                onChange={(value: any) => handleFeaturesDropdownChange(value)}
                                searchable={false}
                                values={[...state.formValues.selectedFeatureName]}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <TextField
                data-testid="Alias-Text-Field"
                label="Enter Aliases (semicolon separated) :"
                multiline
                rows={5}
                className="sp-td"
                onChange={handleTextFieldChange}
                required
            />
            <br />
            <div className="sp-td">
                <PrimaryButton
                    text={Constant.SUBSCRIBE}
                    onClick={onSubmit(Constant.SUBSCRIBE)}
                    className="sp-btn sp-td"
                />
                <PrimaryButton
                    text={Constant.UNSUBSCRIBE}
                    onClick={onSubmit(Constant.UNSUBSCRIBE)}
                    className="sp-btn"
                />
            </div>
        </div>
        : <div>You are unauthorized to access this page</div>
    );
}
