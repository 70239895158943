import * as React from 'react';
import { Constant } from '../Constants';
import { Checkbox, Dropdown, IDropdownOption, Label, TextField } from '@fluentui/react';
import { MultiStepContainer, spText, TenantInfoDetailsTableContainer } from '../../Styles/Style';
import { multiStepContext } from '../../../App';

const TenantInfoDetails = ({ admin, updateFormData }: any) => {

    const { formData, setFormData } = React.useContext(multiStepContext);

    const handleTextValueChange = (textField: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        switch(textField) {
            case "MaxTenantThreads":
            case "BulkActionCalls": {
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    setFormData({ ...formData, [textField]: value === '' ? 0 : parseInt(value) });
                 }
                break;
            }
            default: {
                setFormData({ ...formData, [textField]: value })
                break;
            }
        }

    };

    const handleDropDownChange = (fieldName: string, item: IDropdownOption) => {
        setFormData({ ...formData, [fieldName]: item.key })
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        setFormData({ ...formData, [fieldName]: isChecked })
    };

    React.useEffect(() => {
        updateFormData(formData);
    }, [formData]);

    return (
        <div className={MultiStepContainer.divScroll}>
            <table>
                <tbody>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Application Name: </Label></td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <TextField
                            value={formData.TenantName}
                            onChange={handleTextValueChange("TenantName")}
                            ariaLabel="Tenant Name text field"
                            placeholder="Enter text..."
                            errorMessage={formData.TenantName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""} 
                        />
                    </td>
                </tr>
                {
                    admin && <tr>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Subscription Name: </Label></td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.SubscriptionName}
                                onChange={handleTextValueChange("SubscriptionName")}
                                ariaLabel="Subscription Name text field"
                                placeholder="Enter text..."
                                errorMessage={formData.SubscriptionName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""} />
                        </td>
                    </tr>
                }
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Which type of request you have? </Label></td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            options={Constant.SUBMISSION_TYPE}
                            defaultSelectedKey={formData.ActionSubmissionType}
                            onChange={(e: any, value: any) => handleDropDownChange("ActionSubmissionType", value)}
                            placeholder="--Select--"
                            aria-label = "Action Submission Type dropdown field"
                            errorMessage={formData.ActionSubmissionType === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                        />
                    </td>
                    {formData.ActionSubmissionType === "Bulk" && <React.Fragment>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Which type of request(s) your API supports? </Label></td>
                        <td className={TenantInfoDetailsTableContainer.dropdownContainer}>
                            <Dropdown
                                options={Constant.API_REQUEST_TYPE}
                                defaultSelectedKey={formData.APIRequestType}
                                onChange={(e: any, value: any) => handleDropDownChange("APIRequestType", value)}
                                className={spText}
                                placeholder="--Select--"
                                ariaLabel = "API Request Type dropdown field"
                                errorMessage={formData.APIRequestType === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                    </React.Fragment>}
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>How many bulk approvals does your API supports? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <TextField
                            value={formData.BulkActionCalls}
                            onChange={handleTextValueChange("BulkActionCalls")}
                            ariaLabel="Bulk Action Concurrent calls text field"
                            placeholder="Enter number..."
                        />
                    </td>
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need to have a prefix for each of your request? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            placeholder="--Select--"
                            options={Constant.AGREE_TYPE}
                            defaultSelectedKey={formData.AgreeForDocPrefix}
                            ariaLabel="Agree For Doc Prefix dropdown field"
                            onChange={(e: any, value: any) => handleDropDownChange("AgreeForDocPrefix", value)}
                        />
                    </td>
                    {formData.AgreeForDocPrefix === Constant.AGREE_TYPE[0].key && <React.Fragment>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <Label>Please provide the value: </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.DocNumberPrefix}
                                onChange={handleTextValueChange("DocNumberPrefix")}
                                ariaLabel="Document number prefix text field"
                                placeholder="Enter text..."
                            />
                        </td>
                    </React.Fragment>}
                </tr>
                {
                    admin && <tr>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Maximum Tenant Threads: </Label></td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.MaxTenantThreads}
                                onChange={handleTextValueChange("MaxTenantThreads")}
                                ariaLabel="Max Tenant Threads Name text field"
                                placeholder="Enter number..."
                                autoComplete="off" />
                        </td>
                    </tr>
                }
                {
                    admin && <tr>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Registered Clients: </Label></td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.RegisteredClients}
                                onChange={handleTextValueChange("RegisteredClients")}
                                ariaLabel="Registered Clients text field"
                                placeholder="Enter text..."
                                autoComplete="off"
                                errorMessage={formData.RegisteredClients === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""} />
                        </td>
                    </tr>
                }
                {
                    admin && <tr>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}><Label required>Actionable Email Folder Name: </Label></td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.ActionableEmailFolderName}
                                onChange={handleTextValueChange("ActionableEmailFolderName")}
                                ariaLabel="Actionable Email Folder Name text field"
                                placeholder="Enter text..."
                                autoComplete="off"
                                errorMessage={formData.ActionableEmailFolderName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""} />
                        </td>
                    </tr>
                }
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Is Controls And Compliance Required? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.ControlComplianceRequired}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("ControlComplianceRequired", value)}
                            ariaLabel="Control and Compliance Required checkbox"
                            name="ControlComplianceRequired"
                        />
                    </td>
                    {formData.ControlComplianceRequired && <React.Fragment>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <Label>Compliance Acknowledgement Text: </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <TextField
                                value={formData.ComplianceAckText}
                                onChange={handleTextValueChange("ComplianceAckText")}
                                ariaLabel="Compliance acknowledgement text text field"
                                placeholder="Enter text..."
                            />
                        </td>
                    </React.Fragment>}
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>What information would you like to display when action buttons are disabled? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <TextField
                            value={formData.BtnDisabledReason}
                            onChange={handleTextValueChange("BtnDisabledReason")}
                            ariaLabel="Button Disabled Reason text field"
                            placeholder="Enter text..."
                        />
                    </td>
                </tr>
                {admin && <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Enable Modern Adaptive UI: </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            placeholder="--Select--"
                            defaultSelectedKey={formData.EnableModernAdaptiveUI}
                            options={Constant.ACTION_DROPDOWN}
                            ariaLabel="Enable Modern Adaptive UI dropdown field"
                            onChange={(e: any, value: any) => handleDropDownChange("EnableModernAdaptiveUI", value)}
                        />
                    </td>
                </tr>}
                {admin && <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label required>Tenant Type: </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Dropdown
                            placeholder="--Select--"
                            defaultSelectedKey={formData.TenantType}
                            options={Constant.TENANT_TYPE}
                            ariaLabel="Tenant Type dropdown field"
                            onChange={(e: any, value: any) => handleDropDownChange("TenantType", value)}
                        />
                    </td>
                </tr>}
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need a history functionality? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.HistoryLoggingEnabled}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("HistoryLoggingEnabled", value)}
                            ariaLabel="History Logging Enabled checkbox"
                            name="HistoryLoggingEnabled"
                        />
                    </td>
                    {formData.HistoryLoggingEnabled && <React.Fragment>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <Label>Does your user prefer to view details<br /> from the history? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsHistoryClickable}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsHistoryClickable", value)}
                                ariaLabel="User Preference For History checkbox"
                                name="IsHistoryClickable"
                            />
                        </td>
                    </React.Fragment>}
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you need a feature for an aggregated data of line items? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.IsPictorialLineItemsEnabled}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsPictorialLineItemsEnabled", value)}
                            ariaLabel="Is Pictorial Line Items Enabled checkbox"
                            name="IsPictorialLineItemsEnabled"
                        />
                    </td>
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do you have a feature that multiple approvers can come at same level? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.IsSameApproverMultiLevelSupport}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsSameApproverMultiLevelSupport", value)}
                            ariaLabel="Is Same Approver MultiLevel Support checkbox"
                            name="IsSameApproverMultiLevelSupport"
                        />
                    </td>
                    {formData.IsSameApproverMultiLevelSupport && <React.Fragment>
                        <td className={TenantInfoDetailsTableContainer.tdContainer}>
                            <Label>Do you need to display all current approver in hierarchy or <br /> only logged in user in hierarchy? </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsAllCurrentApproversDisplayInHierarchy}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsAllCurrentApproversDisplayInHierarchy", value)}
                                ariaLabel="Is All Current Approvers Display In Hierarchy checkbox"
                                name="IsAllCurrentApproversDisplayInHierarchy"
                            />
                        </td>
                    </React.Fragment>}
                </tr>
                {admin && <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Use Document number for RowKey? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.DocNumberForRowKey}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("DocNumberForRowKey", value)}
                            ariaLabel="Document Number For RowKey checkbox"
                            name="DocNumberForRowKey"
                        />
                    </td>
                </tr>}
                {admin && <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Allow Pull Model? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.AllowPullModel}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("AllowPullModel", value)}
                            ariaLabel="Allow Pull Model checkbox"
                            name="AllowPullModel"
                        />
                    </td>
                </tr>}
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>
                            We will cache the detailed data for faster responsiveness of<br /> application to the user.
                            However due to compliance, if you prefer,<br /> please agree and we do not store any of the details data
                            for the<br /> transaction period.
                            </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.IsExternalTenantActionDetails}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsExternalTenantActionDetails", value)}
                            ariaLabel="Is External Tenant Action Details checkbox"
                            name="IsExternalTenantActionDetails"
                        />
                    </td>
                </tr>
                <tr>
                    <td className={TenantInfoDetailsTableContainer.tdContainer}>
                        <Label>Do yo have editable details feature? </Label>
                    </td>
                    <td className={TenantInfoDetailsTableContainer.checkboxContainer}>
                        <Checkbox
                            checked={formData.DetailsEditable}
                            onChange={(e: any, value: boolean) => handleCheckboxChange("DetailsEditable", value)}
                            ariaLabel="Details Editable checkbox"
                            name="DetailsEditable"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}
export default TenantInfoDetails;