export const navigationConfig = [
    {
        key: 'Main',
        links: [
            {
                name: 'Telemetry and Logs',
                icon: 'FullHistory',
                ariaLabel: 'Telemetry and Logs',
                links: [
                    {
                        name: "By Request Number",
                        href: "/ByDocNumber",
                        ariaLabel: "By Request Number",
                        requestType: "RequestHistory"
                    },
                    {
                        name: "By User Session History",
                        href: "/ByUserAlias",
                        ariaLabel: "By User Session History",
                        requestType: "ByUserAlias"
                    },
                    {
                        name: "By Tracking Id",
                        href: "/ByTrackId",
                        ariaLabel: "By Tracking Id",
                        requestType: "ByTrackingID"
                    }
                ]
            },
            {
                name: 'Reports',
                icon: 'ReportDocument',
                aiaLabel: 'Reports',
                links: [
                    {
                        name: "Feature Usage",
                        href: "/FeatureUsage",
                        ariaLabel: "Feature USage"
                    }
                ]
            },
            {
                name: 'MSA Specific',
                icon: 'Certificate',
                aiaLabel: 'MS Approvals specific menu',
                links: [
                    {
                        name: "Request Status",
                        href: "/RequestStatus",
                        ariaLabel: "Request Status"
                    },
                    {
                        name: "By Service Bus Topic & Monitoring",
                        href: "/ByServiceBusTopic",
                        ariaLabel: "By Service Bus Topic Monitoring",
                        requestType: "BySBTopic"
                    },
                    {
                        name: "Payload History",
                        href: "/PayloadHistory",
                        ariaLabel: "Payload History"
                    }

                ]
            },
            {
                name: 'Admin Settings',
                icon: 'Admin',
                ariaLabel: 'Admin Settings',
                links: [
                    {
                        name: "Impersonation",
                        href: "/UserDelegation",
                        ariaLabel: "Impersonation"
                    },
                    {
                        name: "Website: User Actions",
                        href: "/UserActions",
                        ariaLabel: "Website User Actions"
                    },
                    {
                        name: "Bulk Flight Users",
                        href: "/FlightFeatures",
                        ariaLabel: "Bulk Flight Users"
                    },
                    {
                        name: "Alert Notifications",
                        href: "/AlertNotifications",
                        ariaLabel: "Alert Notifications"
                    },
                    {
                        name: "Create Out of Sync Requests",
                        href: "/MarkRequestOutofSync",
                        ariaLabel: "Create Out of Sync Requests"
                    },
                    {
                        name: "View/Approve Tenant",
                        href: "/ApproveTenant",
                        ariaLabel: "View Approve Tenant"
                    },
                    {
                        name: "Edit Tenant",
                        href: "/EditTenant",
                        ariaLabel: "Edit Tenant"
                    }
                ]
            },
            {
                name: 'Tenant Onboarding',
                icon: 'AddFriend',
                ariaLabel: 'Tenant Onboarding',
                links: [
                    {
                        name: "Onboard Tenant",
                        href: "/OnboardTenant",
                        ariaLabel: "Onboard Tenant"
                    }
                ]
            }
        ]
    }
];