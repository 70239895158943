import { Checkbox, Dropdown, IDropdownOption, Label, TextField } from '@fluentui/react';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import { multiStepContext } from '../../../App';
import { EngineeringContainer, MultiStepContainer, TenantInfoDetailsTableContainer } from '../../Styles/Style';
import { Constant } from '../Constants';


function ApprovalsEngineering(): React.ReactElement {
    const { formData, setFormData } = React.useContext(multiStepContext);

    const handleTextValueChange = (textField: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        switch(textField) {
            case "ApprovalRetryInterval":
            case "MaxThreadsForSecondaryAction":
            case "RaceConditionSleepTime":
            case "TenantDetailRetryCount": {
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    setFormData({ ...formData, [textField]: value === '' ? 0 : parseInt(value) });
                 }
                break;
            }
            default: {
                setFormData({ ...formData, [textField]: value })
                break;
            }
        }
    };

    const handleDropDownChange = (fieldName: string, item: IDropdownOption) => {
        setFormData({ ...formData, [fieldName]: item.key })
    };
    
    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        setFormData({ ...formData, [fieldName]: isChecked })
    };


    return (
        <div className={MultiStepContainer.divScroll}>
            <Table>
                <tbody>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label required>Business Process Name: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.BusinessProcessName}
                                onChange={handleTextValueChange("BusinessProcessName")}
                                ariaLabel="Business Process Name text field"
                                placeholder="Enter text..."
                                errorMessage={formData.BusinessProcessName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label required>Class Name: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.ClassName}
                                onChange={handleTextValueChange("ClassName")}
                                ariaLabel="Class Name text field"
                                placeholder="Enter text..."
                                errorMessage={formData.ClassName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Race Condition Sleep Time: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.RaceConditionSleepTime}
                                onChange={handleTextValueChange("RaceConditionSleepTime")}
                                ariaLabel="Race Condition Sleep Time text field"
                                placeholder="Enter number..."
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Max Threads For Secondary Action: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.MaxThreadsForSecondaryAction}
                                onChange={handleTextValueChange("MaxThreadsForSecondaryAction")}
                                ariaLabel="Max Threads For Secondary Action text field"
                                placeholder="Enter number..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label required>Process Secondary Action: </Label>
                        </td>
                        <td className={EngineeringContainer.dropdownContainer}>
                        <Dropdown
                            options={Constant.SECONDARY_ACTIONS}
                            defaultSelectedKey={formData.ProcessSecondaryAction}
                            onChange={(e: any, value: any) => handleDropDownChange("ProcessSecondaryAction", value)}
                            placeholder="--Select--"
                            errorMessage={formData.ProcessSecondaryAction === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                        />
                    </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label required>Template Name: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.TemplateName}
                                onChange={handleTextValueChange("TemplateName")}
                                ariaLabel="Template Name text field"
                                placeholder="Enter text..."
                                errorMessage={formData.TemplateName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Tenant Detail Retry Count: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.TenantDetailRetryCount}
                                onChange={handleTextValueChange("TenantDetailRetryCount")}
                                ariaLabel="Tenant Detail Retry Count text field"
                                placeholder="Enter number..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label required>Validation Class Name: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.ValidationClassName}
                                onChange={handleTextValueChange("ValidationClassName")}
                                ariaLabel="Validation Class Name text field"
                                placeholder="Enter text..."
                                errorMessage={formData.ValidationClassName === Constant.DEFAULT_EMPTY_STRING ? "Mandatory Field" : ""}
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Data Model Mapping: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.DataModelMapping}
                                onChange={handleTextValueChange("DataModelMapping")}
                                ariaLabel="DataModel Mapping text field"
                                placeholder="Enter text..."
                            />
                        </td>
                    </tr>
                    <tr>                      
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Line Item Filter Categories: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.LineItemFilterCategories}
                                onChange={handleTextValueChange("LineItemFilterCategories")}
                                ariaLabel="Line Item Filter Categories text field"
                                placeholder="Enter text..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Detail Url: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.DetailUrl}
                                onChange={handleTextValueChange("DetailUrl")}
                                ariaLabel="Detail Url text field"
                                placeholder="Enter text..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Approval Retry Interval: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.ApprovalRetryInterval}
                                onChange={handleTextValueChange("ApprovalRetryInterval")}
                                ariaLabel="Approval Retry Interval text field"
                                placeholder="Enter number..."
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Adaptive Card Version: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.AdaptiveCardVersion}
                                onChange={handleTextValueChange("AdaptiveCardVersion")}
                                ariaLabel="Adaptive Card Version text field"
                                placeholder="Enter text..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Adaptive Card Cache Clients: </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.AdaptiveCardCacheClients}
                                onChange={handleTextValueChange("AdaptiveCardCacheClients")}
                                ariaLabel="Adaptive Card Cache Clients text field"
                                placeholder="Enter text..."
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Actionable Notification TemplateKeys : </Label>
                        </td>
                        <td className={EngineeringContainer.inputFieldContainer}>
                            <TextField
                                value={formData.ActionableNotificationKeys}
                                onChange={handleTextValueChange("ActionableNotificationKeys")}
                                ariaLabel="Actionable Notification Keys text field"
                                placeholder="Enter text..."
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Is Day Zero Activity Running: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsDayZeroActivityRunning}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsDayZeroActivityRunning", value)}
                                ariaLabel="Is Day Zero Activity Running checkbox"
                                name="IsDayZeroActivityRunning"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Is Digest Email Enabled: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsDigestEmailEnabled}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsDigestEmailEnabled", value)}
                                ariaLabel="Is Digest Email Enabled checkbox"
                                name="IsDigestEmailEnabled"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Is Race Condition Handled: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsRaceConditionHandled}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsRaceConditionHandled", value)}
                                ariaLabel="Is Race Condition Handled checkbox"
                                name="IsRaceConditionHandled"
                            />
                        </td>
                    </tr>
                    <tr>
                    <td className={EngineeringContainer.labelContainer}>
                            <Label>Enable User Delegation: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.EnableUserDelegation}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("EnableUserDelegation", value)}
                                ariaLabel="Enable User Delegation checkbox"
                                name="EnableUserDelegation"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Enable Tenant Message Processing: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.EnableTenantMessageProcessing}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("EnableTenantMessageProcessing", value)}
                                ariaLabel="Enable Tenant Message Processing checkbox"
                                name="EnableTenantMessageProcessing"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Is Background Processing Enabled: </Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.IsBackgroundProcessingEnabled}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("IsBackgroundProcessingEnabled", value)}
                                ariaLabel="Is Background Processing Enabled checkbox"
                                name="IsBackgroundProcessingEnabled"
                            />
                        </td>
                    </tr>
                    <tr>
                    <td className={EngineeringContainer.labelContainer}>
                            <Label>Process Attached Summary:</Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.ProcessAttachedSummary}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("ProcessAttachedSummary", value)}
                                ariaLabel="Process Attached Summary checkbox"
                                name="ProcessAttachedSummary"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Enable Adaptive Card Caching:</Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.EnableAdaptiveCardCaching}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("EnableAdaptiveCardCaching", value)}
                                ariaLabel="Enable Adaptive Card Caching checkbox"
                                name="EnableAdaptiveCardCaching"
                            />
                        </td>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Enable Old Hierarchy:</Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.EnableOldHierarchy}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("EnableOldHierarchy", value)}
                                ariaLabel="EnableOldHierarchy checkbox"
                                name="EnableOldHierarchy"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={EngineeringContainer.labelContainer}>
                            <Label>Tenant Enabled:</Label>
                        </td>
                        <td className={EngineeringContainer.checkboxContainer}>
                            <Checkbox
                                checked={formData.TenantEnabled}
                                onChange={(e: any, value: boolean) => handleCheckboxChange("TenantEnabled", value)}
                                ariaLabel="Tenant Enabled checkbox"
                                name="TenantEnabled"
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default ApprovalsEngineering;