import { Text } from 'office-ui-fabric-react/lib/Text';
import { mergeStyleSets } from 'office-ui-fabric-react';
import { minWidth } from './Media';
import { IStackTokens, IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import { IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import { ITextFieldStyles } from 'office-ui-fabric-react/lib/TextField';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { FontWeights } from 'office-ui-fabric-react';
import { FontSizes } from '@cseo/styles';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';

export const Container = styled.div`
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: 2%;
    margin-right: 5%;
`;

export const SummaryContainer = styled.div`
    padding-top: 32px;
    padding-left: 2%;
    margin: 0;

    @media only screen and (max-width: 480px) {
        padding-top: 0px !important;
    }
`;

export const NavContainer = styled.div`
    height: 100%;

    @media only screen and (max-device-width: 480px){
        height: 56px;
    }
`;

export const SecondaryHeaderContainer = styled.div`
    height: 40px;   

    .ms-Button-menuIcon {
        color: rgb(0, 13, 23)!important;
    }

    @media only screen and (max-width: 1024px) {
        margin-left: 0px !important;
    }
`;

export const Space = styled.div`
    margin-bottom: 24px;
`;

export const FooterSpace = styled.div`
    margin-bottom: 0px;
`;

export const SmallSpace = styled.div`
    margin-bottom: 12px;
`;

export const LargePadding = styled.div`
    padding-bottom: 50px;
`;

export const PageHeading = styled(Text).attrs({
    as: 'h1',
    variant: 'xLarge',
    block: true
})`
    margin-bottom: 6px;
`;

export const PageDescription = styled(Text).attrs({
    as: 'p',
    block: true
})`
    margin-bottom: 24px;
`;

export const SectionTitle = styled(Text).attrs({
    as: 'h2',
    variant: 'large',
    block: true
})`
    margin-bottom: 24px;
`;

export const TenantLabel = styled.div`
    height: 100%;
    flex-grow: 1;
    margin-left: 6px;
`;

export const HistoryColumnTenantImage = styled.div`
    width: 16px;
    height: auto;
    padding-right: 36px;
`;

export const CardTenantImage = styled.div`
    padding-top: 6px;
    height: 100%;
    float: left;
`;

export const CardGroupLabel = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: calc(3vw + 8px);
    margin-right: calc(3vw + 8px);
    align-items: center;

    ${minWidth.xl} {
        margin-left: calc(4vw - 22px);
        margin-right: calc(4vw - 22px);
    }
`;

export const PersonaContainer = styled.div`
    height: 100%;
    width: 28px;
    float: left'
`;

export const SubmitterLabel = styled.div`
    height: 100%;
    flex-grow: 1;
    margin-left: 6px;
`;

export const SummaryCount = styled(Text).attrs({
    as: 'p'
})`
    font-weight: bold;
    margin-left: 100px;
`;

export const SummaryCountLabel = styled(Text).attrs({
    as: 'p'
})`
    font-weight: regular;
    padding-left: 5px;
`;

export const FailedCount = styled(Text).attrs({
    as: 'p'
})`
    color: rgb(168, 0, 0);
    font-weight: bold;
    margin-left: 100px;
`;

export const FailedCountLabel = styled(Text).attrs({
    as: 'p'
})`
    color: rgb(168, 0, 0);
    padding-left: 5px;
`;

export const stackTokens: IStackTokens = { childrenGap: 3 };

export const filePreviewStackTokens: IStackTokens = { childrenGap: 3, padding: '5%' };

export const stackTokensLargeGap: IStackTokens = { childrenGap: 6 };

export const stackStyles: IStackStyles = {
    root: {
        background: '#e5e5e5',
        position: 'fixed',
        width: '100%',
        paddingRight: '6%',
        zIndex: 50
    }
};

export const docPreviewHeaderBarStyles: IStackStyles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

export const Styles = mergeStyleSets({
    spacing: {
        paddingLeft: '5%'
    },
    summaryCountSpacing: {
        paddingLeft: '2%'
    }
});

export const iconStyling = {
    height: "100%"
};

export const DetailCardBox = {
    boxShadow: '0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)'
};

export const DetailActionIcon = {
    float: 'right',
    marginRight: '12px',
    cursor: 'pointer',
    fontSize: '12pt'
}

export const InlinePreviewPDF = {
    height: "280"
};

export const DocumentPreviewLayout = {
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#FFFFFF'
}

export const SuccessIcon = styled(FontIcon)`
    margin-top: 20vh;
    margin-bottom: 20px;
    font-size: ${FontSizes.size58};
    color: #107c10;
`;

export const SuccessMessage = styled(Text).attrs({
    as: 'p'
})`
    font-size: ${FontSizes.size18};
    font-weight: ${FontWeights.semibold};
`;

export const MessageBarTitle = styled(Text).attrs({
    as: 'p'
})`
    font-size: ${FontSizes.size16};
    font-weight: ${FontWeights.semibold};
`;

export const DelegationLabel = styled(Text).attrs({
    as: 'p'
})`
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.semibold};
    text-align: center;
    vertical-align: middle;

    @media only screen and (max-width: 1023px) {
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }

    @media only screen and (max-width: 850px) {
        width: 50px !important;
    }

    @media only screen and (max-width: 1023px) and (min-width: 851px) {
        width: 150px !important;
    }
`;


export const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    padding-left: 12px;
    bottom: 0;
    width: 100%;
    background: white;
    overflow-y: hidden;
    box-shadow: ${Depths.depth64};
    margin: auto;
    border-left: 1px solid rgba(0,0,0,.1);
`;

export const CalendarIcon = styled(FontIcon)`
    font-size: ${FontSizes.size26};
`;

export const smallDropdownStyles: Partial<IDropdownStyles> = { dropdown: { maxWidth: 500, minWidth: 150 } };
export const smallTextFieldStyles: Partial<ITextFieldStyles> = { root: { maxWidth: 500 } };

export const detailStackStyles: IStackStyles = {
    root: {
        backgroundColor: 'white',
        marginTop: '0!important'
    }
};

export const stackStylesBottomBorder: IStackStyles = {
    root: { borderBottom: `1px solid gray` }
};

export const stackAdditionDetails: IStackStyles = {
    root: {
        marginTop: '0!important',
        marginBottom: '40px'
    }
};

export const detailCardBackgroundColor = {
    root: {
        backgroundColor: 'white'
    }
};

export const flowRow = {
    flexFlow: 'row'
};
export const tooltipCalloutProps = { gapSpace: 0 };

export const tooltipHostContainer = {
    root: {
        display: 'inline-block',
        float: 'right'
    }
};