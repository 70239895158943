import { Label, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import TenantInfo from './TenantInfo';
import { Constant, LabelConstants } from '../Constants';
import { divScrollContainer } from '../../Styles/Style';
import Select from 'react-dropdown-select';
import { appContext, multiStepContext } from '../../../App';
import { getTenantsFromApprovals } from '../Services';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Helpers/telemetryHelpers';


function TenantApprovals(props: any): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { formData, setFormData } = React.useContext(multiStepContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        tenantList: [],
        isDataLoaded: false,
        isDataMappingDone: false,
        selectedTenant: [],
        errorMessage: Constant.DEFAULT_EMPTY_STRING
    })

    const { tenantType, requestType, admin } = props;

    React.useEffect(() => {      
          if (context.environment !== Constant.DEFAULT_EMPTY_STRING) {
            setState((prevState) => ({
                ...prevState,
                tenantList: [],
                isDataLoaded: false,
                isDataMappingDone: false,
                errorMessage: Constant.DEFAULT_EMPTY_STRING
            }))
            handleTenantDropdownChange([]);

            const guid = generateGuid();

            getTenantsFromApprovals(context.environment, tenantType, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response: any) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        if (response.data.length === 0) {
                            setState((prevState) => ({
                                ...prevState,
                                isDataLoaded: true,
                                errorMessage: Constant.NO_PENDING_REQUESTS
                            }))
                        }
                        else {
                            let tenantsDetails = response.data.map((item: any) => {
                                return { value: item.RowKey, label: item.AppName, tenantData: item }
                            })
                            setState((prevState) => ({
                                ...prevState,
                                tenantList: tenantsDetails,
                                isDataLoaded: true,
                                errorMessage: Constant.DEFAULT_EMPTY_STRING
                            }))
                        }

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'TenantApprovals',
                            'MSApprovals.TenantApprovals.getTenantsFromApprovals.Success',
                            TrackingEventId.GetTenantsFromApprovalsSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error: any) => {
                    setState((prevState) => ({
                        ...prevState,
                        isDataLoaded: true,
                        errorMessage: Constant.EXCEPTION_MESSAGE
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'TenantApprovals',
                        'MSApprovals.TenantApprovals.getTenantsFromApprovals.Failure',
                        TrackingEventId.GetTenantsFromApprovalsFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
          }
    }, [context.environment])


    const handleTenantDropdownChange = (selectedOption: any[]) => {
        if (selectedOption.length === 0) {
            setState((prevState) => ({ ...prevState, selectedTenant: selectedOption }))
        }
        else if (state.tenantList.length > 0) {
            let selectedOptionData = state.tenantList.find((x: any) => x.value === selectedOption[0].value);
            tenantDataMapping(selectedOptionData.tenantData);
            setState((prevState) => ({ ...prevState, isDataMappingDone: true, selectedTenant: selectedOption }))
        }
    }

    const tenantDataMapping = (tenantData: any) => {
        if (tenantData !== undefined && tenantData !== null) {
            let actionSubmissionType: any,
                apiRequestType: any,
                attachmentUrl: string,
                actionDetails: any[] = [];

            if (tenantData.ActionSubmissionType === 1 || tenantData.ActionSubmissionType === 2) {
                actionSubmissionType = Constant.SUBMISSION_TYPE[1].key;
                apiRequestType = tenantData.ActionSubmissionType;
            }
            else {
                actionSubmissionType = Constant.SUBMISSION_TYPE[0].key;
            }

            if (tenantData.ActionDetails.Primary.length > 0) {
                tenantData.ActionDetails.Primary.map((items: any) => {
                    actionDetails.push(items)
                })

            }
            if (tenantData.ActionDetails?.Secondary?.length > 0) {
                tenantData.ActionDetails.Secondary.map((items: any) => {
                    actionDetails.push(items)
                })
            }

            if (tenantData.DetailOperations.DetailOpsList.length > 0) {
                let attachment = tenantData.DetailOperations.DetailOpsList.find((x: any) => x.operationtype === "DOC1");
                if (attachment !== undefined && attachment !== null) {
                    attachmentUrl = attachment.endpointdata
                }
            }

            setFormData({
                ...formData,
                ActionSubmissionType: actionSubmissionType,
                ActionableEmailFolderName: tenantData.ActionableEmailFolderName,
                ActionableNotificationKeys: tenantData.ActionableNotificationTemplateKeys,
                ActionDetails: actionDetails,
                AdaptiveCardCacheClients: tenantData.AdaptiveCardCacheClients,
                AgreeForDocPrefix: tenantData.DocumentNumberPrefix === Constant.DEFAULT_EMPTY_STRING ? Constant.AGREE_TYPE[1].text : Constant.AGREE_TYPE[0].text,
                AllowActionableReminderEmail: tenantData.NotifyWatchDogEmailWithApprovalFunctionality,
                AllowPullModel: tenantData.IsPullModelEnabled,
                APIRequestType: apiRequestType,
                ApprovalRetryInterval: tenantData.BackgroundApprovalRetryInterval.toString(),
                AttachmentUrl: attachmentUrl,
                BulkActionCalls: tenantData.BulkActionConcurrentCall.toString(),
                BusinessProcessName: tenantData.BusinessProcessName,
                BtnDisabledReason: tenantData.ButtonDisabledReason,
                ClassName: tenantData.ClassName,
                ComplianceAckText: tenantData.ComplianceAcknowledgementText,
                DataModelMapping: tenantData.DataModelMapping,
                DetailsEditable: tenantData.IsDetailsEditable,
                DetailUrl: tenantData.TenantDetailUrl,
                DocNumberPrefix: tenantData.DocumentNumberPrefix,
                EnableAdaptiveCardCaching: tenantData.EnableAdaptiveCardCaching,
                EnableEmailNotification: tenantData.NotifyEmail,
                EnableModernAdaptiveUI: tenantData.EnableModernAdaptiveUI.toString(),
                EnableOldHierarchy: tenantData.IsOldHierarchyEnabled,
                EnableOutlookActionableEmail: tenantData.NotifyEmailWithApprovalFunctionality === true ? Constant.AGREE_TYPE[0].text : Constant.AGREE_TYPE[1].text,
                EnableTeamsNotification: tenantData.NotifyTeams,
                EnableTenantMessageProcessing: tenantData.TenantMessageProcessingEnabled,
                EnableUserDelegation: tenantData.EnableUserDelegation,
                HistoryLoggingEnabled: tenantData.HistoryLogging,
                IsAllCurrentApproversDisplayInHierarchy: tenantData.IsAllCurrentApproversDisplayInHierarchy,
                IsBackgroundProcessingEnabled: tenantData.IsBackgroundProcessingEnabledUpfront,
                ControlComplianceRequired: tenantData.IsControlsAndComplianceRequired,
                IsDayZeroActivityRunning: tenantData.IsDayZeroActivityRunning,
                IsDigestEmailEnabled: tenantData.IsDigestEmailEnabled,
                IsExternalTenantActionDetails: tenantData.IsExternalTenantActionDetails,
                IsHistoryClickable: tenantData.IsHistoryClickable,
                IsPictorialLineItemsEnabled: tenantData.IsPictorialLineItemsEnabled,
                IsRaceConditionHandled: tenantData.IsRaceConditionHandled,
                IsSameApproverMultiLevelSupport: tenantData.IsSameApproverMultipleLevelSupported,
                Key: tenantData.AppName,
                LineItemFilterCategories: tenantData.LineItemFilterCategories,
                MaxTenantThreads: tenantData.MaxTenantThreads,
                MaxThreadsForSecondaryAction: tenantData.MaxTenantThreadsForSecondary,
                NotifyEmailWithMobileFriendlyActionCard: tenantData.NotifyEmailWithMobileFriendlyActionCard.toString(),
                OperationDetails: tenantData.DetailOperations.DetailOpsList,
                PartitionKey: tenantData.PartitionKey,
                ProcessAttachedSummary: tenantData.ProcessAttachedSummary,
                ProcessSecondaryAction: tenantData.ProcessSecondaryAction,
                RaceConditionSleepTime: tenantData.RaceConditionSleepTimeInSecond,
                RegisteredClients: tenantData.RegisteredClients,
                RequestType: requestType,
                ResourceUrl: JSON.parse(tenantData.ServiceParameter).ResourceURL,
                RowKey: tenantData.RowKey,
                ServiceTreeId: tenantData.ServiceTreeId,
                SubscriptionName: tenantData.Subscription,
                SummaryUrl: tenantData.SummaryURL,
                Templates: Array<any>(),
                TemplateName: tenantData.TemplateName,
                TenantBaseUrl: tenantData.TenantBaseUrl,
                TenantDetailRetryCount: tenantData.TenantDetailRetryCount,
                TenantEnabled: tenantData.TenantEnabled,
                TenantName: tenantData.AppName,
                TenantType: tenantData.TenantType,
                ToolName: tenantData.ToolName,
                DocNumberForRowKey: tenantData.UseDocumentNumberForRowKey,
                ValidationClassName: tenantData.ValidationClassName
            })
        }
    }

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            {(state.isDataLoaded && state.tenantList.length === 0) &&
                <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={false}> <b>{state.errorMessage}</b>
                </MessageBar>
            }
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.SELECT_TENANT}</Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.tenantList}
                                loading={state.isDataLoaded ? false : true}
                                onChange={(value: any) => handleTenantDropdownChange(value)}
                                searchable={false}
                                values={[...state.selectedTenant]}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            {state.isDataMappingDone && <TenantInfo isAdmin={admin} requestType={requestType} startFormIndex={0} />}
        </div>
        : <div>You are unauthorized to access this page</div>
    )
}

export default TenantApprovals;