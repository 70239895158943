import { FontIcon, IconButton } from '@fluentui/react';
import * as React from 'react';
import { iconClass, InlinePreview } from '../../Styles/Style';
import Popup from '../Popup';


function DocumentPreview(props: any): React.ReactElement {
    const [rotation, setRotation] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const type = props.documentToPreview[0];
    const isImage = type === '/' || type === 'i' || type === 'R';
    const previewMaxHeight = 200;
    const previewMaxSize = 50;
    const rotationAlt = rotation != 0 ? ` rotated ${rotation} degrees` : '';

    function handleGetDimensions(e: object): void {
        const { naturalWidth, naturalHeight } = (e as any).target;
        setWidth(naturalWidth);
        setHeight(naturalHeight);
    }

    function handleRotate(): void {
        if (rotation === 270) {
            setRotation(0);
        } else {
            const newRotation = rotation + 90;
            setRotation(newRotation);
        }
    }

    function calcContainerStyle(): React.CSSProperties {
        const style: React.CSSProperties = {
            overflow: `auto`,
            minHeight: `${previewMaxHeight}px`,
            maxWidth: `${previewMaxSize}px`,
            margin: 'auto'
        };
        switch (rotation) {
            case 90:
                if (width > previewMaxHeight) {
                    style.height = `${previewMaxHeight}px`;
                }
                return style;
            case 270:
                if (width > previewMaxSize) {
                    style.height = `${previewMaxSize}px`;
                }

                if (height > previewMaxHeight) {
                    style.width = `${previewMaxHeight}px`;
                }
                return style;
            default:
                return style;
        }
    }

    function calcImgStyle(): React.CSSProperties {
        const style: React.CSSProperties = {
            display: `block`,
            transformOrigin: `top left`
        }
        switch (rotation) {
            case 90:
                style.transform = `rotate(${rotation}deg) translateY(-100%)`;
                return style;
            case 180:
                style.transform = `rotate(${rotation}deg) translate(-100%, -100%)`;
                return style;
            case 270:
                style.transform = `rotate(${rotation}deg) translateX(-100%)`;
                return style;
            default:
                return style;
        }
    }

    const renderPreview = (documentToPreview: string) => {
        const getExtensionName = (typeChar: string): string => {
            switch (typeChar) {
                case '/':
                    return 'jpg';
                case 'i':
                    return 'png';
                case 'R':
                    return 'gif';
                case 'e':
                    return 'json';
                case 'P':
                    return 'html';
            }
        };
        const extensionName = getExtensionName(type);
        if (isImage) {
            return (
                <div style={calcContainerStyle()} data-testid="imageLoad">
                    <img
                        style={calcImgStyle()}
                        src={`data:image/${extensionName};base64,${documentToPreview}`}
                        alt={'Image preview' + rotationAlt}
                        onLoad={handleGetDimensions}
                    />
                </div>
            );
        }
        else if(extensionName === 'json') {
            return (               
                <iframe
                    src={`data:application/${extensionName};base64,${documentToPreview}`}
                    name="JSON file preview"
                    id="JSONFile"
                    title="JSON file preview"
                    className={InlinePreview}
                />
            );
        }
        else if(extensionName === 'html') {
            return (
                <iframe
                    src={`data:text/${extensionName};base64,${documentToPreview}`}
                    name="HTML file preview"
                    title="HTML file preview"
                    className={InlinePreview}
                />
            );
        }
    }

    return (
        <React.Fragment>
            {props.isOpen && <Popup
                content={<>
                    <FontIcon
                        iconName='ChromeClose'
                        title="Preview Close"
                        className={iconClass.previewCloseIcon}
                        role="button"
                        onClick={props.isClose} />
                    <br />
                    {isImage && (
                        <IconButton
                            iconProps={{ iconName: 'Rotate' }}
                            title="Rotate 90 degrees"
                            ariaLabel="Rotate 90 degrees"
                            onClick={handleRotate}
                        />
                    )}
                    {renderPreview(props.documentToPreview)}
                </>}
            />}
        </React.Fragment>
    )
}

export default DocumentPreview;