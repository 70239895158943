import * as React from 'react';
import Select from 'react-dropdown-select';
import { Constant, HeaderConstants, LabelConstants, TableHeaderConstants } from '../../Shared/Constants';
import { appContext } from '../../../App';
import { getTenantsInfo, updateActionDetails } from '../../Shared/Services';
import { Checkbox, Label, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator } from '@fluentui/react';
import { IUserActions } from '../../Shared/Interfaces/IUserActions';
import { format } from 'react-string-format';
import { divScrollContainer } from '../../Styles/Style';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';

function UserActions(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        tenantList: [],
        tenant: Constant.DEFAULT_EMPTY_STRING,
        tenantData: { value: Constant.DEFAULT_EMPTY_STRING, label: Constant.DEFAULT_EMPTY_STRING, details: { Primary: [], Secondary: [] } },
        actions: [],
        message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
        btnDisabled: false,
        isDataSubmitting: false
    })
    var action: any[] = [];

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {
            setState((prevState) => ({
                ...prevState,
                tenantList: [],
            }));

            const guid = generateGuid();

            getTenantsInfo(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        const tenantsDetails = response.data.map((item: { rowKey: string; appName: string; tenantActionDetails: string }) => {
                            return { value: item.rowKey, label: item.appName, details: item.tenantActionDetails }
                        })
                        setState((prevState) => ({ ...prevState, tenantList: tenantsDetails }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'UserActions',
                            'MSApprovals.UserActions.getTenantsInfo.Success',
                            TrackingEventId.GetTenantInfoSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId) 
                        }
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'UserActions',
                        'MSApprovals.UserActions.getTenantsInfo.Failure',
                        TrackingEventId.GetTenantInfoFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, [context.environment])

    const handleTenantDropdownChange = (selectedOption: any[]) => {
        if (state.tenantList.length > 0) {
            let selectedTenantData = state.tenantList.find(x => x.label === selectedOption[0].label);

            setState((prevState) => ({
                ...prevState,
                tenantData: selectedTenantData,
                tenant: selectedOption[0].label,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING }
            }))

            if (typeof (selectedTenantData.details) != "object") {
                selectedTenantData.details = JSON.parse(selectedTenantData.details);
            }

            if (selectedTenantData.details.Primary?.length > 0) {
                const primaryAction = selectedTenantData.details.Primary.map((item: IUserActions) => {
                    return { Name: item.Name, IsEnabled: item.IsEnabled }
                })
                action.push(primaryAction);
            }

            if (selectedTenantData.details.Secondary?.length > 0) {
                const secondaryAction = selectedTenantData.details.Secondary.map((item: IUserActions) => {
                    return { Name: item.Name, IsEnabled: item.IsEnabled }
                })
                action.push(secondaryAction);
            }

            var flatAction = action.reduce((a, b) => { return a.concat(b) });
            setState((prevState) => ({ ...prevState, actions: flatAction }))
        }
    }

    const handleCheckboxChange = (item: any, isChecked: boolean, index: number) => {
        let actionDetails: any;
        let actionData = state.actions.slice();
        actionData[index].IsEnabled = isChecked;

        if (state.tenantData.details?.Primary?.length > 0) {
            actionDetails = state.tenantData.details.Primary.find((x: any) => x.Name === item.Name)
            if (actionDetails) {
                actionDetails.IsEnabled = isChecked;
            }
        }

        if (state.tenantData.details?.Secondary?.length > 0) {
            actionDetails = state.tenantData.details.Secondary.find((x: any) => x.Name === item.Name)
            if (actionDetails) {
                actionDetails.IsEnabled = isChecked;
            }
        }

        setState((prevState) => ({ ...prevState, actions: actionData }))
    }

    const onSubmit = () => {
        setState((prevState) => ({
            ...prevState,
            message: { error: Constant.DEFAULT_EMPTY_STRING, success: Constant.DEFAULT_EMPTY_STRING },
            btnDisabled: true,
            isDataSubmitting: true
        }))

        const guid = generateGuid();

        if (state.tenantData.details && state.tenantData.value != Constant.DEFAULT_EMPTY_STRING) {
            updateActionDetails(state.tenantData.details, state.tenantData.value, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        setState((prevState) => ({
                            ...prevState,
                            message: { error: Constant.DEFAULT_EMPTY_STRING, success: Constant.SUCCESS_ACTIONS },
                            btnDisabled: false,
                            isDataSubmitting: false
                        }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'UserActions',
                            'MSApprovals.UserActions.updateActionDetails.Success',
                            TrackingEventId.PostActionDetailsSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: { 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId), 
                            success: Constant.DEFAULT_EMPTY_STRING 
                        },
                        btnDisabled: false,
                        isDataSubmitting: false
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'UserActions',
                        'MSApprovals.UserActions.updateActionDetails.Failure',
                        TrackingEventId.PostActionDetailsFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            <h3>{HeaderConstants.USER_ACTIONS}</h3>
            <br />
            {state.message.error !== Constant.DEFAULT_EMPTY_STRING ?
                <MessageBar
                    data-testid="errorMessage"
                    messageBarType={MessageBarType.error}
                    isMultiline={false}> <b>{state.message.error}</b>
                </MessageBar> :
                <React.Fragment>
                    {state.isDataSubmitting && <React.Fragment>
                        <b>{Constant.REQUEST_PROCESSING}</b>
                        <ProgressIndicator barHeight={3} /></React.Fragment>}
                    {state.message.success !== Constant.DEFAULT_EMPTY_STRING &&
                        <MessageBar
                            data-testid="successMessage"
                            messageBarType={MessageBarType.success}
                            isMultiline={false}> <b>{state.message.success}</b>
                        </MessageBar>}
                </React.Fragment>
            }
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.SELECT_TENANT_CONFIGURE_ACTIONS}</Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className="sp-dropdown sp-dropdown-css"
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.tenantList}
                                loading={state.tenantList.length > 0 ? false : true}
                                onChange={(value: any) => handleTenantDropdownChange(value)}
                                searchable={false}
                                values={[...state.tenant]}
                            />
                        </td>
                    </tr>
                    {state.tenant != Constant.DEFAULT_EMPTY_STRING &&
                        <tr>
                            <td className="sp-td"><Label>{format(LabelConstants.CONFIGURE_ACTIONS, state.tenant)} </Label></td>
                        </tr>}
                </tbody>
            </table>
            {state.tenant != Constant.DEFAULT_EMPTY_STRING && <React.Fragment>
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr className="sp-tabs-tr">
                            <th>{TableHeaderConstants.ENABLED_ACTIONS}</th>
                            <th>{TableHeaderConstants.ACTION_NAMES}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.actions.length > 0 && state.actions.map((item: IUserActions, index: number) => {
                            return <tr className="sp-tabs-tr" key={item.rowKey}>
                                <td className="sp-tabs-td">
                                    <Checkbox
                                        checked={state.actions[index].IsEnabled}
                                        onChange={(e: any, value: boolean) => handleCheckboxChange(item, value, index)}
                                        ariaLabel={item.Name}
                                        name={item.Name}
                                    />
                                </td>
                                <td className="sp-tabs-td">{item.Name}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <br />
                <PrimaryButton
                    text={Constant.SAVE}
                    onClick={onSubmit}
                    className="sp-btn"
                    disabled={state.btnDisabled}
                />
            </React.Fragment>
            }
        </div>
        : <div>You are unauthorized to access this page</div>
    )
}

export default UserActions;