import { FontIcon, Spinner } from '@fluentui/react';
import { Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import { appContext, multiStepContext } from '../../../App';
import { MultiStepContainer, OperationDetailsContainer, TenantInfoDetailsTableContainer } from '../../Styles/Style';
import { convertBase64ToFile, convertFileToBase64, generateGuid } from '../../Utils/Common';
import { Constant } from '../Constants';
import { getTemplates } from '../Services';
import DocumentPreview from './FilePreview';
import { format } from 'react-string-format';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../Helpers/telemetryHelpers';

function TenantTemplates({ admin, updateFormData }: any): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const { formData, setFormData } = React.useContext(multiStepContext);
    const [state, setState] = React.useState({
        isLoading: false,
        isPreview: false,
        documentToPreview: Constant.DEFAULT_EMPTY_STRING
    })
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    let templatesArray: any[] = [];
    var existingTemplates: any[] = [];

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING && admin) {
            existingTemplates = formData.Templates;
            if (existingTemplates.length === 0) {
                setState((prevState) => ({ ...prevState, isLoading: true }));

                const guid = generateGuid();

                getTemplates(context.environment, formData.RequestType, formData.RowKey, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                    .then((response) => {
                        if (response.status === Constant.STATUS_CODE_OK) {
                            let adaptiveArray: any[] = [];
                            let emailArray: any[] = [];
                            let sampleArray: any[] = [];
                            let tenantImageArray: any[] = [];

                            if (response.data.adaptiveTemplates.length > 0) {
                                response.data.adaptiveTemplates.map((item: any) => {
                                    adaptiveArray.push({ name: item.FileName, base64: item.FileBase64 });
                                })
                                existingTemplates.push({ AdaptiveTemplate: adaptiveArray });
                            }

                            if (response.data.emailTemplates.length > 0) {
                                response.data.emailTemplates.map((item: any) => {
                                    emailArray.push({ name: item.FileName, base64: item.FileBase64 })
                                })
                                existingTemplates.push({ EmailTemplate: emailArray });
                            }

                            if (response.data.samplePayload.length > 0) {
                                response.data.samplePayload.map((item: any) => {
                                    sampleArray.push({ name: item.FileName, base64: item.FileBase64 })
                                })
                                existingTemplates.push({ SamplePayload: sampleArray });
                            }

                            if (response.data.tenantIcon.length > 0) {
                                response.data.tenantIcon.map((item: any) => {
                                    tenantImageArray.push({ name: item.FileName, base64: item.FileBase64 })
                                })
                                existingTemplates.push({ TenantImage: tenantImageArray });
                            }
                            convertBase64ToFile(existingTemplates);
                            setFormData({ ...formData, Templates: existingTemplates });
                            setState((prevState) => ({ ...prevState, isLoading: false }));

                            trackBusinessProcessEvent(
                                authClient,
                                telemetryClient,
                                'Templates',
                                'MSApprovals.Templates.getTemplates.Success',
                                TrackingEventId.GetTemplatesSuccess,
                                { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                            );
                        }
                    })
                    .catch((error) => {
                        setState((prevState) => ({ ...prevState, isLoading: false }));

                        const errorMessage = error.response ?? error;
                        trackException(
                            authClient,
                            telemetryClient,
                            'SubmitForm',
                            'MSApprovals.Templates.getTemplates.Failure',
                            TrackingEventId.GetTemplatesFailure,
                            errorMessage,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    })
            }
        }
    }, [formData.TenantName, context.environment])



    const fileUploadHandler = (template: string) => (event: any) => {
        let isFileSupported: string[] = [];
        templatesArray = formData.Templates;
        var selectedFiles = Array.from(event.target.files);
        selectedFiles.map((file: any) => {
            var fileExtension = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
            switch (fileExtension) {
                case "json": {
                    if (template === Constant.ADAPTIVE_TEMPLATE || template === Constant.SAMPLE_PAYLOAD) {
                        isFileSupported.push("true");
                    }
                    else {
                        isFileSupported.push("false");
                    }
                    break;
                }
                case "html": {
                    if (template === Constant.ADAPTIVE_TEMPLATE || template === Constant.EMAIL_TEMPLATE) {
                        isFileSupported.push("true");
                    }
                    else {
                        isFileSupported.push("false");
                    }
                    break;
                }
                case "jpg":
                case "jpeg":
                case "png":
                case "gif": {
                    if (template === Constant.TENANT_IMAGE) {
                        isFileSupported.push("true");
                        var fileName = file.name.substr(0, file.name.lastIndexOf('.'));
                        formData.TenantImage.FileName = fileName;
                        formData.TenantImage.FileType = fileExtension;
                    }
                    else {
                        isFileSupported.push("false");
                    }
                    break;
                }
                default: {
                    isFileSupported.push("false");
                    break;
                }
            }
        })


        if (isFileSupported.includes("false")) {
            switch (template) {
                case Constant.ADAPTIVE_TEMPLATE: {
                    alert("Unsupported file chosen. Please select either json or html file to upload.");
                    event.target.value = null;
                    break;
                }
                case Constant.EMAIL_TEMPLATE: {
                    alert("Unsupported file chosen. Please select only html file to upload.");
                    event.target.value = null;
                    break;
                }
                case Constant.SAMPLE_PAYLOAD: {
                    alert("Unsupported file chosen. Please select only json file to upload.");
                    event.target.value = null;
                    break;
                }
                case Constant.TENANT_IMAGE: {
                    alert("Unsupported file chosen. Please select only jpeg, jpg, png or gif file to upload.");
                    event.target.value = null;
                    break;
                }
            }

        }
        else {
            var isTemplateExists = templatesArray.filter((item: any) => {
                return item.hasOwnProperty(template);
            })

            if (isTemplateExists.length > 0) {
                selectedFiles.map((files: any) => {
                    var isFileExists = isTemplateExists[0][template].map((item: any) => {
                        return item.name
                    })

                    if (isFileExists.includes(files.name)) {
                        alert("One of the selected file already exists. Please select another file!!! ")
                        event.target.value = null;
                    }
                    else {
                        (template === Constant.TENANT_IMAGE || template === Constant.SAMPLE_PAYLOAD) ?
                            isTemplateExists[0][template][0] = files :
                            isTemplateExists[0][template].push(files);
                    }
                })
            }
            else {
                templatesArray.push({ [template]: selectedFiles });
            }
            setFormData({ ...formData, Templates: templatesArray })
        }
    }

    const handleDelete = (template: any, fileName: any) => {
        formData.Templates.map((item: any) => {
            if (item[template] !== undefined) {
                let data = item[template].filter((x: any) => x.name !== fileName);
                item[template] = data;
            }
        })
        setFormData({ ...formData, Templates: formData.Templates })
    }

    const handleDocumentPreview = async (attachment: any) => {
        let type = Object.prototype.toString.call(attachment).slice(8, -1);
        let base64: string = Constant.DEFAULT_EMPTY_STRING;

        if (type === "Object") {
            base64 = attachment.base64;
        }
        else if (type === "File") {
            const convertedString = await convertFileToBase64(attachment);
            base64 = convertedString.split(',')[1];
        }
        setState((prevState) => ({ ...prevState, isPreview: true, documentToPreview: base64 }))
        togglePopup();
    }

    React.useEffect(() => {
        updateFormData(formData);
    }, [formData]);


    const templateMapping = (objectKey: string, templateName: string, attachment: any) => {
        return (
            <tr>
                <td>{objectKey}</td>
                <td>{templateName}</td>
                <td>
                    <span>
                        <FontIcon
                            iconName='Preview'
                            title="Preview file"
                            className={OperationDetailsContainer.gridIcons}
                            role="button"
                            onClick={() => { handleDocumentPreview(attachment) }} />
                    </span>
                    <span>
                        <FontIcon iconName='Delete' title="Delete file" className={OperationDetailsContainer.gridIcons} role="button" onClick={() => { handleDelete(objectKey, templateName) }} />
                    </span>
                </td>
            </tr>
        )
    }

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen)
    }

    return (
        <div data-testid="templatesComponent" className={MultiStepContainer.divScroll}>
            <Table>
                <tbody>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label>Upload Adaptive Templates:  </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <input
                                type="file"
                                aria-label="Upload Adaptive Template"
                                multiple
                                accept=".json, .html"
                                onChange={fileUploadHandler(Constant.ADAPTIVE_TEMPLATE)}
                                disabled={state.isLoading} />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label>Upload Email Templates:  </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <input
                                type="file"
                                aria-label="Upload Email Template"
                                multiple
                                accept=".html"
                                onChange={fileUploadHandler(Constant.EMAIL_TEMPLATE)}
                                disabled={state.isLoading} />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label>Upload Sample Payload:  </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <input
                                type="file"
                                aria-label="Upload Sample Payload"
                                accept=".json"
                                onChange={fileUploadHandler(Constant.SAMPLE_PAYLOAD)}
                                disabled={state.isLoading} />
                        </td>
                    </tr>
                    <tr>
                        <td className={TenantInfoDetailsTableContainer.labelContainer}>
                            <Label>Upload Tenant Image: </Label>
                        </td>
                        <td className={TenantInfoDetailsTableContainer.inputFieldContainer}>
                            <input
                                type="file"
                                aria-label="Upload Tenant Image File"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={fileUploadHandler(Constant.TENANT_IMAGE)}
                                disabled={state.isLoading} />
                        </td>
                    </tr>
                </tbody>
            </Table>
            <br />
            {state.isLoading && <Spinner label={format(Constant.LOAD, Constant.TEMPLATE)} />}
            {(formData.Templates.length > 0 && !state.isLoading) &&
                <Table bordered striped hover responsive>
                    <React.Fragment>
                        <thead>
                            <tr className="sp-tabs-tr">
                                <th>Template</th>
                                <th>Uploaded Files</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formData.Templates.map((templateItem: any) => {
                                    var objectKey = Object.keys(templateItem)
                                    switch (objectKey[0]) {
                                        case Constant.ADAPTIVE_TEMPLATE: return (
                                            templateItem.AdaptiveTemplate.map((item: any) => {
                                                return templateMapping(objectKey[0], item.name, item)
                                            })
                                        )
                                        case Constant.EMAIL_TEMPLATE: return (
                                            templateItem.EmailTemplate.map((item: any) => {
                                                return templateMapping(objectKey[0], item.name, item)
                                            })
                                        )
                                        case Constant.SAMPLE_PAYLOAD: return (
                                            templateItem.SamplePayload.map((item: any) => {
                                                return templateMapping(objectKey[0], item.name, item)
                                            })
                                        )
                                        case Constant.TENANT_IMAGE: return (
                                            templateItem.TenantImage.map((item: any) => {
                                                return templateMapping(objectKey[0], item.name, item)
                                            })
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </React.Fragment>
                </Table>}
            {state.isPreview && <DocumentPreview isOpen={isPopupOpen} isClose={togglePopup} documentToPreview={state.documentToPreview} />}
        </div>
    )
}

export default TenantTemplates;