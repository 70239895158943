import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { ChoiceGroup, IChoiceGroupOption, Label, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, TextField } from '@fluentui/react';
import * as React from 'react';
import Select from 'react-dropdown-select';
import { format } from 'react-string-format';
import { appContext } from '../../../App';
import { Constant, HeaderConstants, LabelConstants } from '../../Shared/Constants';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';
import { getTenantsInfo, postOutOfSyncRequests } from '../../Shared/Services';
import { divScrollContainer, DropDownStyle, ReadioButtonContainer } from '../../Styles/Style';
import { convertDictionaryToList, generateGuid } from '../../Utils/Common';

function OutOfSyncRequests(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [state, setState] = React.useState({
        tenantList: [],
        tenantId: Constant.DEFAULT_EMPTY_STRING,
        isRadioCheckedValue: Constant.DEFAULT_EMPTY_STRING,
        reqNumber: Constant.DEFAULT_EMPTY_STRING,
        approverAlias: Constant.DEFAULT_EMPTY_STRING,
        selectedFile: Constant.DEFAULT_EMPTY_STRING,
        message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] },
        btnDisabled: false,
        isProcessing: false
    });

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {

            const guid = generateGuid();

            getTenantsInfo(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status == Constant.STATUS_CODE_OK) {
                        const tenantsDetails = response.data.map((item: { rowKey: string; appName: string }) => {
                            return { value: item.rowKey, label: item.appName }
                        })
                        setState((prevState) => ({ ...prevState, tenantList: tenantsDetails }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'OoSRequests',
                            'MSApprovals.OoSRequests.getTenantsInfo.Success',
                            TrackingEventId.GetTenantInfoSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: [format(Constant.EXCEPTION_MESSAGE, guid.msgId)] 
                        }
                    }));

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'OoSRequests',
                        'MSApprovals.OoSRequests.getTenantsInfo.Failure',
                        TrackingEventId.GetTenantInfoFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, [context.environment])

    const handleTenantDropdownChange = (selectedOption: any[]) => {
        setState((prevState) => ({
            ...prevState,
            message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] },
            tenantId: selectedOption[0].value
        }))
    }

    const handleTypeChange = (ev: any, option: IChoiceGroupOption): void => {
        if (option.key === 'File') {
            setState((prevState) => ({
                ...prevState,
                isRadioCheckedValue: option.text,
                reqNumber: Constant.DEFAULT_EMPTY_STRING,
                approverAlias: Constant.DEFAULT_EMPTY_STRING,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] }
            }))
        }
        else {
            setState((prevState) => ({
                ...prevState,
                isRadioCheckedValue: option.text,
                selectedFile: Constant.DEFAULT_EMPTY_STRING,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] }
            }))
        }
    }

    const handleTextValueChange = (textField: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        setState((prevState) => ({
            ...prevState,
            [textField]: value,
            message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] }
        }))
    }

    const fileUploadHandler = (event: any) => {
        var selectedFiles = Array.from(event.target.files);

        selectedFiles.map((file: any) => {
            var fileExtension = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
            if (fileExtension !== 'txt') {
                setState((prevState) => ({
                    ...prevState,
                    message: {
                        success: Constant.DEFAULT_EMPTY_STRING,
                        error: [format(Constant.INVALID_FILE_SINGLE, 'text')]
                    }
                }))
                event.target.value = null
            }
            else {
                setState((prevState) => ({
                    ...prevState,
                    selectedFile: file,
                    message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] }
                }))
            }
        })
    }

    const onSubmit = () => {
        if (state.tenantId !== Constant.DEFAULT_EMPTY_STRING &&
            state.isRadioCheckedValue !== Constant.DEFAULT_EMPTY_STRING &&
            (state.isRadioCheckedValue === Constant.REQUEST_NUMBER ?
                (state.reqNumber !== Constant.DEFAULT_EMPTY_STRING &&
                    state.approverAlias !== Constant.DEFAULT_EMPTY_STRING) :
                state.selectedFile != Constant.DEFAULT_EMPTY_STRING)
        ) {
            let formDataRequest = new FormData();
            var requestBody = {
                documentNumber: state.reqNumber,
                tenantID: state.tenantId,
                approver: state.approverAlias
            }

            setState((prevState) => ({
                ...prevState,
                btnDisabled: true,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: [] },
                isProcessing: true
            }))

            formDataRequest.append("requestBody", JSON.stringify(requestBody));
            formDataRequest.append("uploadedFile", state.selectedFile);

            const guid = generateGuid();

            postOutOfSyncRequests(context.environment, formDataRequest, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        if (Object.getOwnPropertyNames(response.data).length >= 1) {
                            var errorList = convertDictionaryToList(response.data)
                            setState((prevState) => ({
                                ...prevState,
                                btnDisabled: false,
                                message: { success: Constant.DEFAULT_EMPTY_STRING, error: errorList },
                                isProcessing: false
                            }))
                        }
                        else {
                            setState((prevState) => ({
                                ...prevState,
                                btnDisabled: false,
                                message: { success: Constant.OOS_SUCCESS, error: [] },
                                isProcessing: false
                            }))
                        }

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'OoSRequests',
                            'MSApprovals.OoSRequests.postOutOfSyncRequests.Success',
                            TrackingEventId.PostOutOfSyncRequestsSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        btnDisabled: false,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: [format(Constant.EXCEPTION_MESSAGE, guid.msgId)] 
                        },
                        isProcessing: false
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'OoSRequests',
                        'MSApprovals.OoSRequests.postOutOfSyncRequests.Failure',
                        TrackingEventId.PostOutOfSyncRequestsFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })

        } else {
            setState((prevState) => ({
                ...prevState,
                btnDisabled: false,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: [Constant.EMPTY_INPUT_FIELDS] },
                isProcessing: false
            }))
        }
    }

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            <h3>{HeaderConstants.REQUEST_OOS}</h3>
            <br />
            {state.isProcessing &&
                <React.Fragment>
                    <b>{Constant.REQUEST_PROCESSING}</b>
                    <ProgressIndicator barHeight={3} />
                </React.Fragment>}
            {state.message.error.length > 0 ?
                <React.Fragment>
                    {state.message.error.map((err: any, index: number) => {
                        return (<MessageBar
                            key={index + err}
                            data-testid="errorMessage"
                            messageBarType={MessageBarType.error}
                            isMultiline={false}> <b>{err}</b>
                        </MessageBar>)
                    })}
                </React.Fragment> :
                <React.Fragment>
                    {state.message.success !== Constant.DEFAULT_EMPTY_STRING &&
                        <MessageBar
                            data-testid="successMessage"
                            messageBarType={MessageBarType.success}
                            isMultiline={false}> <b>{state.message.success}</b>
                        </MessageBar>}
                </React.Fragment>}
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.SELECT_TENANT}</Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className={DropDownStyle.generic}
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.tenantList}
                                loading={state.tenantList.length > 0 ? false : true}
                                onChange={(value: any) => handleTenantDropdownChange(value)}
                                searchable={false}
                                values={[...state.tenantId]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.TYPE}</Label></td>
                        <td className="sp-td sp-width">
                            <div className={ReadioButtonContainer.radioStyle}>
                                <ChoiceGroup
                                    options={Constant.OOS_SELECT_TYPE}
                                    onChange={handleTypeChange}
                                    styles={{ flexContainer: { display: "flex" } }}
                                />
                            </div>
                        </td>
                    </tr>
                    {state.isRadioCheckedValue !== Constant.DEFAULT_EMPTY_STRING &&
                        <React.Fragment>
                            {state.isRadioCheckedValue === Constant.REQUEST_NUMBER ?
                                <tr>
                                    <td className="sp-td"><Label required>{LabelConstants.DOC_NUMBER}</Label></td>
                                    <td className="sp-td sp-width">
                                        <TextField
                                            value={state.reqNumber}
                                            onChange={handleTextValueChange("reqNumber")}
                                            ariaLabel="Request number text field"
                                            className="sp-textbox-admin"
                                            title={Constant.COMMA_SEPERATED_REQ_NUMBERS} />
                                    </td>
                                    <td className="sp-td"><Label required>{LabelConstants.APPROVER_ALIAS}</Label></td>
                                    <td className="sp-td sp-width">
                                        <TextField
                                            value={state.approverAlias}
                                            onChange={handleTextValueChange("approverAlias")}
                                            ariaLabel="Approver alias text field"
                                            className="sp-textbox-admin" />
                                    </td>
                                </tr> :
                                <tr>
                                    <td className="sp-td"><Label required>{LabelConstants.SELECT_FILE}</Label></td>
                                    <td className="sp-td sp-width">
                                        <input
                                            type="file"
                                            accept=".txt"
                                            onChange={fileUploadHandler}
                                        />
                                    </td>
                                </tr>}
                        </React.Fragment>
                    }
                    <tr>
                        <td className="sp-td">
                            <PrimaryButton text={Constant.MARK_OoS} onClick={onSubmit} className="sp-btn" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        : <div>You are unauthorized to access this page</div>
    )
}

export default OutOfSyncRequests;