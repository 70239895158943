import { mergeStyles, mergeStyleSets } from "@fluentui/react";

export const MainRoute = styled.div`
    padding: 35px 5px 35px 60px;
`;

export const AlertCheckBoxStyle = styled.div`
    display: flex
`;

export const DropDownStyle = mergeStyleSets({
    requestHistory: {
        minWidth: '320px',
        backgroundColor: 'white',
        border: '1px solid rgb(100,100,100) !important',
        width: '350px !important',
        marginLeft: '7px'
    },
    generic: {
        minWidth: '320px',
        backgroundColor: 'white',
        border: '1px solid rgb(100,100,100) !important',
        width: '250px !important',
    }
})

export const CheckboxStyle = mergeStyleSets({
    alertCheckboxIsScheduled: {
        margin: 'auto'
    },
    alertCheckboxIsActive: {
        width: "100%", 
        marginLeft: "50px"
    },
    alertCheckboxIsActiveLabel: {
        width: "100%"
    }
})


export const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 15px 0',
        width: '100%'
    },
});

export const FormContainer = styled.div`
    max-width: 365px;
    min-width: 200px;
`;

export const divScrollContainer = mergeStyleSets({
    divScroll: {
        overflow: "auto",
        height: "calc(100vh - 150px)"
    }
});

export const ChoiceGroupContainer = mergeStyleSets({
    choiceGroup: {
        display: 'flex',
        flexDirection : 'row'
    }
});

export const MultiStepContainer = mergeStyleSets({
    contentStyle: {
        marginTop: '65px',
        backgroundColor: 'white',
        paddingRight: '5px !important'
    },
    labelStyle: {
        fontWeight: '700',
        fontSize: '13px'
    },
    wrapperStyle: {
        transformStyle: 'preserve-3d'
    },
    progressBarStyle: {
        transform: 'translateZ(0px)',
        padding: '8px'
    },
    divScroll: {
        overflow: 'auto',
        height: '50vh'
    }
})

export const iconClass = mergeStyleSets({
    otherIcons: {
        fontSize: 20,
        margin: '0 60px'
    },
    reprocessIcon: {
        fontSize: 20,
        margin: '0 10px',
    },
    previewCloseIcon: {
        position: 'relative',
        display: 'flex !important',
        flexDirection: 'row-reverse'
    },
    alertIcons: {
        fontSize: 16,
        margin: '0 10px'
    }
});

export const divIconClass = mergeStyles({
    textAlign: "center",
    width: "100%"
  });

export const spText = mergeStyles({
    padding: "0 8px"
})

export const TenantInfoDetailsTableContainer = mergeStyleSets({
    dropdownContainer: {
        width: '200px'
    },
    checkboxContainer: {
        padding: '10px',
        borderTop: "none !important",
    },
    trContainer: {
        margin: '10px'
    },
    tdContainer: {
        padding: '10px',
    },
    tdContainerWithSmallWidth: {
        whiteSpace: "pre-wrap"
    },
    labelContainer: {
        width: "310px",
        paddingLeft: "20px !important",
        borderTop: "none !important"
    },
    inputFieldContainer: {
        display: "table-row",
        borderTop: "none !important"
    }
});

export const NotificationsContainer = mergeStyleSets({
    dropdownContainer: {
        width: '166px'
    }
})

export const OperationDetailsContainer = mergeStyleSets({
    gridIcons: {
        marginLeft: '20px',
        fontSize: '18px'
    }
})

export const EngineeringContainer = mergeStyleSets({
    labelContainer: {
        width: "250px",
        paddingLeft: "20px !important",
        borderTop: "none !important"
    },
    inputFieldContainer: {
        width: "250px",
        borderTop: "none !important",
    },
    checkboxContainer: {
        borderTop: "none !important",
        verticalAlign: "baseline !important"
    },
    dropdownContainer: {
        borderTop: "none !important",
        width: "250px"
    }
})

export const SubmitMessage = styled.div`
        background-color: white;
        margin-top: 20px;        
        padding: 40px;
        overflow: auto;
        height: ${props => props.id === "0" ? "50vh" : "25vh" };
`;

export const SubmitMessageContainer = mergeStyleSets({
    textStyle: {
        lineHeight: "1.5em",
        overflowWrap: "break-word"
    },
    btnStyle: {
        borderRadius: "5px",
        display: "flex",
        margin: "auto"
    }
})

export const ReadioButtonContainer = mergeStyleSets({
    radioStyle: {
        display: 'flex'
    }
});

export const InlinePreview = mergeStyles({
    height: "50vh",
    width: "100%",
    border: 'none'
});