import * as React from 'react';
import { Constant } from '../../Shared/Constants';
import TenantApprovals from '../../Shared/ConfigureTenant/TenantApprovals';


function ViewApproveTenant(): React.ReactElement {

    return (
        <TenantApprovals
            tenantType={Constant.PENDING}
            requestType={Constant.APPROVE}
            admin={true} />
    )
}

export default ViewApproveTenant;