import { IAuthClient } from '@employee-experience/common';
import axios from 'axios';
import { IFlightFeatureDetails } from './Interfaces/IFlightUsers';
import { IUserDelegationList } from './Interfaces/IUserDelegation';

export function fetchAuthToken(authClient: IAuthClient) {
    return authClient.acquireToken(__RESOURCE_URL__)
}

export function fetchEnvironmentList(authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Common/GetEnvironment",
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function fetchFormData(RequestType: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/SchemaDefination/" + env + "?form=" + RequestType,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getTopicData(env: string, authToken: string, userAlias:string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/ServiceBusMonitoring/GetTopic/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getSubscriptionData(TopicName: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/ServiceBusMonitoring/GetSubscription/" + env + "?topicName=" + TopicName,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getSBMonitoringData(TopicName: string, SubscriptionName: string, MessageType: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/ServiceBusMonitoring/" + env + "?subscriptionName=" + SubscriptionName + "&topicName=" + TopicName + "&messageType=" + MessageType,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getRequestStatusData(RequestNumber: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/RequestStatus/" + env + "?RequestNumber=" + RequestNumber,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getUserDelegation(env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/UserDelegation/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function deleteUserDelegation(id: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.delete(__API_BASE_URL__ + __API_URL_ROOT__ + "/UserDelegation/" + env + "?id=" + id,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function postUserDelegation(UserDelegationData: IUserDelegationList, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/UserDelegation/" + env,
        {
            data: UserDelegationData
        },
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function getTenantsInfo(env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Common/GetTenant/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function updateActionDetails(tenantDetails: object, tenantId: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/TenantInfo/UpdateActionDetail/" + env + "?tenantID=" + tenantId,
        {
            tenantDetails
        },
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        })
}

export function getFlightFeatures(env: string, authToken: string, userAlias: string, messageId: string, xcv:string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/SubscribeFeatures/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function postFlightFeatures(FlightFeatureDetails: IFlightFeatureDetails, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/SubscribeFeatures/" + env,
        {
            data: FlightFeatureDetails,
        },
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function SearchRequestHistory(FormData: {}, RequestType: string, aiScope: string, env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/RequestHistory/SearchRequestHistory/" + env + "?requestType=" + RequestType + "&scope=" + aiScope,
        JSON.stringify(JSON.stringify(FormData)),
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function fetchAIScope(env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/SchemaDefination/GetScope/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function postOutOfSyncRequests(env: string, formDataRequest: object, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/MarkRequestOutOfSync/" + env, formDataRequest,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function OnboardTenant(env: string, requestBody: object, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/TenantOnBoarding/" + env,
        requestBody,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function ApprovePendingRequest(env: string, requestBody: object, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/TenantOnBoarding/" + env,
        requestBody,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function getTenantsFromApprovals(env: string, tenantType: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/TenantOnBoarding/" + env + "?tenantType=" + tenantType,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function getTemplates(env: string, operation: string, tenantId: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Templates/" + env + "?operation=" + operation + "&tenantId=" + tenantId,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function getPayloadHistory(env: string, requestBody: {}, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/PayloadReProcessing/" + env + "?requestBody=" + JSON.stringify(requestBody),
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function ReprocessPayload(env: string, requestBody: {}, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/PayloadReProcessing/" + env,
        requestBody,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId": messageId,
                "xcv":xcv
            }
        }
    )
}

export function getAlertNotificationList(env: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/DownTimeAlertNotification/" + env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function deleteNotification(env: string, rowKey: string, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.delete(__API_BASE_URL__ + __API_URL_ROOT__ + "/DownTimeAlertNotification/" + env + "?rowID=" + rowKey,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function saveNotification(env: string, requestBody: {}, authToken: string, userAlias: string, messageId: string, xcv: string) {
    return axios.post(__API_BASE_URL__ + __API_URL_ROOT__ + "/DownTimeAlertNotification/" + env,
        requestBody,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias,
                "messageId":messageId,
                "xcv":xcv
            }
        }
    )
}

export function checkUserRole(env: string, authToken: string, userAlias: string) {
    return axios.get(__API_BASE_URL__ + __API_URL_ROOT__ + "/Common/CheckUserRole/"+ env,
        {
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "userAlias": userAlias
            }
        }
    )
}
