import { format } from "react-string-format";
import { Constant } from "../Shared/Constants";
import {v4 as uuidv4} from 'uuid';

export const convertFileToBase64 = (file: any): any => {
    return new Promise((resolve: any, reject: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            resolve(reader.result)
        }

        reader.onerror = (error) => {
            console.log('Error: ', error);
            reject(error);
        };
    })
}

export const convertBase64ToFile = (existingFileArray: any[]) => {
    let contentType = Constant.DEFAULT_EMPTY_STRING;

    existingFileArray.map((templateItem: any) => {
        var objectKey = Object.keys(templateItem);
        switch(objectKey[0]) {
            case Constant.ADAPTIVE_TEMPLATE: {
                templateItem.AdaptiveTemplate.map((file: any, index: number) => {
                    var fileExtention = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
                    if(fileExtention === "json") {
                        contentType = "application/json";
                    }
                    else if(fileExtention === "html") {
                        contentType = "text/html";
                    }
                    const newFile = fileConversion(file, contentType);
                    templateItem.AdaptiveTemplate[index] = newFile
                })
                break;
            }
            case Constant.EMAIL_TEMPLATE: {
                templateItem.EmailTemplate.map((file: any, index: number) => {
                    var fileExtention = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
                    if(fileExtention === "html") {
                        contentType = "text/html";
                    }
                    const newFile = fileConversion(file, contentType);
                    templateItem.EmailTemplate[index] = newFile;
                })
                break;
            }
            case Constant.SAMPLE_PAYLOAD: {
                templateItem.SamplePayload.map((file: any, index: number) => {
                    var fileExtention = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
                    if(fileExtention === "json") {
                        contentType = "application/json";
                    }
                    const newFile = fileConversion(file, contentType);
                    templateItem.SamplePayload[index] = newFile;
                })
                break;
            }
            case Constant.TENANT_IMAGE: {
                templateItem.TenantImage.map((file: any, index: number) => {
                    var fileExtention = file.name.toLowerCase().split('.')[file.name.toLowerCase().split('.').length - 1]
                    if(fileExtention === "jpg" || fileExtention === "png" || fileExtention === "gif") {
                        contentType = `application/${fileExtention}`;
                    }
                    const newFile = fileConversion(file, contentType);
                    templateItem.TenantImage[index] = newFile;
                })
                break;
            }
        }
    });

    return existingFileArray;
}

export const fileConversion = (file: any, ContentType: string) => {
    const contentType = ContentType || Constant.DEFAULT_EMPTY_STRING;
    const base64String = file.base64;
    const fileName = file.name;
    var sliceSize = 512;
    var byteCharacters = atob(base64String);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var convertedFile = new File(byteArrays, fileName, { type: contentType });
    return convertedFile;
}

export const convertStringToPascalCase = (str: string) => {
    return str.replace(/\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

export const validationOnLastStep = (element: any, type: string) => {
    if (element.hasChildNodes()) {
        var innerElement = document.getElementsByClassName('_2kL0S');
        if (type === Constant.ERROR) {
            for (let i = 0; i < innerElement.length; i++) {
                (innerElement[i] as HTMLElement).style.backgroundColor = '#bb392d';
                (innerElement[i] as HTMLElement).style.animation = '_1ujce 350ms ease-in-out forwards';
                (innerElement[i] as HTMLElement).style.color = 'white';
                (innerElement[i] as HTMLElement).textContent = "!"
            }
        }
        else if(type === Constant.SUCCESS) {
            for (let i = 0; i < innerElement.length; i++) {
                (innerElement[i] as HTMLElement).style.backgroundColor = '#4db193';
                (innerElement[i] as HTMLElement).style.animation = '_3SDnc 400ms ease-in-out forwards';
                (innerElement[i] as HTMLElement).style.color = 'white';
                (innerElement[i] as HTMLElement).textContent = ""
                var svgElement = createSvgForMultiStep();
                (innerElement[i] as HTMLElement).appendChild(svgElement);
            }
        }
    }
}

export function createSvgForMultiStep(): any {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", "1.5rem");
    svg.setAttribute("viewBox", "0 0 13 9");
    svg.setAttribute("fill", "none");
    
    var newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    newpath.setAttributeNS(null, "d", "M1 3.5L4.5 7.5L12 1");
    newpath.setAttributeNS(null, "stroke", "white");
    newpath.setAttributeNS(null, "stroke-width", "1.5");
    svg.appendChild(newpath);
    return svg;
}

export const convertDictionaryToList = (dictionary: any) => {
    var arr = [];

    for(var key in dictionary){
        if(dictionary.hasOwnProperty(key)) {
            var appendedKeyValue = format("{0}{1}", key, dictionary[key])
            arr.push(appendedKeyValue);
        }
    }
    return arr;
}

export const generateGuid = () => {
    let messageId = uuidv4();
    let xcvId = uuidv4();

    return {msgId: messageId, xcv: xcvId}
}