import * as React from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { MultiStepContainer } from '../../Styles/Style';
import TenantActionDetails from './ActionDetails';
import TenantNotification from './Notification';
import TenantOperationDetails from './OperationDetails';
import TenantTemplates from './Templates';
import TenantInfoDetails from './TenantInfoDetails';
import { appContext, multiStepContext } from '../../../App';
import ApprovalsEngineering from './ApprovalsEngineering';
import { Constant, defaultValuesOfMultiStepForm, RouteConstants } from '../Constants';
import { useHistory } from 'react-router-dom';
import { validationOnLastStep } from '../../Utils/Common';


function TenantInfo({ isAdmin, requestType, startFormIndex }: any): React.ReactElement {
    const { context } = React.useContext(appContext);
    const { formData, setFormData, setFinalData } = React.useContext(multiStepContext);
    let history = useHistory();
    var updatedFormData: any;

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING) {
            if (!isAdmin) {
                setFormData({
                    ...defaultValuesOfMultiStepForm,
                    OperationDetails: [{
                        operationtype: Constant.DEFAULT_EMPTY_STRING,
                        endpointdata: Constant.DEFAULT_EMPTY_STRING,
                        SupportsPagination: false,
                        _client: false,
                        IsCached: false,
                        SerializerType: 0,
                        IsLegacyResponse: false
                    }],
                    ActionDetails: [{
                        Name: Constant.DEFAULT_EMPTY_STRING,
                        Text: Constant.DEFAULT_EMPTY_STRING,
                        Code: Constant.DEFAULT_EMPTY_STRING,
                        Condition: Constant.DEFAULT_EMPTY_STRING,
                        IsCommentMandatory: false,
                        CommentLength: 255,
                        IsBulkAction: false,
                        IsInterimStateRequired: false,
                        IsEnabled: true,
                        comments: [
                            {
                                mappingKey: Constant.DEFAULT_EMPTY_STRING,
                                isMandatory: false,
                                mandatoryCondition: Constant.DEFAULT_EMPTY_STRING,
                                length: 255
                            }
                        ]
                    }],
                    Templates: Array<any>()
                });
            }
        }

    }, [formData.Key, context.environment])

    const updatedData = (newFormData: any) => {
        updatedFormData = newFormData;
    }

    function TenantInfoDetailsValidator() {
        if (updatedFormData.TenantName === Constant.DEFAULT_EMPTY_STRING ||
            // (isAdmin && updatedFormData.TenantId === Constant.DEFAULT_EMPTY_STRING) ||
            (isAdmin && updatedFormData.SubscriptionName === Constant.DEFAULT_EMPTY_STRING) ||
            updatedFormData.ActionSubmissionType === Constant.DEFAULT_EMPTY_STRING ||
            (updatedFormData.ActionSubmissionType === Constant.SUBMISSION_TYPE[1].text &&
                updatedFormData.APIRequestType === Constant.DEFAULT_EMPTY_STRING) ||
            (isAdmin && updatedFormData.RegisteredClients === Constant.DEFAULT_EMPTY_STRING) ||
            (isAdmin && updatedFormData.ActionableEmailFolderName === Constant.DEFAULT_EMPTY_STRING)) {
            return false;
        }
        return true;
    }

    function NotificationValidator() {
        if (updatedFormData.EnableOutlookActionableEmail === Constant.DEFAULT_EMPTY_STRING) {
            return false;
        }
        return true;
    }

    function OperationDetailsValidator() {
        if (updatedFormData.TenantBaseUrl === Constant.DEFAULT_EMPTY_STRING ||
            updatedFormData.ResourceUrl === Constant.DEFAULT_EMPTY_STRING ||
            updatedFormData.SummaryUrl === Constant.DEFAULT_EMPTY_STRING
        ) {
            return false;
        }
        return true;
    }

    function ApprovalsEngineeringValidator() {
        if (formData.BusinessProcessName === Constant.DEFAULT_EMPTY_STRING ||
            formData.ClassName === Constant.DEFAULT_EMPTY_STRING ||
            formData.ProcessSecondaryAction === Constant.DEFAULT_EMPTY_STRING ||
            formData.TemplateName === Constant.DEFAULT_EMPTY_STRING ||
            formData.ValidationClassName === Constant.DEFAULT_EMPTY_STRING
        ) {
            return false;
        }
        return true;
    }

    const SubmitTenantData = () => {
        let tenantData: any, operationDetails: any[];
        let actions: { Primary: [], Secondary?: [] };
        let isOperationDetailsEmpty = false;

        if (formData.ActionDetails.length <= 2) {
            actions = { Primary: formData.ActionDetails };
        }
        else {
            actions = {
                Primary: formData.ActionDetails.filter((item: any) => item.Name === Constant.APPROVE),
                Secondary: formData.ActionDetails.filter((item: any) => item.Name !== Constant.APPROVE)
            }
        }

        if (formData.OperationDetails.length == 1 &&
            formData.OperationDetails[0].operationtype === Constant.DEFAULT_EMPTY_STRING &&
            formData.OperationDetails[0].endpointdata === Constant.DEFAULT_EMPTY_STRING &&
            formData.OperationDetails[0].SupportsPagination === false &&
            formData.OperationDetails[0]._client === false &&
            formData.OperationDetails[0].IsCached === false &&
            formData.OperationDetails[0].SerializerType === 0 &&
            formData.OperationDetails[0].IsLegacyResponse === false) {
            operationDetails = [];
            isOperationDetailsEmpty = true
        }

        tenantData = {
            ActionSubmissionType: formData.ActionSubmissionType === Constant.SUBMISSION_TYPE[1].text ?
                (formData.APIRequestType === Constant.DEFAULT_EMPTY_STRING ? 1 : formData.APIRequestType) : 0,
            ActionableEmailFolderName: formData.ActionableEmailFolderName,
            ActionableNotificationTemplateKeys: formData.ActionableNotificationKeys,
            AdaptiveCardCacheClients: formData.AdaptiveCardCacheClients,
            AppName: formData.TenantName,
            AttachmentUrl: formData.AttachmentUrl,
            BackgroundApprovalRetryInterval: formData.ApprovalRetryInterval === Constant.DEFAULT_EMPTY_STRING ? 0 : parseInt(formData.ApprovalRetryInterval),
            BulkActionConcurrentCall: formData.BulkActionCalls === Constant.DEFAULT_EMPTY_STRING ? 0 : parseInt(formData.BulkActionCalls),
            BusinessProcessName: formData.BusinessProcessName,
            ButtonDisabledReason: formData.BtnDisabledReason,
            ClassName: formData.ClassName,
            ComplianceAcknowledgementText: formData.ComplianceAckText,
            DataModelMapping: formData.DataModelMapping,
            DocumentNumberPrefix: formData.DocNumberPrefix,
            EnableAdaptiveCardCaching: formData.EnableAdaptiveCardCaching,
            EnableModernAdaptiveUI: parseInt(formData.EnableModernAdaptiveUI),
            EnableUserDelegation: formData.EnableUserDelegation,
            HistoryLogging: formData.HistoryLoggingEnabled,
            IsAllCurrentApproversDisplayInHierarchy: formData.IsAllCurrentApproversDisplayInHierarchy,
            IsBackgroundProcessingEnabledUpfront: formData.IsBackgroundProcessingEnabled,
            IsControlsAndComplianceRequired: formData.ControlComplianceRequired,
            IsDayZeroActivityRunning: formData.IsDayZeroActivityRunning,
            IsDetailsEditable: formData.DetailsEditable,
            IsDigestEmailEnabled: formData.IsDigestEmailEnabled,
            IsExternalTenantActionDetails: formData.IsExternalTenantActionDetails,
            IsHistoryClickable: formData.IsHistoryClickable,
            IsOldHierarchyEnabled: formData.EnableOldHierarchy,
            IsPictorialLineItemsEnabled: formData.IsPictorialLineItemsEnabled,
            IsPullModelEnabled: formData.AllowPullModel,
            IsRaceConditionHandled: formData.IsRaceConditionHandled,
            IsSameApproverMultipleLevelSupported: formData.IsSameApproverMultiLevelSupport,
            LineItemFilterCategories: formData.LineItemFilterCategories,
            MaxTenantThreads: formData.MaxTenantThreads,
            MaxTenantThreadsForSecondary: formData.MaxThreadsForSecondaryAction,
            NotifyEmail: formData.EnableEmailNotification,
            NotifyEmailWithApprovalFunctionality: formData.EnableOutlookActionableEmail === "Yes" ? true : false,
            NotifyEmailWithMobileFriendlyActionCard: parseInt(formData.NotifyEmailWithMobileFriendlyActionCard),
            NotifyTeams: formData.EnableTeamsNotification,
            NotifyWatchDogEmailWithApprovalFunctionality: formData.AllowActionableReminderEmail,
            PartitionKey: formData.PartitionKey,
            ProcessAttachedSummary: formData.ProcessAttachedSummary,
            ProcessSecondaryAction: formData.ProcessSecondaryAction === Constant.DEFAULT_EMPTY_STRING ? 0 : parseInt(formData.ProcessSecondaryAction),
            RaceConditionSleepTimeInSecond: formData.RaceConditionSleepTime,
            RegisteredClients: formData.RegisteredClients,
            RequestType: isAdmin ? formData.RequestType : Constant.SUBMIT,
            ResourceUrl: formData.ResourceUrl,
            RowKey: formData.RowKey,
            ServiceTreeId: formData.ServiceTreeId,
            Subscription: formData.SubscriptionName,
            SummaryURL: formData.SummaryUrl,
            TemplateName: formData.TemplateName,
            TenantActionDetails: JSON.stringify(actions),
            TenantBaseUrl: formData.TenantBaseUrl,
            TenantDetailRetryCount: formData.TenantDetailRetryCount,
            TenantDetailUrl: formData.DetailUrl,
            TenantEnabled: formData.TenantEnabled,
            TenantImage: JSON.stringify(formData.TenantImage),
            TenantMessageProcessingEnabled: formData.EnableTenantMessageProcessing,
            TenantOperationDetails: JSON.stringify({ "DetailOpsList": isOperationDetailsEmpty ? operationDetails : formData.OperationDetails }),
            TenantType: isAdmin ? Constant.TENANT_TYPE[1].text : formData.TenantType,
            ToolName: formData.TenantName.replace(" ", ""),
            UseDocumentNumberForRowKey: formData.DocNumberForRowKey,
            ValidationClassName: formData.ValidationClassName
        }

        setFinalData({
            tenantData: tenantData,
            templates: formData.Templates
        });
    }

    const onFormSubmit = () => {
        if (isAdmin) {
            var onSubmitValidation = ApprovalsEngineeringValidator();
            var element = document.getElementsByTagName("li").item(document.getElementsByTagName("li").length - 1);

            if (onSubmitValidation) {
                validationOnLastStep(element, Constant.SUCCESS)
                SubmitTenantData();
                history.push(RouteConstants.ONSUBMIT);
            }
            else {
                validationOnLastStep(element, Constant.ERROR)
            }
        }
        else {
            SubmitTenantData();
            history.push(RouteConstants.ONSUBMIT);
        }
    }

    return (
        <React.Fragment>
            <StepProgressBar
                startingStep={startFormIndex}
                onSubmit={onFormSubmit}
                contentClass={MultiStepContainer.contentStyle}
                labelClass={MultiStepContainer.labelStyle}
                progressClass={MultiStepContainer.progressBarStyle}
                wrapperClass={MultiStepContainer.wrapperStyle}
                submitBtnName={requestType === Constant.EDIT ? Constant.UPDATE : requestType}
                steps={isAdmin ?
                    [
                        {
                            label: 'Application Details',
                            name: 'ApplicationDetails',
                            content: <TenantInfoDetails admin={isAdmin} updateFormData={updatedData} />,
                            validator: TenantInfoDetailsValidator
                        },
                        {
                            label: 'Notification',
                            name: 'Notification',
                            content: <TenantNotification admin={isAdmin} updateFormData={updatedData} />,
                            validator: NotificationValidator
                        },
                        {
                            label: 'Operation Details',
                            name: 'OperationDetails',
                            content: <TenantOperationDetails admin={isAdmin} updateFormData={updatedData} />,
                            validator: OperationDetailsValidator
                        },
                        {
                            label: 'Action Details',
                            name: 'ActionDetails',
                            content: <TenantActionDetails admin={isAdmin} updateFormData={updatedData} />
                        },
                        {
                            label: 'Templates',
                            name: 'Templates',
                            content: <TenantTemplates admin={isAdmin} updateFormData={updatedData} />
                        },
                        {
                            label: 'Approvals Engineering',
                            name: 'ApprovalsEngineering',
                            content: <ApprovalsEngineering />,
                            validator: ApprovalsEngineeringValidator
                        }
                    ] :
                    [
                        {
                            label: 'Application Details',
                            name: 'ApplicationDetails',
                            content: <TenantInfoDetails admin={isAdmin} updateFormData={updatedData} />,
                            validator: TenantInfoDetailsValidator
                        },
                        {
                            label: 'Notification',
                            name: 'Notification',
                            content: <TenantNotification admin={isAdmin} updateFormData={updatedData} />,
                            validator: NotificationValidator
                        },
                        {
                            label: 'Operation Details',
                            name: 'OperationDetails',
                            content: <TenantOperationDetails admin={isAdmin} updateFormData={updatedData} />,
                            validator: OperationDetailsValidator
                        },
                        {
                            label: 'Action Details',
                            name: 'ActionDetails',
                            content: <TenantActionDetails admin={isAdmin} updateFormData={updatedData} />
                        },
                        {
                            label: 'Templates',
                            name: 'Templates',
                            content: <TenantTemplates admin={isAdmin} updateFormData={updatedData} />
                        }
                    ]
                }
            />
        </React.Fragment>
    )
};

export default TenantInfo;