import * as React from 'react'
import { Link } from '@fluentui/react/lib/Link';
import { SubmitMessage, SubmitMessageContainer } from '../../Styles/Style';
import { PrimaryButton } from '@fluentui/react';
import { Constant, RouteConstants } from '../Constants';
import { useHistory } from 'react-router-dom';
import { format } from 'react-string-format';

function MessageOnSubmit(props: any): React.ReactElement {
    let history = useHistory();
    var state = props.location.state;

    const onBtnClick = () => {
        history.push(state.requestType !== Constant.APPROVE ?
            RouteConstants.ONBOARD_TENANT :
            RouteConstants.APPROVE_TENANT)
    }

    return (
        <SubmitMessage id={!state.isErrorOccurred ? "0" : "1"}>
            { !state.isErrorOccurred ?
                <React.Fragment>
                    {
                        state.requestType === Constant.SUBMIT &&
                        <React.Fragment>
                            <h4 className={SubmitMessageContainer.textStyle}>
                                Thank you for submitting request! Your request will be reviewed by MSApprovals team.
                                Once request will be approved, you will be notified via email from{' '}
                                <b>'MSApprovalsNotification@microsoft.com'</b>.<br /><br />

                                In a meantime we have created a test tenant for your request and you can start exploring
                                features of MSApprovals with your requests templates by creating request/s for
                                application '<b>{`Test ${state.tenantName}`}</b>' from our test harness application by clicking{' '}
                                <Link
                                    href="https://testharness.msapprovals.microsoft.com/"
                                    target="blank"
                                    underline>
                                    Test Harness
                                </Link>.
                            </h4>
                            <br /><br />
                            <PrimaryButton text={Constant.REDIRECT_TO_ONBOARDING_FORM} onClick={onBtnClick} className={SubmitMessageContainer.btnStyle} />
                        </React.Fragment>
                    }
                    {
                        state.requestType === Constant.APPROVE &&
                        <React.Fragment>
                            <h4 className={SubmitMessageContainer.textStyle}>
                                {format(Constant.TENANT_ONBOARDED_SUCCESSFULLY_MESSAGE, <b>{state.tenantName}</b>)}
                            </h4>
                            <br /><br />
                            <PrimaryButton text={Constant.REDIRECT_TO_VIEWAPPROVE_TENANT} onClick={onBtnClick} className={SubmitMessageContainer.btnStyle} />
                        </React.Fragment>
                    }
                    {
                        state.requestType === Constant.EDIT && 
                        <React.Fragment>
                            <h4 className={SubmitMessageContainer.textStyle}>
                                {format(Constant.TENANT_DETAILS_UPDATED_MESSAGE, <b>{state.tenantName}</b>)}
                            </h4>
                            <br /><br />
                            <PrimaryButton text={Constant.REDIRECT_TO_EDIT_TENANT} onClick={onBtnClick} className={SubmitMessageContainer.btnStyle} />
                        </React.Fragment>
                    }
                </React.Fragment> :
                <React.Fragment>
                    <h4 className={SubmitMessageContainer.textStyle}>
                        There is some error occurred for tenant "<b>{state.tenantName}</b>".
                        Please reach out to MSApprovals Support team at{' '}<b>"msapprovalssup@microsoft.com"</b>{' '} for clarifications.<br />
                        TrackingId: {state.errorId}
                    </h4>
                </React.Fragment>
            }
        </SubmitMessage>
    )
}

export default MessageOnSubmit;