import * as React from 'react';
import { Constant, DayPickerStrings, HeaderConstants, LabelConstants, TableHeaderConstants } from '../../Shared/Constants';
import { TextField, DatePicker, DayOfWeek, PrimaryButton, MessageBar, MessageBarType, Dropdown, IDropdownOption, Label, FontIcon } from '@fluentui/react';
import { appContext } from '../../../App';
import { deleteUserDelegation, getUserDelegation, postUserDelegation } from '../../Shared/Services';
import { controlClass, divScrollContainer, iconClass } from '../../Styles/Style';
import { IUserDelegationList } from '../../Shared/Interfaces/IUserDelegation';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { format } from 'react-string-format';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { generateGuid } from '../../Utils/Common';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';

export function UserDelegation(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext);
    const [textValue, setTextValue] = React.useState({ ManagerAlias: Constant.DEFAULT_EMPTY_STRING, DelegatedAlias: Constant.DEFAULT_EMPTY_STRING });
    const [dateValue, setDateValue] = React.useState({ StartDate: Constant.DEFAULT_EMPTY_STRING, EndDate: Constant.DEFAULT_EMPTY_STRING })
    const [state, setState] = React.useState({
        accessType: Constant.ACCESS_TYPE[1].text,
        startDate: null,
        endDate: null,
        impersonationList: [],
        isLoading: {
            visible: false,
            value: Constant.DEFAULT_EMPTY_STRING
        },
        isTableRefresh: false,
        showMessageBar: false,
        message: Constant.DEFAULT_EMPTY_STRING,
        errorMessage: {
            visible: false,
            value: Constant.DEFAULT_EMPTY_STRING
        },
        isloader: false
    })


    const firstDayOfWeek = DayOfWeek.Sunday;
    const today: Date = new Date(Date.now());

    React.useEffect(() => {
        if (context.environment != Constant.DEFAULT_EMPTY_STRING) {
            setState((prevState) => ({
                ...prevState,
                isLoading: { visible: true, value: format(Constant.LOAD, Constant.DATA) },
                errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
                message: Constant.DEFAULT_EMPTY_STRING,
                isTableRefresh: false
            }))

            const guid = generateGuid();

            getUserDelegation(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    response.data.length > 0 ?
                        setState((prevState) => ({
                            ...prevState,
                            impersonationList: response.data,
                            isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
                        })) :
                        setState((prevState) => ({
                            ...prevState,
                            errorMessage: {value: Constant.NO_RECORDS_AVAILABLE, visible: true},
                            isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING }
                        }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'UserDelegation',
                            'MSApprovals.UserDelegation.getUserDelegation.Success',
                            TrackingEventId.GetUserDelegationSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );

                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        isLoading: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
                        errorMessage: {value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), visible: true}
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'UserDelegation',
                        'MSApprovals.UserDelegation.getUserDelegation.Failure',
                        TrackingEventId.GetUserDelegationFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }, [context.environment, state.isTableRefresh])

    const onFormatDate = (date?: Date): string => {
        var monthField = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
        var dateField = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();

        return !date ? '' : monthField + '/' + dateField + "/" + date.getFullYear();
    };

    const handleTextValueChange = (textField: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        setTextValue({ ...textValue, [textField]: value })
    }

    const handleSelectedDateChange = (dateField: string) => (date: Date | null | undefined): void => {
        dateField === "StartDate" ? setState((prevState) => ({ ...prevState, startDate: date })) : setState((prevState) => ({ ...prevState, endDate: date }))
        var formattedDate = onFormatDate(date);
        setDateValue({ ...dateValue, [dateField]: formattedDate })
    }

    const handleAccessTypeDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        setState((prevState) => ({ ...prevState, accessType: item.text }))
    }

    const onDeleteClick = (userData: IUserDelegationList) => {
        if (confirm(format(Constant.DELETE_CONFIRM_MESSAGE, Constant.DELEGATION)) === true) {

            const guid = generateGuid();

            deleteUserDelegation(userData.rowKey, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        alert(format(Constant.DELETE_SUCCESS, Constant.DELEGATION));
                        setState((prevState) => ({ ...prevState, isTableRefresh: true }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'UserDelegation',
                            'MSApprovals.UserDelegation.deleteUserDelegation.Success',
                            TrackingEventId.DeleteUserDelegationSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({ 
                        ...prevState,
                        isTableRefresh: true,
                        errorMessage: {value: format(Constant.EXCEPTION_MESSAGE, guid.msgId), visible: true}
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'UserDelegation',
                        'MSApprovals.UserDelegation.deleteUserDelegation.Failure',
                        TrackingEventId.DeleteUserDelegationFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
    }

    const onSubmit = () => {
        setState((prevState) => ({
            ...prevState,
            errorMessage: { visible: false, value: Constant.DEFAULT_EMPTY_STRING },
            showMessageBar: false,
            message: Constant.DEFAULT_EMPTY_STRING,
            isloader: true
        }))

        if (textValue.ManagerAlias != Constant.DEFAULT_EMPTY_STRING &&
            textValue.DelegatedAlias != Constant.DEFAULT_EMPTY_STRING &&
            dateValue.StartDate != Constant.DEFAULT_EMPTY_STRING &&
            dateValue.EndDate != Constant.DEFAULT_EMPTY_STRING &&
            state.accessType != Constant.DEFAULT_EMPTY_STRING) {

            var UserDelegationData: IUserDelegationList = {
                managerAlias: textValue.ManagerAlias,
                delegatedTo: textValue.DelegatedAlias,
                startDate: dateValue.StartDate,
                endDate: dateValue.EndDate,
                delegationAccess: state.accessType
            };

            const guid = generateGuid();

            postUserDelegation(UserDelegationData, context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        setState((prevState) => ({
                            ...prevState,
                            showMessageBar: true,
                            message: response.data,
                            isloader: false
                        }))

                        if (response.data === Constant.DEFAULT_EMPTY_STRING) {
                            setTimeout(() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    isTableRefresh: true
                                }))
                            }, 1000)
                        }

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'UserDelegation',
                            'MSApprovals.UserDelegation.postUserDelegation.Success',
                            TrackingEventId.PostUserDelegationSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        showMessageBar: true,
                        errorMessage: {visible: true, value: format(Constant.EXCEPTION_MESSAGE, guid.msgId)},
                        isloader: false
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'UserDelegation',
                        'MSApprovals.UserDelegation.postUserDelegation.Failure',
                        TrackingEventId.PostUserDelegationFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                errorMessage: { visible: true, value: Constant.EMPTY_INPUT_FIELDS },
                isloader: false
            }))
        }
    }

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            <h3>{HeaderConstants.USER_DELEGATION}</h3>
            <br />
            {state.errorMessage.visible ?
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}><b>{state.errorMessage.value}</b>
                </MessageBar> :
                <React.Fragment>
                    {state.isloader && <React.Fragment>
                        <b>{Constant.REQUEST_PROCESSING}</b>
                        <ProgressIndicator barHeight={3} /></React.Fragment>}
                    {state.showMessageBar && <MessageBar
                        messageBarType={state.message == Constant.DEFAULT_EMPTY_STRING ? MessageBarType.success : MessageBarType.error}
                        isMultiline={true}
                    >
                        <b>{state.message == Constant.DEFAULT_EMPTY_STRING ?
                            format(Constant.ADD_SUCCESS, Constant.DELEGATION) :
                            state.message}
                        </b>
                    </MessageBar>}
                    <br />
                </React.Fragment>
            }
            <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.MANAGER_ALIAS}</Label></td>
                        <td className="sp-td sp-width">
                            <TextField
                                value={textValue.ManagerAlias}
                                onChange={handleTextValueChange("ManagerAlias")}
                                ariaLabel="Manager alias text field"
                                className="sp-textbox-admin" />
                        </td>
                        <td className="sp-td"><Label required>{LabelConstants.DELEGATED_ALIAS}</Label></td>
                        <td className="sp-td sp-width">
                            <TextField
                                value={textValue.DelegatedAlias}
                                onChange={handleTextValueChange("DelegatedAlias")}
                                ariaLabel="Delegated user alias text field"
                                className="sp-textbox-admin" />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.START_DATE}</Label></td>
                        <td className="sp-td sp-width">
                            <DatePicker
                                className={controlClass.control}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                placeholder="mm/dd/yyyy"
                                ariaLabel="Select a start date"
                                showMonthPickerAsOverlay={true}
                                formatDate={onFormatDate}
                                onSelectDate={handleSelectedDateChange("StartDate")}
                                value={state.startDate}
                                minDate={today}
                            />
                        </td>
                        <td className="sp-td"><Label required>{LabelConstants.END_DATE}</Label></td>
                        <td className="sp-td sp-width">
                            <DatePicker
                                className={controlClass.control}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                placeholder="mm/dd/yyyy"
                                ariaLabel="Select an end date"
                                showMonthPickerAsOverlay={true}
                                formatDate={onFormatDate}
                                onSelectDate={handleSelectedDateChange("EndDate")}
                                value={state.endDate}
                            minDate={state.startDate}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.ACCESS_TYPE}</Label></td>
                        <td className="sp-td sp-width">
                            <Dropdown
                                onChange={handleAccessTypeDropdownChange}
                                placeholder="Select..."
                                options={Constant.ACCESS_TYPE}
                            defaultSelectedKey={state.accessType}
                        />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"></td>
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <PrimaryButton text={Constant.SAVE} onClick={onSubmit} className="sp-btn" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            {state.isLoading.visible && <Spinner label={state.isLoading.value}></Spinner>}
            {(!state.isLoading.visible && state.impersonationList.length > 0) && <table className="table table-striped table-bordered">
                <thead>
                    <tr className="sp-tabs-tr">
                        <th>{TableHeaderConstants.SOURCE}</th>
                        <th>{TableHeaderConstants.MANAGER_ALIAS}</th>
                        <th>{TableHeaderConstants.DELEGATION_ACCESS}</th>
                        <th>{TableHeaderConstants.DELEGATE_TO}</th>
                        <th>{TableHeaderConstants.START_DATE}</th>
                        <th>{TableHeaderConstants.END_DATE}</th>
                        <th>{TableHeaderConstants.ACTION}</th>
                    </tr>
                </thead>
                <tbody>
                    {state.impersonationList.map((item: IUserDelegationList, index: number) => {
                        return <tr className="sp-tabs-tr" key={index}>
                            <td className="sp-tabs-td">{item.source}</td>
                            <td className="sp-tabs-td">{item.managerAlias}</td>
                            <td className="sp-tabs-td">{item.delegationAccess}</td>
                            <td className="sp-tabs-td">{item.delegatedTo}</td>
                            <td className="sp-tabs-td">{item.startDate}</td>
                            <td className="sp-tabs-td">{item.endDate}</td>
                            <td className="sp-tabs-td">
                                <FontIcon iconName='Delete' title={Constant.DELETE} className={iconClass.otherIcons} role="button" onClick={() => { onDeleteClick(item) }} />
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>}
        </div>
        : <div>You are unauthorized to access this page</div>
    )
}