import { FontIcon } from "@fluentui/react";
import * as React from "react";
import '../Styles/Styles.css'


const Popup = (props: any) => {
  return (
    <div className="popup-box">
      <div className="box">
        {props.content}
      </div>
    </div>
  );
};

export default Popup;