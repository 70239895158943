import { Checkbox, FontIcon, Label, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import Table from 'react-bootstrap/Table';
import { multiStepContext } from '../../../App';
import { MultiStepContainer, OperationDetailsContainer } from '../../Styles/Style';

interface ISubmittedGrid {
    Name: string,
    Text: string,
    Code: string,
    Condition: string,
    IsCommentMandatory: boolean,
    CommentLength: number,
    IsBulkAction: boolean,
    IsInterimStateRequired?: boolean,
    IsEnabled?: boolean,
    comments?: [
        {
          mappingKey: string,
          isMandatory: boolean,
          mandatoryCondition: string,
          length: number
        }
      ]
};

function TenantActionDetails({ admin, updateFormData }: any): React.ReactElement {
    const { formData, setFormData } = React.useContext(multiStepContext);
    let gridArray: ISubmittedGrid[] = [];

    const handleTextValueChange = (textField: string, index: number) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        if (index !== undefined) {   
            let gridData = formData.ActionDetails.slice();
            switch (textField) {
                case "CommentLength": {
                    const re = /^[0-9\b]+$/;
                    if (value === '' || re.test(value)) {
                        gridData[index][textField] = value === '' ? 0 : parseInt(value);
                        setFormData({ ...formData, ActionDetails: gridData })
                    }
                    break;
                }
                default: {                 
                    gridData[index][textField] = value;
                    setFormData({ ...formData, ActionDetails: gridData })
                    break;
                }
            }
        }
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean, index: number) => {
        if (index !== undefined) {
            let gridData = formData.ActionDetails.slice();
            gridData[index][fieldName] = isChecked
            setFormData({ ...formData, ActionDetails: gridData })
        }
    };

    const handleAdd = () => {
        gridArray = formData.ActionDetails;
        let submittedData: ISubmittedGrid = {
            Name: formData.Name,
            Text: formData.Text,
            Code: formData.Code,
            Condition: formData.Condition,
            IsCommentMandatory: formData.IsCommentMandatory,
            IsBulkAction: formData.IsBulkAction,
            CommentLength: formData.CommentLength,
            IsInterimStateRequired: formData.IsInterimStateRequired,
            IsEnabled: formData.IsEnabled,
            comments: [
                {
                    mappingKey: formData.mappingKey,
                    isMandatory: formData.IsCommentMandatory,
                    mandatoryCondition: formData.mandatoryCondition,
                    length: formData.CommentLength
                }
            ]
        }
        gridArray.push(submittedData);
        setFormData({ ...formData, ActionDetails: gridArray });
    }

    const handleDelete = (index: any) => {       
        let filteredGrid = formData.ActionDetails.filter((x: any, i: number) => i !== index);
        setFormData({ ...formData, ActionDetails: filteredGrid })
    }

    
    React.useEffect(() => {
        updateFormData(formData);
    }, [formData]);


    return (
        <div className={MultiStepContainer.divScroll}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr className="sp-tabs-tr">
                        <th>Action Name</th>
                        <th>Action Description</th>
                        <th>Action Code</th>
                        <th>Action Condition</th>
                        <th>Comment Length</th>
                        <th>Comment Mandatory</th>
                        <th>Bulk Action</th>
                        {admin && <React.Fragment>
                            <th>Interim State Required</th>
                            <th>Enabled</th>
                        </React.Fragment>}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {formData.ActionDetails.map((item: any, index: number) => {
                        return <tr key={index}>
                            <td>
                                <TextField
                                    value={index === 0 ? formData.ActionDetails[index].Name = "Approve": formData.ActionDetails[index].Name}
                                    onChange={handleTextValueChange("Name", index)}
                                    ariaLabel="Action Name text field"
                                    placeholder="Enter text..."
                                    disabled= {index === 0 ? (admin ? false : true) : false}
                                />
                            </td>
                            <td>
                                <TextField
                                    value={index === 0 ? formData.ActionDetails[index].Text = "Approve Request" : formData.ActionDetails[index].Text}
                                    onChange={handleTextValueChange("Text", index)}
                                    ariaLabel="Action Text text field"
                                    placeholder="Enter text..."
                                />
                            </td>
                            <td>
                                <TextField
                                    value={index === 0 ? formData.ActionDetails[index].Code = "Approve" : formData.ActionDetails[index].Code}
                                    onChange={handleTextValueChange("Code", index)}
                                    ariaLabel="Action Code text field"
                                    placeholder="Enter text..."
                                    disabled= {index === 0 ? (admin ? false : true) : false}
                                />
                            </td>
                            <td>
                                <TextField
                                    value={formData.ActionDetails[index].Condition}
                                    onChange={handleTextValueChange("Condition", index)}
                                    ariaLabel="Action Condition text field"
                                    placeholder="Enter text..."
                                />
                            </td>
                            <td>
                                <TextField
                                    value={formData.ActionDetails[index].CommentLength}
                                    onChange={handleTextValueChange("CommentLength", index)}
                                    ariaLabel="Comment Length text field"
                                    placeholder="Enter text..."
                                />
                            </td>
                            <td>
                                <Checkbox
                                    checked={formData.ActionDetails[index].IsCommentMandatory}
                                    onChange={(e: any, value: boolean) => handleCheckboxChange("IsCommentMandatory", value, index)}
                                    ariaLabel="Comment Mandatory checkbox"
                                    name="IsCommentMandatory"
                                />
                            </td>
                            <td>
                                <Checkbox
                                    checked={formData.ActionDetails[index].IsBulkAction}
                                    onChange={(e: any, value: boolean) => handleCheckboxChange("IsBulkAction", value, index)}
                                    ariaLabel="Bulk Action checkbox"
                                    name="IsBulkAction"
                                />
                            </td>
                            {
                                admin && <React.Fragment>
                                    <td>
                                        <Checkbox
                                            checked={formData.ActionDetails[index].IsInterimStateRequired}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsInterimStateRequired", value, index)}
                                            ariaLabel="Interim State Required checkbox"
                                            name="IsInterimStateRequired"
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={formData.ActionDetails[index].IsEnabled}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("IsEnabled", value, index)}
                                            ariaLabel="Enabled checkbox"
                                            name="IsEnabled"
                                        />
                                    </td>
                                </React.Fragment>
                            }
                            <td>
                                <span>
                                    <FontIcon iconName='Add' title="Add new row" className={OperationDetailsContainer.gridIcons} role="button" onClick={handleAdd} />
                                </span>
                                {index !== 0 && <span>
                                    <FontIcon iconName='Delete' title="Delete row" className={OperationDetailsContainer.gridIcons} role="button" onClick={() => { handleDelete(index) }} />
                                </span>}

                            </td>
                        </tr>
                    })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default TenantActionDetails;