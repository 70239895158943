import { IDatePickerStrings } from "@fluentui/react";

export const Constant = {
    DEFAULT_EMPTY_STRING: "",
    SUBMIT: "Submit",
    EDIT: "Edit",
    APPROVE: "Approve",
    UPDATE: "Update",
    DELETE: "Delete",
    SAVE: "Save",
    CANCEL: "Cancel",
    SEARCH: "Search",
    MARK_OoS: "Mark OOS",
    OK: "Ok",
    ADD: "Add",
    YES: "Yes",
    NO: "No",
    APPROVED: "Approved",
    PENDING: "Pending",
    PRIMARY: "primary",
    REPROCESS: "Re-Process",
    ERROR: "error",
    SUCCESS: "Success",
    REDIRECT_TO_ONBOARDING_FORM: "Onboard New Tenant",
    REDIRECT_TO_VIEWAPPROVE_TENANT: "Approve another Request",
    REDIRECT_TO_EDIT_TENANT: "Update another Tenant",
    REQUEST_PROCESSING: "Processing your request...",
    ERROR_TEXT: "There is an error!",
    ENVIRONMENT_NAME: "Environment Name: ",
    LOAD: "Loading {0}...",
    DATA: "Data",
    HISTORY_DATA: "History Data",
    TEMPLATE: "Template",
    SUBMIT_REQUEST: "Submitting request...",
    APPROVE_REQUEST: "Approving request...",
    UPDATE_REQUEST: "Updating details...",
    MESSAGE_TYPE: [{label: "Active", value: "Active"}, {label: "DeadLetter", value: "DeadLetter"}] as any[],
    EMPTY_INPUT_FIELDS: "Please fill all mandatory (*) fields.",
    NO_RECORDS_AVAILABLE: "No records available.",
    ACCESS_TYPE: [{key: "FullAccess", text: "FullAccess"}, {key: "ReadOnly", text: "ReadOnly"}],
    DELETE_CONFIRM_MESSAGE: "Are you sure want to delete the {0}?",
    DELEGATION: "Delegation",
    NOTIFICATION: "Notification",
    DELETE_SUCCESS: "{0} deleted successfully",
    ADD_SUCCESS: "{0} added successfully",
    UPDATE_SUCCESS: "{0} updated successfully",
    STATUS_CODE_OK: 200,
    STATUS_CODE_NO_CONTENT: 204,
    SUBSCRIBE: "Subscribe Users",
    UNSUBSCRIBE: "Unsubscribe Users",
    SUCCESS_ACTIONS: "Actions updated Successfully",
    SUBMISSION_TYPE: [{key: 0 , text: "Single"}, {key: "Bulk", text: "Bulk"}],
    API_REQUEST_TYPE: [{key: 1 , text: "Single"}, {key: 2 , text: "Multiple"}],
    AGREE_TYPE: [{key: "Yes", text: "Yes"}, {key: "No", text: "No"}],
    TENANT_TYPE: [{key: "Test", text: "Test"}, {key: "Prod", text: "Prod"}],
    ACTION_DROPDOWN: [{key: "0", text: "0"}, {key: "1", text: "1"},{key: "2", text: "2"}],
    SECONDARY_ACTIONS: [
        {key: 0, text: "Don't Process Secondary Actions"}, 
        {key: 1, text: "Process Secondary Actions"},
        {key: 2, text:"Secondary Actions not present"}],
    DATE_FIELD: "DateTime",
    REQUEST_NUMBER: "Request Number",
    SEARCH_CRITERIA: [
        {key: "DateTime", text: "DateTime", styles:{field: {fontWeight: "400"}}}, 
        {key: "RequestNumber", text: "Request Number", styles:{field: { marginLeft: "15px", fontWeight: "400"}}}
    ],
    OOS_SELECT_TYPE: [
        { key: 'File', text: 'File Upload', styles:{field: {fontWeight: "400"}} },
        { key: 'RequestNumber', text: 'Request Number', styles:{field: { marginLeft: "15px", fontWeight: "400"}} }
    ],
    NO_PENDING_REQUESTS: "There are no Pending Requests currently.",
    SUCCESS_PAYLOAD_REPROCESS: "Message pushed. Please check status in 'Request Status' page.",
    LARGE_START_DATE: "Start Date should be less than End Data.",
    ADAPTIVE_TEMPLATE: "AdaptiveTemplate",
    EMAIL_TEMPLATE: "EmailTemplate",
    SAMPLE_PAYLOAD: "SamplePayload",
    TENANT_IMAGE: "TenantImage",
    INVALID_FILE_SINGLE: "Unsupported file chosen. Please select only {0} file to upload.",
    INVALID_FILE_MULTI: "Unsupported file chosen. Please select either {0} or {1} file to upload.",
    COMMA_SEPERATED_REQ_NUMBERS: "Multiple request numbers can be comma seperated.",
    OOS_SUCCESS: "Request marked Out of Sync successfully.",
    EXCEPTION_MESSAGE: "Some error occurred. Please reach out to MSApprovals Support team at msapprovalssup@microsoft.com. TrackingId: {0}",
    BANNER_TYPE: [{value: "warning", label: "warning"}, {value: "danger", label: "danger"}, {value: "info", label: "info"}],
    ADD_NEW_ALERT: "Add New Alerts/Notifications",
    TENANT_ONBOARDED_SUCCESSFULLY_MESSAGE: "Tenant '{0}' onboarded successfully.",
    TENANT_DETAILS_UPDATED_MESSAGE: "Tenant details for '{0}' updated successfully"

}

export const RouteConstants = {
    HOME: "/",
    BY_TRACKING_ID: "/ByTrackId",
    BY_REQUEST_NUMBER: "/ByRequestNumber",
    BY_USER_ALIAS: "/ByUserAlias",
    BY_SERVICE_BUS_TOPIC: "/ByServiceBusTopic",
    FEATURE_USAGE: "/FeatureUsage",
    REQUEST_STATUS: "/RequestStatus",
    IMPERSONATION: "/UserDelegation",
    USER_ACTIONS: "/UserActions",
    BULK_FLIGHT_FEATURES : "/FlightFeatures",
    REQUEST_OoS: "/MarkRequestOutofSync",
    ONBOARD_TENANT: "/OnboardTenant",
    //APPROVALS_TENANT: "/TenantApprovals",
    ONBOARD_MESSAGE: "/MessageOnSubmit",
    ONSUBMIT: "/OnSubmit",
    PAYLOD_HISTORY: "/PayloadHistory",
    ALERT_NOTIFICATION: "/AlertNotifications",
    APPROVE_TENANT: "/ApproveTenant",
    EDIT_TENANT: "/EditTenant"
}

export const HeaderConstants = {
    REQUEST_HISTORY: "Search Request History",
    USER_ACTIONS: "Manage User Actions",
    USER_DELEGATION: "Add New Delegation",
    REQUEST_OOS: "Mark Request out of Sync",
    ALERT_NOTIFICATIONS: "Alert Notifications"
}

export const LabelConstants = {
    SCOPE: "Select Scope: ",
    SELECT_TENANT_CONFIGURE_ACTIONS: "Select Tenant to Configure Actions: ",
    SELECT_TENANT: "Select Tenant: ",
    CONFIGURE_ACTIONS: "Configure Actions for {0}: ",
    MANAGER_ALIAS: "Manager Alias: ",
    DELEGATED_ALIAS: "Delegated to Alias: ",
    START_DATE: "Start Date: ",
    END_DATE: "End Date: ",
    ACCESS_TYPE: "Access Type: ",
    TYPE: "Select Type: ",
    DOC_NUMBER: "Request Number(s): ",
    APPROVER_ALIAS: "Approver Alias: ",
    AUDIT_AGENT_LOGS: "Audit Agent Logs Collection: ",
    SEARCH_CRITERIA: "Search Criteria",
    SELECT_FILE: "Select File: ",
    NOTIFICATION_TITLE: "Notification Title: ",
    EVENT_START_DATE: "Event Start Date: ",
    EVENT_END_DATE: "Event End Date: ",
    BANNER_TYPE: "Banner Type: ",
    IS_ACTIVE: "Is Active: ",
    IS_SCHEDULED: "Is Scheduled: ",
    NOTIFICATION_BODY: "Notification Body: "

}

export const TableHeaderConstants = {
    ENABLED_ACTIONS: "Are Actions Enabled",
    ACTION_NAMES: "Action Names",
    SOURCE: "Source",
    MANAGER_ALIAS: "Manager Alias",
    DELEGATION_ACCESS: "Delegation Access",
    DELEGATE_TO: "Delegated To",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    ACTION: "Action",
    DOCUMENT_NUMBER: "Request Number",
    DISPLAY_DOCUMENT_NUMBER: "Display Document Number",
    BROKERAGE_MSG_ID: "Brokerage Message Id",
    OPERATION_TYPE: "Operation Type",
    APPROVER_ALIAS: "Approver Alias",
    TIMESTAMP: "Timestamp",
    NOTIFICATION: "Notification",
    TENANT_ID: "Tenant Id",
    IS_ACTIVE: "Is Active",
    IS_SCHEDULED: "Is Scheduled",
    EVENT_START_DATE: "Event Start Date",
    EVENT_END_DATE: "Event End Date",
    NOTIFICATION_TITLE: "Notification Title",
    NOTIFICATION_BODY: "Notification Body",
    BANNER_TYPE: "Banner Type",
    TENANT_NAME: "Tenant Name",
    APPROVER: "Approver",
    STATUS: "Status"
}

export const DayPickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    monthPickerHeaderAriaLabel: '{0}, select to change the year',
    yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

export const defaultValuesOfMultiStepForm = {
    //TenantInfoDetails
    NotifyEmailWithMobileFriendlyActionCard: Constant.ACTION_DROPDOWN[2].text,
    EnableModernAdaptiveUI: Constant.ACTION_DROPDOWN[2].text,
    HistoryLoggingEnabled: false,
    ControlComplianceRequired: false,
    IsHistoryClickable: false,
    IsPictorialLineItemsEnabled: false,
    IsSameApproverMultiLevelSupport: false,
    IsAllCurrentApproversDisplayInHierarchy: false,
    DocNumberForRowKey: false,
    AllowPullModel: false,
    IsExternalTenantActionDetails: false,
    DetailsEditable: false,
    TenantName: Constant.DEFAULT_EMPTY_STRING,
    TenantId: Constant.DEFAULT_EMPTY_STRING,
    ActionSubmissionType: Constant.DEFAULT_EMPTY_STRING,
    APIRequestType: Constant.DEFAULT_EMPTY_STRING,
    SubscriptionName: Constant.DEFAULT_EMPTY_STRING,
    BulkActionCalls: Constant.DEFAULT_EMPTY_STRING,
    AgreeForDocPrefix: Constant.DEFAULT_EMPTY_STRING,
    DocNumberPrefix: Constant.DEFAULT_EMPTY_STRING,
    MaxTenantThreads: 0,
    ToolName: Constant.DEFAULT_EMPTY_STRING,
    RegisteredClients: Constant.DEFAULT_EMPTY_STRING,
    ActionableEmailFolderName: Constant.DEFAULT_EMPTY_STRING,
    ComplianceAckText: Constant.DEFAULT_EMPTY_STRING,
    BtnDisabledReason: Constant.DEFAULT_EMPTY_STRING,
    ServiceTreeId: Constant.DEFAULT_EMPTY_STRING,
    ServiceParameter: Constant.DEFAULT_EMPTY_STRING,
    TenantType: Constant.TENANT_TYPE[0].text,
    //Notifications
    EnableEmailNotification: false,
    EnableOutlookActionableEmail: Constant.DEFAULT_EMPTY_STRING,
    EnableTeamsNotification: false,
    AllowActionableReminderEmail: false,
    //Operation Details
    TenantBaseUrl: Constant.DEFAULT_EMPTY_STRING,
    SummaryUrl: Constant.DEFAULT_EMPTY_STRING,
    ResourceUrl: Constant.DEFAULT_EMPTY_STRING,
    AttachmentUrl: Constant.DEFAULT_EMPTY_STRING,
    operationtype: Constant.DEFAULT_EMPTY_STRING,
    endpointdata: Constant.DEFAULT_EMPTY_STRING,
    SupportsPagination: false,
    _client: false,
    IsCached: false,
    SerializerType: 0,
    IsLegacyResponse: false,
    OperationDetails: [{
        operationtype: Constant.DEFAULT_EMPTY_STRING,
        endpointdata: Constant.DEFAULT_EMPTY_STRING,
        SupportsPagination: false,
        _client: false,
        IsCached: false,
        SerializerType: 0,
        IsLegacyResponse: false
    }],
    //Action Details
    Name: Constant.DEFAULT_EMPTY_STRING,
    Text: Constant.DEFAULT_EMPTY_STRING,
    Code: Constant.DEFAULT_EMPTY_STRING,
    Condition: Constant.DEFAULT_EMPTY_STRING,
    IsCommentMandatory: false,
    CommentLength: 255,
    IsBulkAction: false,
    IsInterimStateRequired: false,
    IsEnabled: true,
    mappingKey: Constant.DEFAULT_EMPTY_STRING,
    mandatoryCondition: Constant.DEFAULT_EMPTY_STRING,
    ActionDetails: [{
        Name: Constant.DEFAULT_EMPTY_STRING,
        Text: Constant.DEFAULT_EMPTY_STRING,
        Code: Constant.DEFAULT_EMPTY_STRING,
        Condition: Constant.DEFAULT_EMPTY_STRING,
        IsCommentMandatory: false,
        CommentLength: 255,
        IsBulkAction: false,
        IsInterimStateRequired: false,
        IsEnabled: true,
        comments: [
            {
              mappingKey: Constant.DEFAULT_EMPTY_STRING,
              isMandatory: false,
              mandatoryCondition: Constant.DEFAULT_EMPTY_STRING,
              length: 255
            }
          ]
    }],
    //Templates
    AdaptiveTemplate: FileList,
    EmailTemplate: FileList,
    SamplePayload: File,
    Templates: Array<any>(),
    //Approvals Engineering
    BusinessProcessName: Constant.DEFAULT_EMPTY_STRING,
    ClassName: Constant.DEFAULT_EMPTY_STRING,
    RaceConditionSleepTime: 0,
    MaxThreadsForSecondaryAction: 0,
    ProcessSecondaryAction: Constant.DEFAULT_EMPTY_STRING,
    TemplateName: Constant.DEFAULT_EMPTY_STRING,
    TenantDetailRetryCount: 0,
    ValidationClassName: Constant.DEFAULT_EMPTY_STRING,
    DataModelMapping: Constant.DEFAULT_EMPTY_STRING,
    LineItemFilterCategories: Constant.DEFAULT_EMPTY_STRING,
    DetailUrl: Constant.DEFAULT_EMPTY_STRING,
    ApprovalRetryInterval: 0,
    AdaptiveCardVersion: Constant.DEFAULT_EMPTY_STRING,
    AdaptiveCardCacheClients: Constant.DEFAULT_EMPTY_STRING,
    TenantImage: {
        FileName: Constant.DEFAULT_EMPTY_STRING,
        FileType:Constant.DEFAULT_EMPTY_STRING,
        FileURL: Constant.DEFAULT_EMPTY_STRING,
        FileBase64: Constant.DEFAULT_EMPTY_STRING
    },
    IsDayZeroActivityRunning: false,
    IsDigestEmailEnabled: false,
    IsRaceConditionHandled: false,
    EnableUserDelegation: false,
    EnableTenantMessageProcessing: false,
    IsBackgroundProcessingEnabled: false,
    ProcessAttachedSummary: false,
    EnableAdaptiveCardCaching: false,
    EnableOldHierarchy: false,
    ActionableNotificationKeys: Constant.DEFAULT_EMPTY_STRING,
    TenantEnabled: false,
    //Generic Fields
    RowKey: Constant.DEFAULT_EMPTY_STRING,
    PartitionKey: Constant.DEFAULT_EMPTY_STRING,
    RequestType: Constant.DEFAULT_EMPTY_STRING,
    Key: Constant.DEFAULT_EMPTY_STRING
}