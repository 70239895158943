import { Checkbox, DatePicker, DayOfWeek, Dropdown, FontIcon, Label, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, ProgressIndicator, Spinner, TextField } from '@fluentui/react';
import { parseBool } from 'adaptivecards';
import * as React from 'react';
import Select from 'react-dropdown-select';
import { format } from 'react-string-format';
import { appContext } from '../../../App';
import { Constant, DayPickerStrings, HeaderConstants, LabelConstants, TableHeaderConstants } from '../../Shared/Constants';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Shared/Helpers/telemetryHelpers';
import { IAlertNotifications } from '../../Shared/Interfaces/IAlertNotifications';
import { deleteNotification, getAlertNotificationList, getTenantsInfo, saveNotification } from '../../Shared/Services';
import { AlertCheckBoxStyle, CheckboxStyle, controlClass, divScrollContainer, DropDownStyle, iconClass } from '../../Styles/Style';
import { convertStringToPascalCase, generateGuid } from '../../Utils/Common';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';

function AlertNotifications(): React.ReactElement {
    const { authClient, telemetryClient } = React.useContext(ComponentContext);
    const { context } = React.useContext(appContext)
    const [state, setState] = React.useState({
        tenantList: [],
        message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
        tenantId: [],
        notificationTitle: Constant.DEFAULT_EMPTY_STRING,
        notificationBody: Constant.DEFAULT_EMPTY_STRING,
        bannerType: [],
        startDate: null,
        endDate: null,
        StartDate: Constant.DEFAULT_EMPTY_STRING,
        EndDate: Constant.DEFAULT_EMPTY_STRING,
        isActive: false,
        isScheduled: false,
        isAddAlertClicked: true,
        isLoading: false,
        alertNotificationData: [],
        isMessageBarWithActions: false,
        operationOnItem: { rowKey: Constant.DEFAULT_EMPTY_STRING },
        isProcessing: false,
        isEditable: false,
        isDisabled: false,
        btnDisabled: false
    })

    const firstDayOfWeek = DayOfWeek.Sunday;

    React.useEffect(() => {
        if (context.environment !== Constant.DEFAULT_EMPTY_STRING) {
            getTenantInfo();
            getAlertNotifications();
        }
    }, [context.environment])

    const getTenantInfo = () => {
        setState((prevState) => ({
            ...prevState,
            tenantList: [],
            isLoading: true
        }))

        const guid = generateGuid();

        getTenantsInfo(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
            .then((response) => {
                if (response.status == Constant.STATUS_CODE_OK) {
                    const tenantsDetails = response.data.map((item: { rowKey: string; appName: string }) => {
                        return { value: item.rowKey, label: item.appName }
                    })
                    setState((prevState) => ({ ...prevState, tenantList: tenantsDetails }));
                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'AlertNotifications',
                        'MSApprovals.AlertNotifications.getTenantsInfo.Success',
                        TrackingEventId.GetTenantInfoSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                }
            })
            .catch((error) => {
                setState((prevState) => ({ 
                    ...prevState, 
                    message: { 
                        success: Constant.DEFAULT_EMPTY_STRING, 
                        error: format(Constant.EXCEPTION_MESSAGE, guid.msgId) 
                    } 
                }));

                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'AlertNotifications',
                    'MSApprovals.AlertNotifications.getTenantsInfo.Failure',
                    TrackingEventId.GetTenantInfoFailure,
                    errorMessage,
                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            })
    }

    const getAlertNotifications = () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            alertNotificationData: []
        }))

        const guid = generateGuid();

        getAlertNotificationList(context.environment, context.authToken, context.userAlias, guid.msgId, guid.xcv)
            .then((response) => {
                if (response.status === Constant.STATUS_CODE_OK) {
                    let alertNotificationList: IAlertNotifications[] = response.data.map((item: any) => {
                        let tenantIdArray: any[] = [];
                        tenantIdArray.push(item.tenantId);
                        return {
                            tenantId: tenantIdArray.toString(),
                            isActive: parseBool(item.partitionKey),
                            isScheduled: item.isScheduled,
                            eventStartTime: item.eventStartTime,
                            eventEndTime: item.eventEndTime,
                            notificationTitle: item.notificationTitle,
                            notificationBody: item.notificationBody,
                            bannerType: item.bannerType,
                            rowKey: item.rowKey
                        }
                    })

                    setState((prevState) => ({
                        ...prevState,
                        alertNotificationData: alertNotificationList,
                        isLoading: false
                    }))
                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'AlertNotifications',
                        'MSApprovals.AlertNotifications.getAlertNotificationList.Success',
                        TrackingEventId.GetAlertNotificationSuccess,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                }
            })
            .catch((error) => {
                setState((prevState) => ({ 
                    ...prevState, 
                    message: { 
                        success: Constant.DEFAULT_EMPTY_STRING, 
                        error: format(Constant.EXCEPTION_MESSAGE, guid.msgId) 
                    } 
                }));

                const errorMessage = error.response ?? error;
                trackException(
                    authClient,
                    telemetryClient,
                    'AlertNotifications',
                    'MSApprovals.AlertNotifications.getAlertNotificationList.Failure',
                    TrackingEventId.GetTenantInfoFailure,
                    errorMessage,
                    { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                );
            })
    }

    const handleTenantDropdownChange = (selectedOption: any[], fieldName?: string, index?: number) => {
        if (selectedOption.length === 0) {
            setState((prevState) => ({
                ...prevState,
                bannerType: selectedOption,
                tenantId: selectedOption
            }))
        }
        else {
            if (index !== undefined) {
                let gridData = state.alertNotificationData.slice();
                gridData[index][fieldName] = selectedOption[0].value;
                setState((prevState) => ({
                    ...prevState,
                    message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
                    alertNotificationData: gridData
                }))
            }
            else {
                setState((prevState) => ({
                    ...prevState,
                    message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
                    [fieldName]: selectedOption[0].value
                }))
            }
        }

    }

    const handleTextValueChange = (textField: string, index?: number) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
        if (index !== undefined) {
            let gridData = state.alertNotificationData.slice();
            gridData[index][textField] = value;
            setState((prevState) => ({
                ...prevState,
                alertNotificationData: gridData,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING }
            }))
        }
        else {
            setState((prevState) => ({
                ...prevState,
                [textField]: value,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING }
            }))
        }
    }

    const onFormatDate = (date: Date): string => {
        var monthField = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
        var dateField = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
        var stringDate = monthField + '/' + dateField + "/" + date.getFullYear();
        return !date ? '' : stringDate;
    }

    const handleSelectedDateChange = (dateField: string, index?: number) => (date: Date | null | undefined): void => {
        var formattedDate = onFormatDate(date);
        if (index !== undefined) {
            let gridData = state.alertNotificationData.slice();
            gridData[index][dateField] = formattedDate;
            setState((prevState) => ({ ...prevState, alertNotificationData: gridData }))
        }
        else {
            dateField === "StartDate" ? setState((prevState) => ({ ...prevState, startDate: date })) : setState((prevState) => ({ ...prevState, endDate: date }))
            setState((prevState) => ({ ...prevState, [dateField]: formattedDate }))
        }
    }

    const handleCheckboxChange = (fieldName: string, isChecked: boolean, index?: number) => {
        if (index !== undefined) {
            let gridData = state.alertNotificationData.slice();
            gridData[index][fieldName] = isChecked;
            setState((prevState) => ({ ...prevState, alertNotificationData: gridData }))
        }
        else {
            setState((prevState) => ({ ...prevState, [fieldName]: isChecked }))
        }

    }

    const onSaveClick = (item?: any, index?: number) => {
        setState((prevState) => ({
            ...prevState,
            message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING },
            isProcessing: true,
            btnDisabled: true,
        }))

        const guid = generateGuid();

        if (index !== undefined) {
            let gridData = state.alertNotificationData.slice();

            if (item.bannerType !== Constant.DEFAULT_EMPTY_STRING &&
                item.eventEndTime !== Constant.DEFAULT_EMPTY_STRING &&
                item.eventStartTime !== Constant.DEFAULT_EMPTY_STRING &&
                item.notificationTitle !== Constant.DEFAULT_EMPTY_STRING &&
                item.notificationBody !== Constant.DEFAULT_EMPTY_STRING) {
                setState((prevState) => ({ ...prevState, isDisabled: true }))

                let tenantItemArray: any[] = [];
                tenantItemArray.push(item.tenantId);

                let requestBody = {
                    TenantId: tenantItemArray.toString(),
                    NotificationTitle: item.notificationTitle,
                    EventStartTime: item.eventStartTime,
                    EventEndTime: item.eventEndTime,
                    IsScheduled: item.isScheduled,
                    PartitionKey: convertStringToPascalCase(item.isActive.toString()),
                    BannerType: item.bannerType,
                    NotificationBody: item.notificationBody,
                    RowKey: item.rowKey,
                    CreatedBy: context.userAlias
                };

                saveNotification(context.environment, requestBody, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                    .then((response) => {
                        if (response.status === Constant.STATUS_CODE_OK) {
                            gridData[index].isEditable = false;
                            setState((prevsState) => ({
                                ...prevsState,
                                message: { success: format(Constant.UPDATE_SUCCESS, Constant.NOTIFICATION), error: Constant.DEFAULT_EMPTY_STRING },
                                isProcessing: false,
                                alertNotificationData: gridData,
                                isDisabled: false,
                                btnDisabled: false
                            }))

                            trackBusinessProcessEvent(
                                authClient,
                                telemetryClient,
                                'AlertNotifications',
                                'MSApprovals.AlertNotifications.saveNotification.Success',
                                TrackingEventId.PostSaveNotificationSuccess,
                                { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                            );

                            setTimeout(() => {
                                getAlertNotifications()
                            }, 0)
                        }
                    })
                    .catch((error) => {
                        setState((prevsState) => ({
                            ...prevsState,
                            message: { 
                                success: Constant.DEFAULT_EMPTY_STRING, 
                                error: format(Constant.EXCEPTION_MESSAGE, guid.msgId)  
                            },
                            isProcessing: false,
                            isDisabled: false,
                            btnDisabled: false
                        }))

                        const errorMessage = error.response ?? error;
                        trackException(
                            authClient,
                            telemetryClient,
                            'AlertNotifications',
                            'MSApprovals.AlertNotifications.saveNotification.Failure',
                            TrackingEventId.PostSaveNotificationFailure,
                            errorMessage,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    })

            }
            else {
                setState((prevsState) => ({
                    ...prevsState,
                    message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.EMPTY_INPUT_FIELDS },
                    isProcessing: false,
                    btnDisabled: false
                }))
            }
        }
        else {
            if (state.tenantId.length > 0 &&
                state.notificationTitle !== Constant.DEFAULT_EMPTY_STRING &&
                state.StartDate !== Constant.DEFAULT_EMPTY_STRING &&
                state.EndDate !== Constant.DEFAULT_EMPTY_STRING &&
                state.bannerType.length > 0 &&
                state.notificationBody !== Constant.DEFAULT_EMPTY_STRING) {
                let requestBody = {
                    TenantId: state.tenantId.toString(),
                    NotificationTitle: state.notificationTitle,
                    EventStartTime: state.StartDate,
                    EventEndTime: state.EndDate,
                    IsScheduled: state.isScheduled,
                    PartitionKey: convertStringToPascalCase(state.isActive.toString()),
                    BannerType: state.bannerType,
                    NotificationBody: state.notificationBody,
                    CreatedBy: context.userAlias
                }

                saveNotification(context.environment, requestBody, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                    .then((response) => {
                        if (response.status === Constant.STATUS_CODE_OK) {
                            handleTenantDropdownChange([])
                            if (!state.isAddAlertClicked) {
                                setState((prevState) => ({ ...prevState, isAddAlertClicked: true }))
                            }
                            setState((prevsState) => ({
                                ...prevsState,
                                message: { success: format(Constant.ADD_SUCCESS, Constant.NOTIFICATION), error: Constant.DEFAULT_EMPTY_STRING },
                                isProcessing: false,
                                btnDisabled: false,
                                notificationTitle: Constant.DEFAULT_EMPTY_STRING,
                                notificationBody: Constant.DEFAULT_EMPTY_STRING,
                                startDate: null,
                                endDate: null,
                                isActive: false,
                                isScheduled: false
                            }));

                            trackBusinessProcessEvent(
                                authClient,
                                telemetryClient,
                                'AlertNotifications',
                                'MSApprovals.AlertNotifications.saveNotification.Success',
                                TrackingEventId.PostSaveNotificationSuccess,
                                { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                            );

                            setTimeout(() => {
                                getAlertNotifications()
                            }, 0)
                        }
                    })
                    .catch((error) => {
                        setState((prevsState) => ({
                            ...prevsState,
                            message: { 
                                success: Constant.DEFAULT_EMPTY_STRING, 
                                error: format(Constant.EXCEPTION_MESSAGE, guid.msgId)  
                            },
                            isProcessing: false,
                            btnDisabled: false
                        }));

                        const errorMessage = error.response ?? error;
                        trackException(
                            authClient,
                            telemetryClient,
                            'AlertNotifications',
                            'MSApprovals.AlertNotifications.saveNotification.Failure',
                            TrackingEventId.PostSaveNotificationFailure,
                            errorMessage,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );
                    })
            }
            else {
                setState((prevsState) => ({
                    ...prevsState,
                    message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.EMPTY_INPUT_FIELDS },
                    isProcessing: false,
                    btnDisabled: false
                }))
            }
        }
    }

    const onCancel = () => {
        setState((prevState) => ({ ...prevState, isAddAlertClicked: !state.isAddAlertClicked }))
    }

    const addAlert = () => {
        setState((prevState) => ({ ...prevState, isAddAlertClicked: !state.isAddAlertClicked }))
    }

    const onDeleteClick = (item: any) => {
        if (!state.isAddAlertClicked) {
            setState((prevState) => ({ ...prevState, isAddAlertClicked: true }))
        }
        setState((prevState) => ({
            ...prevState,
            isMessageBarWithActions: true,
            operationOnItem: item,
            message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING }
        }))
    }

    const confirmDeletion = (agreeType: string) => {
        setState((prevState) => ({
            ...prevState,
            isProcessing: true,
            isMessageBarWithActions: false
        }))

        const guid = generateGuid();

        if (agreeType === Constant.YES) {
            deleteNotification(context.environment, state.operationOnItem.rowKey, context.authToken, context.userAlias, guid.msgId, guid.xcv)
                .then((response) => {
                    if (response.status === Constant.STATUS_CODE_OK) {
                        setState((prevState) => ({
                            ...prevState,
                            isMessageBarWithActions: false,
                            message: { success: format(Constant.DELETE_SUCCESS, Constant.NOTIFICATION), error: Constant.DEFAULT_EMPTY_STRING },
                            isProcessing: false
                        }))

                        trackBusinessProcessEvent(
                            authClient,
                            telemetryClient,
                            'AlertNotifications',
                            'MSApprovals.AlertNotifications.deleteNotification.Success',
                            TrackingEventId.PostSaveNotificationSuccess,
                            { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                        );

                        setTimeout(() => {
                            getAlertNotifications()
                        }, 0)
                    }
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        isMessageBarWithActions: false,
                        message: { 
                            success: Constant.DEFAULT_EMPTY_STRING, 
                            error: format(Constant.EXCEPTION_MESSAGE, guid.msgId)  
                        },
                        isProcessing: false
                    }))

                    const errorMessage = error.response ?? error;
                    trackException(
                        authClient,
                        telemetryClient,
                        'AlertNotifications',
                        'MSApprovals.AlertNotifications.deleteNotification.Failure',
                        TrackingEventId.PostSaveNotificationFailure,
                        errorMessage,
                        { userAlias: context.userAlias, messageId: guid.msgId, xcv: guid.xcv, environment: context.environment }
                    );
                })
        }
        else {
            setState((prevState) => ({
                ...prevState,
                isMessageBarWithActions: false,
                isProcessing: false,
                message: { success: Constant.DEFAULT_EMPTY_STRING, error: Constant.DEFAULT_EMPTY_STRING }
            }))
        }
    }

    const DeleteNotificationConfirmation = () => (
        <MessageBar
            actions={
                <React.Fragment>
                    <MessageBarButton onClick={() => (confirmDeletion(Constant.YES))}>
                        {Constant.YES}
                    </MessageBarButton>
                    <MessageBarButton onClick={() => confirmDeletion(Constant.NO)}>
                        {Constant.NO}
                    </MessageBarButton>
                </React.Fragment>
            }
            messageBarType={MessageBarType.warning}
            isMultiline={false}
        >
            <b>{format(Constant.DELETE_CONFIRM_MESSAGE, Constant.NOTIFICATION)}</b>
        </MessageBar>
    );

    const onEditClick = (item: any, index: number) => {
        if (!state.isAddAlertClicked) {
            setState((prevState) => ({ ...prevState, isAddAlertClicked: true }))
        }

        let gridData = state.alertNotificationData.slice();
        gridData[index].isEditable = true
        setState((prevState) => ({ ...prevState, alertNotificationData: gridData }))
    }

    return (
        context.isAdmin ?
        <div className={divScrollContainer.divScroll}>
            <h3>{HeaderConstants.ALERT_NOTIFICATIONS}</h3>
            <br />
            {state.isMessageBarWithActions && <DeleteNotificationConfirmation />}
            {state.isProcessing &&
                <React.Fragment>
                    <b>{Constant.REQUEST_PROCESSING}</b>
                    <ProgressIndicator barHeight={3} />
                </React.Fragment>}
            {state.message.error !== Constant.DEFAULT_EMPTY_STRING ?
                <React.Fragment>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}> <b>{state.message.error}</b>
                    </MessageBar><br />
                </React.Fragment> :
                <React.Fragment>
                    {state.message.success !== Constant.DEFAULT_EMPTY_STRING &&
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}> <b>{state.message.success}</b>
                        </MessageBar>}
                    <br />
                </React.Fragment>}
            {state.isAddAlertClicked &&
                <React.Fragment>
                    <PrimaryButton text={Constant.ADD_NEW_ALERT} onClick={addAlert} className="sp-btn" />
                    <br />
                </React.Fragment>}

            {!state.isAddAlertClicked && <table>
                <tbody>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.SELECT_TENANT}</Label></td>
                        <td className="sp-td sp-width">
                            <Select
                                className={DropDownStyle.generic}
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={state.tenantList}
                                loading={state.tenantList.length > 0 ? false : true}
                                onChange={(values: any) => handleTenantDropdownChange(values, "tenantId")}
                                searchable={false}
                                values={[...state.tenantId]}
                            />
                        </td>
                        <td className="sp-td"><Label required>{LabelConstants.NOTIFICATION_TITLE}</Label></td>
                        <td className="sp-td sp-width">
                            <TextField
                                value={state.notificationTitle}
                                onChange={handleTextValueChange("notificationTitle")}
                                ariaLabel="Notification Title text field"
                                className="sp-textbox-admin" />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <Label required>{LabelConstants.EVENT_START_DATE}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <DatePicker
                                className={controlClass.control}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                placeholder="mm/dd/yyyy"
                                ariaLabel="Select a start date"
                                showMonthPickerAsOverlay={true}
                                formatDate={onFormatDate}
                                onSelectDate={handleSelectedDateChange("StartDate")}
                                value={state.startDate}
                            />
                        </td>
                        <td className="sp-td">
                            <Label required>{LabelConstants.EVENT_END_DATE}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <DatePicker
                                className={controlClass.control}
                                firstDayOfWeek={firstDayOfWeek}
                                strings={DayPickerStrings}
                                placeholder="mm/dd/yyyy"
                                ariaLabel="Select an end date"
                                showMonthPickerAsOverlay={true}
                                formatDate={onFormatDate}
                                onSelectDate={handleSelectedDateChange("EndDate")}
                                value={state.endDate}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td">
                            <Label required>{LabelConstants.BANNER_TYPE}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <Select
                                className={DropDownStyle.generic}
                                disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                options={Constant.BANNER_TYPE}
                                onChange={(values: any) => handleTenantDropdownChange(values, "bannerType")}
                                searchable={false}
                                values={[...state.bannerType]}
                            />
                        </td>
                        <td className="sp-td">
                            <Label>{LabelConstants.IS_SCHEDULED}</Label>
                        </td>
                        <td className="sp-td sp-width">
                            <AlertCheckBoxStyle>
                                <span className={CheckboxStyle.alertCheckboxIsScheduled}>
                                    <Checkbox
                                        checked={state.isScheduled}
                                        onChange={(e: any, value: boolean) => handleCheckboxChange("isScheduled", value)}
                                        ariaLabel="is Scheduled checkbox"
                                        name="isScheduled" />
                                </span>
                                <span className={CheckboxStyle.alertCheckboxIsActive}>
                                    <td className={CheckboxStyle.alertCheckboxIsActiveLabel}>
                                        <Label>{LabelConstants.IS_ACTIVE}</Label>
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={state.isActive}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("isActive", value)}
                                            ariaLabel="is Active checkbox"
                                            name="isActive"
                                        />
                                    </td>
                                </span>
                            </AlertCheckBoxStyle>
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td"><Label required>{LabelConstants.NOTIFICATION_BODY}</Label></td>
                        <td className="sp-td sp-width" colSpan={3}>
                            <TextField
                                value={state.notificationBody}
                                onChange={handleTextValueChange("notificationBody")}
                                ariaLabel="Notification Body text field"
                                multiline
                                rows={8}
                                resizable={false} />
                        </td>
                    </tr>
                    <tr>
                        <td className="sp-td" colSpan={2}>
                            <span>
                                <PrimaryButton
                                    text={Constant.SAVE}
                                    onClick={onSaveClick}
                                    className="sp-btn"
                                    disabled={state.btnDisabled}
                                />
                            </span>
                            <span>
                                <PrimaryButton
                                    text={Constant.CANCEL}
                                    onClick={onCancel}
                                    className="sp-btn"
                                    disabled={state.btnDisabled}
                                />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>}
            <br />
            {state.isLoading && <Spinner label={format(Constant.LOAD, Constant.DATA)} />}
            {
                (state.alertNotificationData.length > 0 && !state.isLoading) &&
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr className="sp-tabs-tr">
                            <th>{TableHeaderConstants.TENANT_ID}</th>
                            <th>{TableHeaderConstants.IS_ACTIVE}</th>
                            <th>{TableHeaderConstants.IS_SCHEDULED}</th>
                            <th>{TableHeaderConstants.EVENT_START_DATE}</th>
                            <th>{TableHeaderConstants.EVENT_END_DATE}</th>
                            <th>{TableHeaderConstants.NOTIFICATION_TITLE}</th>
                            <th>{TableHeaderConstants.NOTIFICATION_BODY}</th>
                            <th>{TableHeaderConstants.BANNER_TYPE}</th>
                            <th>{TableHeaderConstants.ACTION}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.alertNotificationData.map((item: IAlertNotifications, index: number) => {
                            return <tr className="sp-tabs-tr" key={index}>
                                <td className="sp-tabs-td">
                                    {item.tenantId}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <Checkbox
                                            checked={state.alertNotificationData[index].isActive}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("isActive", value, index)}
                                            ariaLabel="is Active checkbox"
                                            name="isActive"
                                        />
                                        : (item.isActive ?
                                            <FontIcon iconName='Accept' className={iconClass.alertIcons} /> :
                                            <FontIcon iconName='Clear' className={iconClass.alertIcons} />)}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <Checkbox
                                            checked={state.alertNotificationData[index].isScheduled}
                                            onChange={(e: any, value: boolean) => handleCheckboxChange("isScheduled", value, index)}
                                            ariaLabel="is Scheduled checkbox"
                                            name="isScheduled"
                                        />
                                        : (item.isScheduled ?
                                            <FontIcon iconName='Accept' className={iconClass.alertIcons} /> :
                                            <FontIcon iconName='Clear' className={iconClass.alertIcons} />)}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <DatePicker
                                            className={controlClass.control}
                                            firstDayOfWeek={firstDayOfWeek}
                                            strings={DayPickerStrings}
                                            placeholder="mm/dd/yyyy"
                                            ariaLabel="Select a start date"
                                            showMonthPickerAsOverlay={true}
                                            formatDate={onFormatDate}
                                            onSelectDate={handleSelectedDateChange("eventStartTime", index)}
                                            value={new Date(state.alertNotificationData[index].eventStartTime)}
                                        />
                                        : onFormatDate(new Date(item.eventStartTime))}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <DatePicker
                                            className={controlClass.control}
                                            firstDayOfWeek={firstDayOfWeek}
                                            strings={DayPickerStrings}
                                            placeholder="mm/dd/yyyy"
                                            ariaLabel="Select an end date"
                                            showMonthPickerAsOverlay={true}
                                            formatDate={onFormatDate}
                                            onSelectDate={handleSelectedDateChange("eventEndTime", index)}
                                            value={new Date(state.alertNotificationData[index].eventEndTime)}
                                        />
                                        : onFormatDate(new Date(item.eventEndTime))}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <TextField
                                            value={state.alertNotificationData[index].notificationTitle}
                                            onChange={handleTextValueChange("notificationTitle", index)}
                                            ariaLabel="Notification Title text field"
                                        />
                                        : item.notificationTitle}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <TextField
                                            value={state.alertNotificationData[index].notificationBody}
                                            onChange={handleTextValueChange("notificationBody", index)}
                                            ariaLabel="Notification Body text field"
                                            multiline
                                            rows={2}
                                            resizable={false} />
                                        : item.notificationBody}
                                </td>
                                <td className="sp-tabs-td">
                                    {item.isEditable ?
                                        <Select
                                            disabled={context.environment == Constant.DEFAULT_EMPTY_STRING ? true : false}
                                            options={Constant.BANNER_TYPE}
                                            onChange={(values: any) => handleTenantDropdownChange(values, "bannerType", index)}
                                            searchable={false}
                                            values={[Constant.BANNER_TYPE.find((item: any) => item.value === state.alertNotificationData[index].bannerType)]}
                                        />
                                        : item.bannerType}
                                </td>
                                <td className="sp-tabs-td">
                                    <span className={iconClass.alertIcons}>
                                        {item.isEditable ?
                                            <FontIcon iconName='Save' title="Save notification" role={state.btnDisabled ? "" : "button"} onClick={(e: any) => onSaveClick(item, index)} />
                                            : <FontIcon iconName='PageEdit' title="Edit notification" role="button" onClick={(e: any) => onEditClick(item, index)} />}

                                    </span>
                                    <span className={iconClass.alertIcons}>
                                        <FontIcon iconName='Delete' title="Delete notification" role={state.btnDisabled ? "" : "button"} onClick={() => { onDeleteClick(item) }} />
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }

        </div>
        : <div>You are unauthorized to access this page</div>
    )
}

export default AlertNotifications;