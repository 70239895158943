import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProvider } from '@employee-experience/common/lib/RouteComponentProvider';
import RequestHistory from './SupportPortalCode/Components/Generic/RequestHistory';
import { IRoutes } from './SupportPortalCode/Shared/Interfaces/IRoutes';
import { ServiceBusTopicMonitoring } from './SupportPortalCode/Components/MSASpecific/SBTopicMonitoring';
import { RequestStatus } from './SupportPortalCode/Components/MSASpecific/RequestStatus';
import { UserDelegation } from './SupportPortalCode/Components/Admin/UserDelegation';
import { RouteConstants } from './SupportPortalCode/Shared/Constants';
import UserActions from './SupportPortalCode/Components/Admin/UserActions';
import { BulkFlightFeatures } from './SupportPortalCode/Components/Admin/FlightUsers';
import OutOfSyncRequests from './SupportPortalCode/Components/Admin/OoSRequests';
import TenantInfo from './SupportPortalCode/Shared/ConfigureTenant/TenantInfo';
import OnboardTenant from './SupportPortalCode/Components/TenantOnboarding/OnboardTenant';
import MessageOnSubmit from './SupportPortalCode/Shared/ConfigureTenant/Message';
import TenantApprovals from './SupportPortalCode/Shared/ConfigureTenant/TenantApprovals';
import OnSubmitForm from './SupportPortalCode/Shared/ConfigureTenant/SubmitForm';
import PayloadHistory from './SupportPortalCode/Components/MSASpecific/PayloadHistory';
import AlertNotifications from './SupportPortalCode/Components/Admin/AlertNotifications';
import ViewApproveTenant from './SupportPortalCode/Components/Admin/ViewApproveTenant';
import EditTenant from './SupportPortalCode/Components/Admin/EditTenant';

export function Routes(props: IRoutes): React.ReactElement {

    const [menuItems, setMenuItems] = React.useState([]);
    var SubMenuItemsArray: Array<{}> = Array<{}>();

    React.useEffect(() => {
        props.MenuItems.map((mainMenu) => {
            mainMenu.links.map((menuItems) => {
                menuItems.links.map((subMenuItems) => {
                    SubMenuItemsArray.push(subMenuItems)
                    setMenuItems(SubMenuItemsArray);
                })
            })
        })
    }, [props.MenuItems]);

    return (
        <React.Fragment>
            <Switch>
                <Route
                    path={RouteConstants.HOME}
                    exact={true}
                    render={() => <RequestHistory RequestType="RequestHistory" />} />
                {/* {menuItems && menuItems.map((items) => {
                   return (<Route
                        path={items.href}
                        exact={true}
                        render={() => <RequestHistory RequestType={items.requestType} />} />)
                })} */}
                <Route
                    path="/ByTrackId"
                    exact={true}
                    render={() => <RequestHistory RequestType="ByTrackingID" />} />

                <Route
                    path="/ByDocNumber"
                    exact={true}
                    render={() => <RequestHistory RequestType="RequestHistory" />} />

                <Route
                    path="/ByUserAlias"
                    exact={true}
                    render={() => <RequestHistory RequestType="ByUserAlias" />} />

                <Route
                    path="/FeatureUsage"
                    exact={true}
                    render={() => <RequestHistory RequestType="FeatureUsage" />} />

                <Route
                    path={RouteConstants.PAYLOD_HISTORY}
                    exact={true}
                    component={PayloadHistory} />

                <Route
                    path={RouteConstants.BY_SERVICE_BUS_TOPIC}
                    exact={true}
                    component={ServiceBusTopicMonitoring} />

                <Route
                    path={RouteConstants.REQUEST_STATUS}
                    exact={true}
                    component={RequestStatus} />

                <Route
                    path={RouteConstants.IMPERSONATION}
                    exact={true}
                    component={UserDelegation} />

                <Route
                    path={RouteConstants.BULK_FLIGHT_FEATURES}
                    exact={true}
                    component={BulkFlightFeatures} />

                <Route
                    path={RouteConstants.USER_ACTIONS}
                    exact={true}
                    component={UserActions} />

                <Route
                    path={RouteConstants.REQUEST_OoS}
                    exact={true}
                    component={OutOfSyncRequests} />

                <Route
                    path={RouteConstants.ALERT_NOTIFICATION}
                    exact={true}
                    component={AlertNotifications} />

                <Route
                    path={RouteConstants.ONBOARD_TENANT}
                    exact={true}
                    component={OnboardTenant} />

                <Route
                    path={RouteConstants.APPROVE_TENANT}
                    exact={true}
                    component={ViewApproveTenant} />

                <Route
                    path={RouteConstants.EDIT_TENANT}
                    exact={true}
                    component={EditTenant} />

                <Route
                    path={RouteConstants.ONSUBMIT}
                    exact={true}
                    component={OnSubmitForm} />

                <Route
                    path={RouteConstants.ONBOARD_MESSAGE}
                    exact={true}
                    component={MessageOnSubmit} />


                <RouteComponentProvider
                    path="/dynamic-redux-hooks"
                    config={{
                        script: '/bundles/dynamic-redux-hooks.js',
                        name: 'DynamicReduxHooks'
                    }}
                />
                <RouteComponentProvider
                    path="/dynamic-redux-class"
                    config={{
                        script: '/bundles/dynamic-redux-class.js',
                        name: 'DynamicReduxClass'
                    }}
                />
                <RouteComponentProvider
                    path="/dynamic-sub-routes"
                    config={{
                        script: '/bundles/dynamic-sub-routes.js',
                        name: 'DynamicSubRoutes'
                    }}
                />
                <RouteComponentProvider
                    path="/dynamic-custom-props"
                    config={{
                        script: '/bundles/dynamic-custom-props.js',
                        name: 'DynamicCustomProps'
                    }}
                />
                <RouteComponentProvider
                    path="/hello-world"
                    config={{
                        script: 'https://ee.azureedge.net/eedev/hello-world.js',
                        name: 'HelloWorld'
                    }}
                />
            </Switch>
        </React.Fragment>
    );
}
