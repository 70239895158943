import { IFeaturesIntroductionStep } from './SharedComponents.types';

export type SharedComponentsAction =
    | IToggleTeachingBubbleVisibility
    | IUpdateTeachingStep
    | IUpdateUserAlias;

    export enum SharedComponentsActionType {
        UPDATE_USER_ALIAS = 'UPDATE_USER_ALIAS',
        TOGGLE_TEACHING_BUBBLE_VISIBILITY = 'TOGGLE_TEACHING_BUBBLE_VISIBILITY',
        UPDATE_TEACHING_STEP = 'UPDATE_TEACHING_STEP'
    }

    export interface IToggleTeachingBubbleVisibility {
        type: SharedComponentsActionType.TOGGLE_TEACHING_BUBBLE_VISIBILITY;
    }
    
    export interface IUpdateTeachingStep {
        type: SharedComponentsActionType.UPDATE_TEACHING_STEP;
        newStep: IFeaturesIntroductionStep
    }

    export interface IUpdateUserAlias {
        type: SharedComponentsActionType.UPDATE_USER_ALIAS;
        userAlias: string;
        userName: string;
    }