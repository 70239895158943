import * as React from 'react';
import TenantInfo from '../../Shared/ConfigureTenant/TenantInfo';
import { Constant } from '../../Shared/Constants';


function OnboardTenant() : React.ReactElement {

    return (
        <TenantInfo 
        isAdmin={false}
        requestType={Constant.SUBMIT}
        startFormIndex={0}
         />
    )
}

export default OnboardTenant;