import * as React from 'react';
import { CoherenceCustomizations } from '@cseo/styles';
import { Customizer, Stack } from 'office-ui-fabric-react';
import { BrowserRouter } from 'react-router-dom';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Header } from '@employee-experience/shell/lib/Header';
import { Nav } from '@employee-experience/shell/lib/Nav';
import { Main } from '@employee-experience/shell/lib/Main';
import { initializeIcons } from '@uifabric/icons';
import { Routes } from './Routes';
import { usePersistentReducer } from './SupportPortalCode/Shared/PersistantReducer';
import {
    sharedComponentsPersistentReducerName,
    sharedComponentsPersistentReducer,
    SharedComponentsPersistentInitialState
} from './SupportPortalCode/Shared/SharedComponents.persistent-reducer';
import { ReduxContext } from '@employee-experience/common';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { IComponentsAppState } from './SupportPortalCode/Shared/SharedComponents.types';
import { SecondaryHeader } from './SupportPortalCode/Shared/SecondaryHeader';
import './App.css';
import { ICoherenceHeaderProps } from '@cseo/controls';
import { useLoginOnStartupWithOptions } from './SupportPortalCode/Utils/useLoginOnStartupWithOptions';
import {
    sharedComponentsReducerName,
    sharedComponentsInitialState
} from './SupportPortalCode/Shared/SharedComponents.reducer';
//import './SupportPortalCode/Styles/Styles.css';
import { navigationConfig } from './SupportPortalCode/Shared/Navigation';
import { Constant, defaultValuesOfMultiStepForm } from './SupportPortalCode/Shared/Constants';
import { MainRoute } from './SupportPortalCode/Styles/Style';

export const appContext = React.createContext(null);
export const multiStepContext = React.createContext(null);

function App(): React.ReactElement {
    initializeIcons();
    useLoginOnStartupWithOptions(true, { scopes: ['https://graph.microsoft.com/.default'] });
    usePersistentReducer(
        sharedComponentsPersistentReducerName,
        sharedComponentsPersistentReducer
    );
    const { useSelector } = React.useContext(ReduxContext);
    const { telemetryClient: telemetryHook } = React.useContext(ComponentContext);
    const { teachingBubbleVisibility } = useSelector(
        (state: IComponentsAppState) =>
            state.SharedComponentsPersistentReducer || SharedComponentsPersistentInitialState
    );

    const { isPanelOpen } = useSelector(
        (state: IComponentsAppState) => state.dynamic?.[sharedComponentsReducerName] || sharedComponentsInitialState
    );

    const headerConfig: ICoherenceHeaderProps = {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: __APP_NAME__
        },
        telemetryHook
    }


    const [context, setContext] = React.useState({
        authToken: Constant.DEFAULT_EMPTY_STRING,
        environment: Constant.DEFAULT_EMPTY_STRING,
        userAlias: Constant.DEFAULT_EMPTY_STRING,
        isAdmin: false
    })

    const [formData, setFormData] = React.useState(defaultValuesOfMultiStepForm);
    const [finalData, setFinalData] = React.useState(null);

    return (
        <Customizer {...CoherenceCustomizations}>
            <ShellStyles />
            <BrowserRouter>
                <Header {...headerConfig} />
                <div>
                    <appContext.Provider value={{ context, setContext }}>
                        <Stack horizontal className={isPanelOpen ? 'ms-hiddenSm' : ''}>
                            <Stack.Item>
                                <div className="navContainer">
                                    <Nav groups={context.isAdmin ? navigationConfig : navigationConfig.map(({ links, ...element }) => {
                                                                                        return {
                                                                                            key:element.key,
                                                                                            links: links.filter(
                                                                                                (link) => link.name !== 'Admin Settings'
                                                                                            )
                                                                                        }
                                                                                    })} />
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <SecondaryHeader />
                            </Stack.Item>
                        </Stack>
                        <multiStepContext.Provider value={{ formData, setFormData, finalData, setFinalData }}>
                            <Main id="main" tabIndex={-1} role="main">
                                <MainRoute>
                                    <Routes MenuItems={context.isAdmin ? navigationConfig : navigationConfig.map(({ links, ...element }) => {
                                                                                                return {
                                                                                                    key:element.key,
                                                                                                    links: links.filter(
                                                                                                        (link) => link.name !== 'Admin Settings'
                                                                                                    )
                                                                                                }
                                                                                            })} />
                                </MainRoute>
                            </Main>
                        </multiStepContext.Provider>
                    </appContext.Provider>
                </div>
            </BrowserRouter>
        </Customizer>
    );
}

export default App;
